import React, { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { GET_LEAGUE_INDUSTRIES, UPDATE_LEAGUE_INDUSTRIES } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: '20px 15px 15px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014',
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    },
    autocompleteRoot: {
        height: 43,
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            minWidth: "80px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '6.5px 4px'
        },
        '& .MuiFormControl-root label': {
            top: '2px !important'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    functions: {
        '& .MuiFormControl-root label': {
            top: '2px !important'
        },
    }
}));



const IndustryCreationDialog = ({ open = false, handleClose, selectedIndustry, functions, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        functions: [],
        industry: '',
        description: '',
    });


    useEffect(() => {
        if (selectedIndustry) {
            const objectDetails = {
                ...details,
                functions: selectedIndustry?.function_id ? functions?.filter(func => selectedIndustry?.function_id?.map(id => id).includes(func._id)) : [],
                industry: selectedIndustry?.name,
                description: selectedIndustry?.description,
            };
            setDetails({ ...objectDetails });
        }
    }, [selectedIndustry]);


    const handleChangeInput = (e, field) => {
        setDetails({ ...details, [field]: e.target.value });
    }

    const handleSelect = (val, field) => {
        if (val) {
            setDetails({ ...details, [field]: val });
        }
    }

    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (
            details?.industry.trim() === ""
        ) {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                function_id: details?.functions ? details?.functions?.map(el => el._id) : null,
                name: details?.industry,
                description: details?.description
            };
            setLoading(true);
            if (selectedIndustry) {
                Axios.put(UPDATE_LEAGUE_INDUSTRIES(selectedIndustry?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Industry Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Industry', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            } else {
                Axios.post(GET_LEAGUE_INDUSTRIES, requestObj)
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar('Industry added successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Industry', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
        }
    };


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {selectedIndustry ? 'Update Industry' : 'Add Industry'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="industry"
                                value={details.industry}
                                type="String"
                                label="Industry"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "industry")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.functions}>
                                <Autocomplete
                                    id="function"
                                    multiple
                                    size="small"
                                    options={functions}
                                    limitTags={3}
                                    disableCloseOnSelect
                                    getOptionLabel={option => (option?.name || '')}
                                    getOptionSelected={(option, value) => option?._id === value?._id}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    value={details?.functions}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                className={classes.checkClass}
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                color="primary"
                                            />
                                            {option?.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                            label="Assign Functions to This Industry"
                                            size="small"
                                        // required
                                        />
                                    }
                                    onChange={(...args) => handleSelect(args[1], 'functions')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 150 }}
                                variant="outlined"
                                id="description"
                                value={details.description}
                                type="String"
                                label="Description"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "description")}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 0" }}>
                        <Button
                            onClick={() => handleClose(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            {selectedIndustry ? 'Save' : 'Submit'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(IndustryCreationDialog);
