import { all, select, takeEvery, put } from "redux-saga/effects";
import find from 'lodash/find';
// import shortid from 'shortid';

import { receivedPresenceAvailable, receivedPresenceUnavailable } from "../../ducks/presence";
// import { receivedMessage } from "../../ducks/messages";

import { makeChannel } from "../_helpers";

function* watchForPresence(client) {
  // console.log('presence ', client);

  // client.on('presence', (obj) => {
  //   console.log('presenceObj ', obj);
  // })

  const channel = makeChannel(client, {
    // "presence": (emit, msg) => {
    //   emit(msg);
    // },
    "muc:available": (emit, msg) => {
      emit(msg);
    },
    "muc:unavailable": (emit, msg) => {
      emit(msg);
    },
  });

  yield takeEvery(channel, function* eachMessage(presence) {
    // console.log('presenceObj ', presence.show, presence);
    const roomJid = presence.from.split('/')[0];
    const roomNickname = yield select((state) => {
      // console.log('state ', state);
      if (state.rooms[roomJid]) {
        return state.rooms[roomJid].nickname
      }
      return undefined;
    });

    // Ignore own presence messages
    if (roomNickname && (presence.from.split('/')[1] !== roomNickname)) {

      const roomMembers = yield select(function (state) {
        let members = [];
        if (state.presence[roomJid] && state.presence[roomJid].members) {
          members = state.presence[roomJid].members;
        }
        return members;
      });

      let memberInRoom = find(roomMembers, function (member) {
        return member.resource === presence.from.resource;
      });

      // Don't show join message if member already in room
      if (!memberInRoom || presence.show !== 'available') {
        // presence.id = shortid.generate();
        // yield put(receivedMessage(presence));
      }

    }

    if (presence.show === 'available' || presence.status) {
      // console.log('presence.show available', presence.show);
      yield put(receivedPresenceAvailable(presence));
    } else if (presence.show === 'unavailable') {
      // console.log('presence.show un-available', presence.show);
      yield put(receivedPresenceUnavailable(presence));
    }

    return;

  });
}

export default function* (client) {
  yield all([watchForPresence(client)]);
}
