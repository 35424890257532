import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { BUSINESS_UNITS, UPDATE_BUSINESS } from "../../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: '20px 15px 15px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014',
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    },
    autocompleteRoot: {
        height: 43,
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            minWidth: "80px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '6.5px 4px'
        },
        '& .MuiFormControl-root label': {
            top: '2px !important'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    }
}));



const BusinessUnitDialog = ({ open = false, handleClose, selectedBusiness, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        business: '',
        short_code: '',
        description: '',
    });

    useEffect(() => {
        if (selectedBusiness) {
            const objectDetails = {
                ...details,
                business: selectedBusiness?.name,
                description: selectedBusiness?.description,
                short_code: selectedBusiness?.short_code,
            };
            setDetails({ ...objectDetails });
        }
    }, [selectedBusiness]);


    const handleChangeInput = (e, field) => {
        setDetails({ ...details, [field]: e.target.value });
    }


    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (details?.business.trim() === "" || details.short_code === '') {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                name: details?.business,
                description: details?.description,
                short_code: details.short_code
            };
            setLoading(true);
            if (selectedBusiness) {
                Axios.put(UPDATE_BUSINESS(selectedBusiness?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Business Unit Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Business', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            } else {
                Axios.post(BUSINESS_UNITS, requestObj)
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar('Business Unit added successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Business', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
        }
    };


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {selectedBusiness ? 'Update Business Unit' : 'Add Business Unit'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="business"
                                value={details.business}
                                type="String"
                                label="Business Unit"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "business")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 150 }}
                                variant="outlined"
                                id="short_code"
                                value={details.short_code}
                                type="String"
                                label="Short Code"
                                autoComplete="off"
                                required
                                onChange={(e) => handleChangeInput(e, "short_code")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 150 }}
                                variant="outlined"
                                id="description"
                                value={details.description}
                                type="String"
                                label="Description"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "description")}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 0" }}>
                        <Button
                            onClick={() => handleClose(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            {selectedBusiness ? 'Save' : 'Submit'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(BusinessUnitDialog);
