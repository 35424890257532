import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, TextField, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading } from '../ducks/loading';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';
import { GET_FUNCTION_KPIS, GET_INDUSTRIES_LIST } from "../config";
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomKpiTable from './CustomKpiTable';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        },
        '& .MuiAccordion-root:before': {
            height: '0px !important'
        },
        '.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px !important'
        }
    },
    footerButton: {
        margin: 18,
        // width: 135,
        // height: 47,
        '&:last-child': {
            marginRight: 0
        }
    },
    backButton: {
        margin: 18,
        width: 135,
        height: 47,
        marginLeft: 0
    },
    footerButtonGroup: {
        margin: '0px 0px 18px 18px',
        float: 'right',
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        width: 280,
        height: 40
    },
    multilineInput: {
        fontSize: 14,
        padding: '15px 14px 12px'
    },
    chipRoot: {
        borderRadius: '16px !important'
    }, customToolbarRoot: {
        position: 'absolute',
        top: 5,
        // right: 115,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 100px)'
    }, customToolbarRootWithMessage: {
        position: 'absolute',
        top: 1,
        right: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 342px)'
    },
    divisionsLink: {
        fontSize: 12,
        cursor: 'pointer',
        // marginRight: 15,
        whiteSpace: 'nowrap',
        textDecoration: 'underline'
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        '& input': {
            padding: '5.5px 4px !important'
        }
        // width: 280
    },
    button: {
        textTransform: 'capitalize'
    },
    accordianMain: {
        background: '#F0F1F3',
        marginBottom: 8,
        border: '1px solid #d6d6d6',
        borderRadius: '4px !important',
        boxShadow: 'none',
        '& .MuiAccordion-root:before': {
            height: '0px !important'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 48
        }
    },
    accordianDetails: {
        padding: 0
    },
    viewAllButton: {
        color: '#727272',
        fontSize: 13,
        float: 'right',
        cursor: 'pointer',
        border: '1px solid #D3D3D3',
        borderRadius: 4,
        padding: '6px 16px',
        textTransform: 'Capitalize',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    }
}));

export const MeasurementTypes = [{ name: "Currency", id: "CURRENCY" }, { name: "Number", id: "NUMBER" }, { name: "Percentage", id: "PERCENTAGE" }, { name: 'Duration', id: 'DURATION' }];
export const kpiTagTypes = [{ name: 'Team Core', id: 'TEAM_MATCH' }, { name: 'Team Bonus', id: 'TEAM_BONUS' }, { name: 'Player Core', id: 'PLAYER_MATCH' }, { name: 'Player Bonus', id: 'PLAYER_BONUS' }];
export const durationUnits = [{ name: 'Minutes', id: 'MINUTES' }, { name: 'Hours', id: 'HOURS' }, { name: 'Days', id: 'DAYS' }];
export const ScoringUnitTypes = [{ name: 'Run', id: 'RUN' }, { name: 'Hit', id: 'HIT' }, { name: 'Goal', id: 'GOAL' },]



const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Kpi Master' },
]

const MasterKpi = ({ setLoading }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [industries, setIndustries] = useState();
    const [kpiDetails, setKpiDetails] = useState({ industry: null, function: null });
    const [expanded, setExpanded] = useState(false);
    const [industryFunctions, setIndustryFunctions] = useState();
    const [selectedFunction, setSelectedFunction] = useState();

    useEffect(() => {
        getIndustries();
    }, []);

    useEffect(() => {
        kpiDetails?.industry && getIndustryFunctions();
    }, [kpiDetails?.industry]);


    // ----------------industries------------
    const getIndustries = () => {
        Axios.get(GET_INDUSTRIES_LIST(), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.name,
                            id: el._id,
                            ...el
                        }
                    })
                    setIndustries(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ----------------functions------------
    const getIndustryFunctions = () => {
        Axios.get(GET_FUNCTION_KPIS(kpiDetails?.industry?._id), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            id: el._id,
                            ...el
                        }
                    })
                    setIndustryFunctions(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }


    const handleChangeAutocomplete = (data, field) => {
        setKpiDetails({ ...kpiDetails, [field]: data, function: null });
        setExpanded(false);
        setSelectedFunction();
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel?.name : false);
        setKpiDetails({ ...kpiDetails, function: panel })
        setSelectedFunction(panel)
    };


    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Category"} crumbsList={breadcrumbsList} />
                <div style={{ background: 'white', borderRadius: 6, marginTop: 15, padding: 10 }}>
                    <div className='industry' style={{ padding: '10px 10px 0px 10px', marginBottom: 15 }}>
                        <Autocomplete
                            id="industries"
                            options={industries}
                            getOptionLabel={(option) => option.name || ''}
                            value={kpiDetails?.industry_id}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => <TextField {...params} label="Industry" placeholder='Select Industry' variant="outlined" required />}
                            onChange={(...args) => { handleChangeAutocomplete(args[1], 'industry') }
                            }
                        />
                    </div>
                    {kpiDetails?.industry ?
                        <div style={{ width: 'calc(100% - 0px)', height: 'calc(100vh - 220px)', overflow: 'auto' }}>
                            {industryFunctions?.length > 0 ?
                                industryFunctions.map((fun, index) => {
                                    return (
                                        <Accordion className={classes.accordianMain} key={fun?._id} expanded={expanded === fun?.name} onChange={handleChange(fun)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography className={classes.heading}>{fun?.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordianDetails}>
                                                <div style={{ background: 'white', paddingBottom: 10, width: '100%' }} key={`table_${fun?._id}`}>
                                                    <CustomKpiTable key={fun?._id}
                                                        id={fun?._id}
                                                        kpiDetails={kpiDetails}
                                                        functions={industryFunctions}
                                                        industries={industries}
                                                        selectedFunction={selectedFunction}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                                :
                                <div style={{ height: 'calc(100vh - 220px)', background: '#F0F1F3', position: 'relative', overflow: 'auto' }}>
                                    <div style={{ position: 'absolute', textAlign: 'center', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                        <Typography variant="h6" style={{ color: '#000000E5' }} color='#000000E5'>No Function's Created</Typography>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div style={{ height: 'calc(100vh - 220px)', background: '#F0F1F3', position: 'relative', overflow: 'auto' }}>
                            <div style={{ position: 'absolute', textAlign: 'center', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                                <Typography variant="h6" style={{ color: '#000000E5' }} color='#000000E5'>Please Select Industry</Typography>
                                <Typography variant='body' style={{ color: '#9c9c9c' }} color='#9c9c9c'>Selecting your preferred industry. You can add and modify details to your specific industry category category.</Typography>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(
    null,
    mapDispatchToProps
)(MasterKpi));
