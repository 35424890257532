import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { Chip, TablePagination, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import { ATTRIBUTES_LIST } from '../config';
import { Delete, Edit } from '@material-ui/icons';
import AttributeTemplateDialog from './AttributeTemplateDialog';
import CreateAttributesDialog from './CreateAttributesDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }
    },
    event: {
        background: '#5D5FEF29',
        borderRadius: 4,
        padding: 2,
        height: 28,
        fontSize: 12,
        marginRight: 5,
        marginBottom: 5
    }

}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Buzz Settings' }
]

let total_count = 0;
let total_pages = 0;

const EventAttributes = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [eventList, seteventList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [openEventDialog, setOpenEventDialog] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [editType, setEditType] = useState(null);
    const [createEventDialog, setCreateEventDialog] = useState(false);



    useEffect(() => {
        getAttributes();
    }, [currentPage, pageSize]);

    const getAttributes = () => {
        setLoading(true)
        Axios.get(ATTRIBUTES_LIST(), { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    seteventList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Attributes', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }


    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value == total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----add edit template dialogs -----
    const handleCloseTemplateDialog = (value) => {
        getAttributes();
        setOpenEventDialog(false);
        setCreateEventDialog(false);
        setSelectedTemplate(null);
    };


    const handleEditClick = (rowData) => {
        setSelectedTemplate(eventList.find((obj) => obj._id === rowData._id));
        setOpenEventDialog(true);
    };


    const handleOpenTemplateDialog = () => {
        setCreateEventDialog(true);
        setOpenEventDialog(false);
    };


    const column = [
        {
            title: "Event Category",
            field: "title",
            emptyValue: '--',
            cellStyle: { minWidth: 400 },
            editComponent: props => (
                <TextField
                    value={props.value}
                    id="category-required"
                    label="Category"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    style={{ maxWidth: 200 }}
                />
            )
        },
        {
            title: "Chosen Attributes",
            field: "attributes",
            emptyValue: '--',
            cellStyle: { maxWidth: 600 },
            render: (rowData) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', width: 400 }}>
                    {rowData?.attributes.length > 0 ? rowData?.attributes.map(el => {
                        return (
                            <Chip className={classes.event} size="small" label={el?.title} />
                        )
                    }) : '--'
                    }
                </div>
            )
        }
    ]



    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Users"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={eventList.length}
                        title="List Of Category"
                        columns={column}
                        data={eventList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        actions={[
                            {
                                icon: () => <Edit style={{ fontSize: "20px" }} />,
                                tooltip: "edit",
                                onClick: (event, rowData) => handleEditClick(rowData),
                            }
                        ]}
                        editable={{

                        }}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count} //total count
                                        page={currentPage} //page number 0,1,2,3
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            }
                        }}
                    />
                </div>
            </div>
            {openEventDialog && (
                <AttributeTemplateDialog
                    isEdit={selectedTemplate}
                    open={openEventDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    createEventDialog={createEventDialog}
                    setCreateEventDialog={setCreateEventDialog}
                    setOpenEventDialog={setOpenEventDialog}
                    handleOpenTemplateDialog={handleOpenTemplateDialog}
                />
            )}

            {createEventDialog && (
                <CreateAttributesDialog
                    isEdit={selectedTemplate}
                    open={createEventDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedTemplate={selectedTemplate}
                    createEventDialog={createEventDialog}
                    setCreateEventDialog={setCreateEventDialog}
                    getAttributes={getAttributes}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventAttributes));
