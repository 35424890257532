import React, { useEffect, useState } from 'react';
// import './App.css';
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppBar from './AppBar/AppBar';
import CommonLayout from './commonLayout/CommonLayout';
import PlatformAdmin from './Platform-admin/PlatformAdmin';


function App({ clientAuthenticated, setDeviceToken, orgDetails, getOrgDetails, setNetworkStatus }) {
  document.title = 'Ryzeup Leagues platform'

  return (
    <div className="App">
      <ThemeProvider >
        <Router>
          <AppBar />
          {/* <Loading /> */}
          <Route
            path="/"
            render={props => {
              return (
                <>
                  <CommonLayout>
                    <Switch>
                      <Route path='/platform-admin' component={PlatformAdmin} />
                      <Redirect to={`/platform-admin`} />
                    </Switch>
                  </CommonLayout>
                </>
              );
            }}
          />
        </Router>
      </ThemeProvider>
    </div>
  )

}

const mapStateToProps = (state) => ({
  clientAuthenticated: state.client.authenticated,
});

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
