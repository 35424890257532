import React, { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Switch, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import classNames from "classnames";
import { ATTRIBUTES_LIST, GET_ATTRIBUTE_KEYS } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";
import { Autocomplete } from "@material-ui/lab";



const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: ' 20px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014',
        '& .MuiFormControl-root label': {
            top: '0px'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 500
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    customToolbarRoot: {
        position: "absolute",
        top: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        right: 0,

    },
    tableRoot: {
        marginTop: 0
    }
}));


const dataTypes = [
    { name: 'String', id: 'STRING' },
    { name: 'Number', id: 'NUMBER' },
    { name: 'Boolean', id: 'BOOLEAN' },
    { name: 'Date', id: 'DATE' },
    { name: 'Time', id: 'TIME' },
    { name: 'Tiestamp', id: 'TIMESTAMP' },
    { name: 'Float', id: 'FLOAT' },
    { name: 'Object Id', id: 'OBJECTID' }
];
const basedonTypes = [
    { name: 'Contest', id: 'CONTEST' },
    { name: 'Kpis', id: 'KPIS' },
    { name: 'Users', id: 'USERS' },
    { name: 'Rewards', id: 'REWARDS' },
    { name: 'Others', id: 'OTHER' },
]
const CreateAttributesDialog = ({ open = false, handleClose, selectedTemplate, getAttributes }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        dataType: null,
        dataAttribute: null,
        based_on: null,
        name: '',
        active: false
    });
    const [keys, setKeys] = useState([]);



    useEffect(() => {
        GetAttributeKeys();
    }, [selectedTemplate]);

    //attribute keys
    const GetAttributeKeys = () => {
        Axios.get(GET_ATTRIBUTE_KEYS(), { params: { category_id: selectedTemplate?._id, page: 1, limit: 5000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.key,
                            id: el._id,
                            ...el
                        }
                    })
                    setKeys(data);
                }
            })
            .catch(error => {
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch keys', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }


    const handleChangeInput = (e, field) => {
        if (field === 'active') setDetails({ ...details, [field]: e.target.checked });
        else setDetails({ ...details, [field]: e.target.value });
    };

    const handleSubmit = () => {
        let reqObj = {
            title: details?.name,
            category_id: selectedTemplate?._id,
            data_type: details?.dataType?.id,
            source_key_id: details?.dataAttribute?._id,
            unit_of_measurement: details?.dataType?.id,
            based_on: details?.based_on?.id,
            active: details?.active
        }
        if (reqObj?.title.trim() === "") {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
        } else {
            Axios.post(ATTRIBUTES_LIST(), reqObj)
                .then(() => {
                    handleClose(false);
                    getAttributes();
                    enqueueSnackbar('Attribute added successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                })
                .catch(error => {
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Attribute', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
    }

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                Create a new attribute for {selectedTemplate?.category_name}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                id="attributes"
                                autoComplete
                                options={keys}
                                getOptionLabel={(option) => option.name}
                                value={details.dataAttribute}
                                type="Schema"
                                clearable
                                size="small"
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Data Attribute"
                                        variant="outlined"
                                        placeholder="Select"
                                        required
                                    />
                                )}
                                onChange={(e, val) => setDetails({ ...details, dataAttribute: val, dataType: val ? dataTypes.find(el => el.id === val?.data_type) : null })}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                id="datatype"
                                autoComplete
                                options={dataTypes}
                                getOptionLabel={(option) => option.name}
                                value={details.dataType}
                                type="Schema"
                                size="small"
                                clearable
                                disabled={details?.dataAttribute}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Datatype"
                                        variant="outlined"
                                        placeholder="Select"
                                        required
                                    />
                                )}
                                onChange={(e, val) => setDetails({ ...details, dataType: val })}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                id="basedon"
                                autoComplete
                                options={basedonTypes}
                                getOptionLabel={(option) => option.name}
                                value={details.based_on}
                                type="Schema"
                                size="small"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Basedon"
                                        variant="outlined"
                                        placeholder="Select"
                                        required
                                    />
                                )}
                                onChange={(e, val) => setDetails({ ...details, based_on: val })}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div className={classes.tagRoot}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    required
                                    id="name"
                                    size="small"
                                    value={details.name}
                                    type="text"
                                    label="Attribute Name"
                                    placeholder="@"
                                    autoComplete="off"
                                    onChange={(e) => handleChangeInput(e, "name")}
                                />
                            </div>
                        </Grid>
                        <Grid item={12}>
                            <div >
                                <div >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={details.active}
                                                onChange={(e) => handleChangeInput(e, 'active')}
                                                name="active"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Activate this Attribute"
                                    />
                                </div>
                                <span style={{ fontSize: 12 }}>The attribute will be activated in the category that you have selected.</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 25px" }}>

                <Button
                    onClick={() => handleClose(false)}
                    className={classes.footerButton}
                    variant="outlined"
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSubmit()}
                    className={classes.footerButton}
                    variant="contained"
                    color="primary"
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default withRouter(CreateAttributesDialog);
