import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import logo from '../assets/images/leagues-logo-1.png';
import { Avatar, Divider, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { ReactComponent as SwitchToUser } from "../assets/icons/switch-to-user.svg";
import { ReactComponent as SwitchToUserFilled } from "../assets/icons/switch-to-user-2.svg";
import { ReactComponent as NotificationIcon } from "../assets/icons/notification-1.svg";
import { ReactComponent as NotificationIconFilled } from "../assets/icons/notification-2.svg";
import { ReactComponent as AddIcon } from "../assets/icons/add.svg";
import { ReactComponent as ReactLogOut } from "../assets/icons/logout.svg";
import { orgRealm } from '..';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { getAvatarText, getEllipsisText } from '../helpers';


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
        color: 'rgb(77 77 79 / 46%)',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    logoRoot: {
        // backgroundColor: theme.palette.primary.main,
        margin: '0px 2px 0px 10px',
        borderRadius: 4.6224,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 5px 0px 0px'
        },
    },
    logo: {
        // logo styles
        width: '33px',
        height: '33px',
        maxWidth: '33px',
        maxHeight: '33px',
        border: '0.785714px solid #F0F2F5', // #4D4D4F
        boxSizing: 'border-box',
        filter: 'drop-shadow(0px 0.101036px 0.101036px rgba(0, 0, 0, 0.25))',
        padding: 3,
        borderRadius: 4.6224,
        // width: 'auto',
        // height: 'auto',
        // maxWidth: '85%',
        // maxHeight: '80%'
    },
    defautlLogo: {
        border: 'none',
        padding: 4,
        maxWidth: '33px',
        maxHeight: '33px',
        boxSizing: 'border-box',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: 230,
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
    },
    sectionMobile: {
        color: 'rgb(77 77 79 / 46%)',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    // Additional
    appBarStyles: {
        background: '#FFF',
        boxShadow: 'none',
        borderBottom: '1.5px solid #EEEEEE',
        color: '#262626'
    },
    gutters: {
        display: 'flex',
        justifyContent: 'space-between',
        // paddingLeft: 0,
        paddingLeft: 14,
        paddingRight: 14,
        // justifyContent: 'space-between',
        height: 60,
        minHeight: 60,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 5,
        },
    },
    navRoot: {
        width: 550,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    iconStyle: {
        margin: '0px 10px',
        padding: 0,
        width: 30,
        '& svg': {
            width: 21,
            height: 21,
            '& path': {
                // fill: 'rgb(77 77 79 / 46%)',
                // stroke: 'rgb(77 77 79 / 46%)'
            },
            '& path[fill="white"]': {
                fill: 'white',
            }
        },
    },
    selectedModule: {
        '& svg': {
            '& path': {
                // fill: '#4d4d4f',
                // stroke: '#4d4d4f'
            },
            '& path[fill="white"]': {
                fill: 'white',
            }
        },
        '& .MuiListItemText-root': {
            '& span': {
                color: '#4d4d4f',
                fontWeight: 600,
                textTransform: 'capitalize',
            }
        },
    },
    drawerList: {
        width: 250,
    },
    listItemStyles: {
        '& span': {
            color: 'rgb(77 77 79 / 46%)',
            fontWeight: 600,
            textTransform: 'capitalize',
        }
    },
    moreIconStyle: {
        padding: 0,
        margin: '0px 5px',
        color: 'rgb(77 77 79 / 46%)',
        // '& path': {
        //     fill: 'rgb(77 77 79 / 46%)',
        // },
        // '& path[fill="none"]': {
        //     fill: 'none',
        // },
        '&:hover': {
            background: 'none'
        }
    },
    avatarStyles: {
        width: 35,
        height: 35,
        fontSize: 16,
        backgroundColor: theme.palette.primary.main,
    },
    profilePopup: {
        width: '200px',
        top: '50px !important',
    },
    menuItemRoot: {
        height: 50,
        fontSize: 14,
        '&:hover': {
            fontWeight: 600
        }
    },
    profileIcons: {
        marginRight: 10,
        '& path': {
            // fill: theme.palette.primary.main,
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        },
        '& path[fill="white"]': {
            fill: 'none'
        }
    },
    addIconStyle: {
        // marginRight: 20,
        height: 28,
        width: 28,
        [theme.breakpoints.down('sm')]: {
            marginRight: 5
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        }
    },
}));

const AppBar = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [profile, setProfile] = React.useState({ name: props.keycloak.decoded_token.name, photoUrl: '/' });

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    useEffect(() => {

    }, [])



    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        localStorage.clear();
        console.log(window.location.href);
        props.keycloak.logout({
            redirectUri: window.location.href
        })
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            classes={{ paper: classes.profilePopup }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleLogout()}> <ReactLogOut className={classes.profileIcons} />      Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            classes={{ paper: classes.profilePopup }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <Divider />
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleLogout()}> <ReactLogOut className={classes.profileIcons} /> Logout</MenuItem>
        </Menu>
    );

    const switchApp = (url) => {
        window.location.href = url;
    }

    const handleCreatePost = () => {
        props.openCreatePostDialog({ open: true, arena: {} })
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenDrawer(open);
    };

    const drawerList = (
        <div
            className={classes.drawerList}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {props.orgDetails.modules.map((module, index) => (
                    <ListItem button key={index} onClick={() => props.history.push(`/${module.path}`)}
                        className={classNames({ [classes.selectedModule]: props.location.pathname.startsWith(`/${module.path}`) })}
                    >
                        <ListItemIcon><Icon className={classes.iconStyle}>{props.location.pathname.startsWith(`/${module.path}`) ? module.filledIcon : module.icon}</Icon></ListItemIcon>
                        <ListItemText className={classes.listItemStyles} primary={module.feature} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div className={classes.grow}>
            <MuiAppBar position="fixed" className={classes.appBarStyles}>
                <Toolbar classes={{ gutters: classes.gutters }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '25%' }}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {
                            // (props.orgDetails && props.orgDetails.logo_url) ?
                            <div className={classes.logoRoot}>  {/* 'currentColor' */}
                                <img className={classes.logo} src={(props.orgDetails && props.orgDetails.logo_url) || logo} alt="logo" width="40" height="40" />
                            </div>
                            // :
                            // <img style={{ margin: '0px 2px 0px 10px' }} width="170" src={logo} alt="logo" />
                        }
                    </div>
                    <div style={{ width: '50%' }}>
                        <div className={classes.navRoot}>
                            {props.orgDetails.modules.map((item, i) =>
                                <div key={i}>
                                    <IconButton disableTouchRipple
                                        onClick={() => props.history.push(`/${item.path}`)}
                                        title={item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())} key={i} aria-label={item.feature}
                                        color="inherit" className={classNames(classes.iconStyle, { [classes.selectedModule]: props.location.pathname.startsWith(`/${item.path}`) })}
                                    >
                                        <Badge badgeContent={0} color="secondary">
                                            {props.location.pathname.startsWith(`/${item.path}`) ? item.filledIcon : item.icon}
                                        </Badge>
                                    </IconButton>
                                    <p style={{ margin: 0, fontSize: 10, textAlign: 'center', cursor: 'pointer' }} onClick={() => props.history.push(`/${item.path}`)}>{item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.sectionDesktop} style={{ width: '25%' }}>
                        <IconButton
                            disableTouchRipple className={classes.moreIconStyle}
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            {/* <Badge color="primary" classes={{ badge: classes.statusBadge, colorPrimary: classes.statusColor }} overlap={'circle'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot"> */}
                            <Avatar
                                className={classes.avatarStyles}
                                src={profile.photo_url || profile.photoUrl}
                            >
                                {getAvatarText(profile.name)}
                            </Avatar>
                            {/* </Badge> */}
                        </IconButton>
                        <p style={{ fontSize: 14 }}>{getEllipsisText(profile.name, 8)}</p>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            style={{ padding: 8 }}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </MuiAppBar>
            {renderMobileMenu}
            {renderMenu}
            <Drawer anchor={'left'} open={openDrawer} onClose={toggleDrawer(false)}>
                {drawerList}
            </Drawer>
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    keycloak: state.keycloak,
    orgDetails: state.common.orgDetails
});

const mapDispatchToProps = (dispatch, props) => ({
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AppBar);