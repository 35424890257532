import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { GET_FUNCTIONS_LIST, GET_LEAGUE_INDUSTRIES, UPDATE_LEAGUE_INDUSTRIES, UPDATE_LEAGUE_INDUSTRIES_STATUS } from '../config';
import { Button, Chip, Switch, TablePagination, TextField } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import CustomDialog from '../components/CustomDialog';
import { Edit } from '@material-ui/icons';
import IndustryCreationDialog from './IndustryCreationDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    },
    event: {
        background: '#5D5FEF29',
        borderRadius: 4,
        padding: 2,
        height: 28,
        fontSize: 12,
        marginRight: 5,
        marginBottom: 5
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Industry Master' }
]


let total_count = 0;
let total_pages = 0;

const Industry = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [IndustryList, setIndustryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [IndustryConfirmDialog, setIndustryConfirmDialog] = useState(false);
    const [selectedIndustry, setselectedIndustry] = useState();
    const [industryOpenDialog, setIndustryOpenDialog] = useState(false);
    const [functions, setFunctions] = useState();


    useEffect(() => {
        getIndustries();
    }, [currentPage, pageSize]);

    useEffect(() => {
        getFunctions();
    }, [])


    const getIndustries = () => {
        setLoading(true)
        Axios.get(GET_LEAGUE_INDUSTRIES, { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    setIndustryList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ----------------functions------------
    const getFunctions = () => {
        Axios.get(GET_FUNCTIONS_LIST(), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.name,
                            id: el._id,
                            ...el
                        }
                    })
                    setFunctions(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    const createIndustry = (requestObj) => {
        setLoading(true)
        if (requestObj?.name.trim() === "") {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            Axios.post(GET_LEAGUE_INDUSTRIES, requestObj)
                .then(() => {
                    getIndustries();
                    setLoading(false);
                    enqueueSnackbar('Industry added successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Industry', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
    }

    const updateIndustry = (requestObj, type, resolve = () => { }, reject = () => { }) => {
        setLoading(true)
        if (type === 'active') {
            let finalReqObj = { active: requestObj.active, id: requestObj._id }
            finalReqObj['active'] = requestObj?.activeStatus;
            Axios.put(UPDATE_LEAGUE_INDUSTRIES_STATUS(finalReqObj?.id), finalReqObj)
                .then(() => {
                    getIndustries();
                    setIndustryConfirmDialog(false);
                    setLoading(false);
                    setselectedIndustry(false);
                    enqueueSnackbar('Industry Update successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                    resolve();
                })
                .catch(error => {
                    setLoading(false);
                    reject();
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Industry', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
        else {
            setselectedIndustry(requestObj);
            setIndustryOpenDialog(true);
        }

    }


    const handleToggleChange = (e, value, rowData) => {
        setselectedIndustry({ ...rowData, activeStatus: value });
        setIndustryConfirmDialog(true);
    }

    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    const column = [
        {
            title: "Industry",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 150 },
        },
        {
            title: "Description",
            field: "description",
            emptyValue: '--',
            cellStyle: { minWidth: 250 },
        },
        {
            title: "Function Assigned To Industry",
            field: "functions",
            editable: false,
            emptyValue: '--',
            cellStyle: { minWidth: 300 },
            render: (rowData) => (
                <div style={{ display: 'flex', flexWrap: 'wrap', width: 300 }}>
                    {rowData?.functions.length > 0 ? rowData?.functions.map(el => {
                        return (
                            <Chip className={classes.event} size="small" label={el?.name} />
                        )
                    }) : '--'
                    }
                </div>
            )
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]


    const handleConfirmationDialog = (value) => {
        if (value) {
            updateIndustry(selectedIndustry, 'active');
        }
        else setIndustryConfirmDialog(false);
    };


    // ----actions ---
    const handleCloseTemplateDialog = (value) => {
        if (value) getIndustries();
        setIndustryOpenDialog(false);
        setselectedIndustry();
    };
    const handleOpenDialog = () => {
        setIndustryOpenDialog(true);
    }

    const EnhancedTableToolbar = (toolBarProps) => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Industry
                    </Button>
                </div>
            </div >
        )
    };


    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Industry"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={IndustryList.length}
                        title="List Of Industry"
                        columns={column}
                        data={IndustryList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        actions={[
                            {
                                icon: () => <Edit style={{ fontSize: "20px" }} />,
                                tooltip: "edit",
                                onClick: (event, rowData) => updateIndustry(rowData),
                            }
                        ]}
                        editable={{
                            // onRowAdd: newData =>
                            // new Promise((resolve, reject) => {
                            //     createIndustry({ name: newData.name, description: newData.description }, resolve, reject);
                            // }),
                            // onRowDelete: newData =>
                            //     new Promise((resolve, reject) => {
                            //         //updateIndustry({ title: newData.title, id: newData._id, active: newData.active }, resolve, reject);
                            //     }),
                            // isDeletable: () => false,
                            // onRowUpdate: newData =>
                            //     new Promise((resolve, reject) => {
                            //         updateIndustry({ name: newData.name, description: newData.description, id: newData._id, active: newData.active }, resolve, reject);
                            //     }),
                        }}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count}
                                        page={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            {IndustryConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={IndustryConfirmDialog}
                    content={<span>Are you sure want to Update This Industry?</span>}
                    handleConfirmationDialog={handleConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
            {industryOpenDialog && (
                <IndustryCreationDialog
                    open={industryOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedIndustry={selectedIndustry}
                    IndustryList={IndustryList}
                    functions={functions}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Industry));

