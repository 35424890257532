import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { GET_OBJECT_ELEMENTS } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";
import classNames from "classnames";
import MaterialTable from "material-table";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: '20px 15px 15px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014'
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "5px 7px !important",
        height: 42,
    },
}));



const CustomElementDialog = ({ open = false, handleClose, customElements, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedElements, setSelectedElements] = useState([])
    const params = useParams()


    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (!selectedElements.length) {
            enqueueSnackbar("Please select atleast one Element.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                elements: selectedElements.map(el => el._id)
            };
            Axios.post(GET_OBJECT_ELEMENTS(params?.objId), requestObj)
                .then(() => {
                    setLoading(false);
                    handleClose(true);
                    setSelectedElements([]);
                    enqueueSnackbar('Element added successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Element', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
    };

    const selectChange = (row, data) => {
        setSelectedElements(row);
    }

    const column = [
        {
            title: "Name",
            field: "display_name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Data-Type",
            field: "data_type",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "UOM",
            field: "unit_of_measurement",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
    ]

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                Add Element
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <div className={classNames("material-table", classes.tableRoot)}>
                                    <MaterialTable
                                        key={customElements?.length}
                                        title="List Of elements"
                                        columns={column}
                                        data={customElements}
                                        options={{
                                            actionsColumnIndex: -1,
                                            showTitle: false,
                                            addRowPosition: 'first',
                                            pageSizeOptions: [10, 25, 50],
                                            emptyRowsWhenPaging: false,
                                            pageSize: 10,
                                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                                            searchFieldAlignment: "left",
                                            search: true,
                                            selection: true,
                                            showTextRowsSelected: false,
                                            headerSelectionProps: { color: 'primary' },
                                            selectionProps: rowData => ({ color: 'primary' }),
                                        }}
                                        onSelectionChange={(rows, data) => { selectChange(rows, data) }}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 0" }}>
                        <Button
                            onClick={() => handleClose(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(CustomElementDialog);
