import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import classNames from "classnames";
import { ADD_MASTER_EVENTS, GET_CATEGORY_ATRIBUTES, UPDATE_ATTRIBUTE } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";



const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: ' 12px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014'
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    customToolbarRoot: {
        position: "absolute",
        top: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        right: 0,

    },
    tableRoot: {
        marginTop: 0
    }
}));



const AttributeTemplateDialog = ({ open = false, handleClose, selectedTemplate, setLoading, editType = null, setCreateEventDialog, setOpenEventDialog, handleOpenTemplateDialog }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAttributes, setSelectedAttributes] = useState();

    useEffect(() => {
        getAttributes();
    }, [])

    const getAttributes = () => {
        Axios.get(GET_CATEGORY_ATRIBUTES(selectedTemplate?._id), { params: { page: 1, limit: 5000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.key,
                            id: el._id,
                            ...el
                        }
                    })
                    setSelectedAttributes(data);
                }
            })
            .catch(error => {
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch keys', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    const handleToggleChange = (e, value, rowData, index) => {
        let copyEventAttributes = JSON.parse(JSON.stringify(selectedAttributes));
        copyEventAttributes[index].active = value
        setSelectedAttributes(copyEventAttributes);
        updateEventStatus(value, rowData);
    }

    const updateEventStatus = (value, rowData) => {
        if (rowData) {
            setLoading(true)
            let reqObj = {
                active: value,
            }
            Axios.put(UPDATE_ATTRIBUTE(rowData?._id), reqObj)
                .then(() => {
                    // enqueueSnackbar(value ? 'Attribute activated successfully.' : 'Attribute deactivated successfully.', {
                    //     variant: 'success',
                    //     preventDuplicate: true
                    // });
                    // setLoading(false);
                }).catch(error => {
                    // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : (value ? 'Failed to activate Attribute.' : 'Failed to deactivated Attribute.'), {
                    //     variant: 'error',
                    //     preventDuplicate: true
                    // });
                    // setLoading(false);
                });
        }
    }



    const column = [
        {
            title: "Attribute",
            field: "attribute",
            emptyValue: '--',
            render: (rowData) => (
                <span>@{rowData?.attribute}</span>
            )
        },
        {
            title: "Data Type",
            field: "data_type",
            emptyValue: '--',
        },
        {
            title: "Data Attribute",
            field: "key",
            emptyValue: '--',
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData?.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData, rowData?.tableData?.id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]


    const EnhancedTableToolbar = () => {
        const classes = useStyles();
        return (
            <div>
                <div className={classes.customToolbarRoot}>
                    <div style={{ marginLeft: 8 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            disableRipple
                            className={classes.button}
                            onClick={() => handleOpenTemplateDialog()}
                        >
                            + Add Attribute
                        </Button>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                Attributes For {selectedTemplate?.category_name}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <div className={classNames("material-table", classes.tableRoot)}>
                        <MaterialTable
                            key={selectedAttributes?.length}
                            title="List Of Category"
                            columns={column}
                            data={selectedAttributes}
                            options={{
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 350 },
                                searchFieldAlignment: "left",
                                search: true,
                                paging: false
                            }}
                            actions={[]}
                            editable={{
                                isEditable: () => false,
                            }}
                            components={{
                                Toolbar: (props) => (
                                    <div>
                                        <MTableToolbar {...props} />
                                        <EnhancedTableToolbar {...props} />
                                    </div>
                                ),
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(AttributeTemplateDialog);
