import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { CUSTOM_ELEMENTS, UPDATE_ELEMENTS } from '../../config';
import { Button, Switch, TablePagination } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';
import { Check, Edit } from '@material-ui/icons';
import { setLoading } from '../../ducks/loading';
import CustomElementsDialog from './CustomElementsDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    },
    event: {
        background: '#5D5FEF29',
        borderRadius: 4,
        padding: 2,
        height: 28,
        fontSize: 12,
        marginRight: 5,
        marginBottom: 5
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Custom Elements' }
]


let total_count = 0;
let total_pages = 0;

const CustomElements = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [customElements, setCustomElements] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedElements, setSelectedElements] = useState();
    const [elementsOpenDialog, setElementsOpenDialog] = useState(false);

    useEffect(() => {
        getElementsList();
    }, [currentPage, pageSize]);

    const getElementsList = () => {
        setLoading(true)
        Axios.get(CUSTOM_ELEMENTS, { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    setCustomElements(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ----toggle changes-------
    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            Axios.put(UPDATE_ELEMENTS(rowData?._id), { active: value })
                .then(() => {
                    getElementsList();
                    setSelectedElements();
                    setLoading(false);
                    if (value)
                        enqueueSnackbar('Element activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    else
                        enqueueSnackbar('Element de-activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Element', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });

        }
    }

    // --------update Elements------
    const updateElements = (data) => {
        setSelectedElements(data);
        setElementsOpenDialog(true);
    }
    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----actions ---
    const handleCloseTemplateDialog = (value) => {
        if (value) getElementsList();
        setElementsOpenDialog(false);
        setSelectedElements();
    };
    const handleOpenDialog = () => {
        setElementsOpenDialog(true);
    }


    const column = [
        {
            title: "Name",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Display Name",
            field: "display_name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Key",
            field: "key",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Data-Type",
            field: "data_type",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "UOM",
            field: "unit_of_measurement",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Default Value",
            field: "default_value",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: 'Required',
            field: "required",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.required ? <Check style={{ color: 'green' }} /> : '--'
        },
        {
            title: 'Index By',
            field: "index",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.index ? <Check style={{ color: 'green' }} /> : '--'
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]


    const EnhancedTableToolbar = () => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Element
                    </Button>
                </div>
            </div >
        )
    };

    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Elements"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={customElements.length}
                        title="List Of Elements"
                        columns={column}
                        data={customElements}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        actions={[
                            {
                                icon: () => <Edit style={{ fontSize: "20px" }} />,
                                tooltip: "edit",
                                onClick: (event, rowData) => updateElements(rowData),
                            }
                        ]}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count}
                                        page={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>

            {elementsOpenDialog && (
                <CustomElementsDialog
                    open={elementsOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedElement={selectedElements}
                    customElements={customElements}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomElements));

