import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, makeStyles, Switch, TablePagination } from '@material-ui/core';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading } from '../ducks/loading';
import Axios from 'axios';
import { GET_FUNCTIONS_LIST, GET_INDUSTRIES_LIST, GET_KPI_LIST, UPDATE_KPI, UPDATE_KPI_STATUS } from "../config";
import CurrencyCodes from './CurrencyCodes.json';
import { Check, SettingsOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDialog from '../components/CustomDialog';
import KpiScoringRulesDialog from './KpiScoringRulesDialog';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import { getSearchValue } from '../helpers';


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    footerButton: {
        margin: 18,
        // width: 135,
        // height: 47,
        '&:last-child': {
            marginRight: 0
        }
    },
    backButton: {
        margin: 18,
        width: 135,
        height: 47,
        marginLeft: 0
    },
    footerButtonGroup: {
        margin: '0px 0px 18px 18px',
        float: 'right',
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        // width: 280
    },
    multilineInput: {
        fontSize: 14,
        padding: '15px 14px 12px'
    },
    chipRoot: {
        borderRadius: '16px !important'
    }, customToolbarRoot: {
        position: 'absolute',
        top: 5,
        // right: 115,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 100px)'
    }, customToolbarRootWithMessage: {
        position: 'absolute',
        top: 1,
        right: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 342px)'
    },
    divisionsLink: {
        fontSize: 12,
        cursor: 'pointer',
        // marginRight: 15,
        whiteSpace: 'nowrap',
        textDecoration: 'underline'
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        '& input': {
            padding: '5.5px 4px !important'
        }
        // width: 280
    },
    button: {
        textTransform: 'capitalize'
    }
}));

export const MeasurementTypes = [{ name: "Currency", id: "CURRENCY" }, { name: "Number", id: "NUMBER" }, { name: "Percentage", id: "PERCENTAGE" }, { name: 'Duration', id: 'DURATION' }];
export const kpiTagTypes = [{ name: 'Team Core', id: 'TEAM_MATCH' }, { name: 'Team Bonus', id: 'TEAM_BONUS' }, { name: 'Player Core', id: 'PLAYER_MATCH' }, { name: 'Player Bonus', id: 'PLAYER_BONUS' }];
export const durationUnits = [{ name: 'Minutes', id: 'MINUTES' }, { name: 'Hours', id: 'HOURS' }, { name: 'Days', id: 'DAYS' }];
export const ScoringUnitTypes = [{ name: 'Run', id: 'RUN' }, { name: 'Hit', id: 'HIT' }, { name: 'Goal', id: 'GOAL' },]


const defaultConfirmDialogState = {
    open: false,
    content: '',
    data: null
}


const ViewALLMasterKpis = ({ setSeason, setLoading, history, match }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const myTextIcon = React.useRef(null);
    const [data, setData] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ ...defaultConfirmDialogState });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedKpiData, setSelectedKpiData] = useState({});
    const [openKpiScoringRulesDialog, setOpenKpiScoringRulesDialog] = useState(false);
    const [editType, setEditType] = useState(null);
    const [industries, setIndustries] = useState();
    const [functions, setFunctions] = useState();

    const industry_id = getSearchValue(history, 'industry_id')
    const function_id = getSearchValue(history, 'function_id')
    useEffect(() => {
        myTextIcon.current && myTextIcon.current.parentNode.parentNode.classList.remove('MuiIconButton-root');
        getKpisList({ industry_id: industry_id, function_id: function_id });
        getIndustries();
        getFunctions();
    }, []);


    // ----------------industries------------
    const getIndustries = () => {
        Axios.get(GET_INDUSTRIES_LIST(), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.name,
                            id: el._id,
                            ...el
                        }
                    })
                    setIndustries(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }


    // ----------------functions------------
    const getFunctions = () => {
        Axios.get(GET_FUNCTIONS_LIST(), { params: { page: 0, limit: 1000 } })
            .then(res => {
                if (res.data) {
                    let data = res.data.map((el) => {
                        return {
                            name: el.name,
                            id: el._id,
                            ...el
                        }
                    })
                    setFunctions(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    const getKpisList = (params) => {
        setLoading(true)
        Axios.get(GET_KPI_LIST(), { params: { ...params, limit: 1000, page: 0 } })
            .then(res => {
                if (res.data) {
                    res.data.forEach(ele => {
                        ele.tags = ele.tags.length > 0 ? kpiTagTypes.filter(t => ele.tags.includes(t.id)) : ele.tags;
                        ele.unit_of_measurement = MeasurementTypes.find(u => u.id === ele.unit_of_measurement);
                        ele.unit = ele.unit_of_measurement?.id === "CURRENCY" ? CurrencyCodes.find(c => c.id === ele.unit) : ele.unit_of_measurement?.id === "DURATION" ? durationUnits.find(c => c.id === ele.unit) : ''
                    })
                    setData(res.data);
                    setSeason(prevState => ({ ...prevState, kpis: res.data }));
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err)
                // setLoading(false);
                // enqueueSnackbar('Failed to fetch KPIsssssss', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            });
    }


    const handleDeleteKpi = (data) => {
        setOpenDeleteDialog(true);
        setSelectedKpiData({ ...data })

    }
    const handleConfirmationDeleteDialog = (value) => {
        if (value) {
            setLoading(true);
            Axios
                .delete(UPDATE_KPI(selectedKpiData._id))
                .then(response => {
                    setLoading(false);
                    setSelectedKpiData({})
                    setOpenDeleteDialog(false);
                    getKpisList({ industry_id: industry_id, function_id: function_id });
                })
                .catch(error => {
                });
        }
        else {
            setOpenDeleteDialog(false)
            setSelectedKpiData({})
        }
    }

    const handleCloseKpiDialog = (value) => {
        setSelectedKpiData({})
        if (value) getKpisList({ industry_id: industry_id, function_id: function_id });
        setOpenKpiScoringRulesDialog(false);
        if (editType) setEditType(null)
    }

    const handleEditKpi = (data, type) => {
        setSelectedKpiData(data)
        setOpenKpiScoringRulesDialog(true);
        setEditType(type)
    }

    const handleOpenKpiDialog = () => {
        setOpenKpiScoringRulesDialog(true);
        setSelectedKpiData({});
        setTimeout(() => {
            if (!editType) myTextIcon.current.click()
        })
    }


    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            let reqObj = {
                active: value,
            }
            setLoading(true);
            Axios.put(UPDATE_KPI_STATUS(rowData._id), reqObj)
                .then(() => {
                    getKpisList({ industry_id: industry_id, function_id: function_id });
                    enqueueSnackbar(value ? 'KPI activated successfully.' : 'KPI deactivated successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    });
                    setLoading(false);
                }).catch(error => {
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : (value ? 'Failed to activate role.' : 'Failed to deactivated role.'), {
                        variant: 'error',
                        preventDuplicate: true
                    });
                    setLoading(false);
                });
        }
    }


    const breadcrumbsList = [
        { label: 'Platform Admin', to: '/platform-admin' },
        { label: 'Kpi Master', to: '/platform-admin/masterkpi' },
        { label: industries?.find(el => el._id === industry_id)?.name },
        { label: functions?.find(el => el._id === function_id)?.name }
    ]

    const columns = [
        {
            title: "KPI Name",
            field: "name",
            editable: false,
            cellStyle: { minWidth: 150 },
            render: rowData => rowData?.name
        },
        {
            title: "Industry",
            field: "industries",
            editable: false,
            render: rowData => rowData?.industries
        },
        {
            title: "Function",
            field: "function",
            editable: false,
            render: rowData => rowData?.function
        },
        {
            title: "Measurement",
            field: "unit_of_measurement",
            editable: false,
            render: (rowData) => rowData.unit_of_measurement?.name || ''
        },
        {
            title: `Team Core rule`,
            field: "matchScoringRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('TEAM_MATCH') ?
                <Check style={rowData.team_match_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Team Bonus rule`,
            field: "bonusScoringRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('TEAM_BONUS') ?
                <Check style={rowData.team_bonus_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Player Core rule`,
            field: "playerMatchRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('PLAYER_MATCH') ?
                <Check style={rowData.player_match_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Player Bonus rule`,
            field: "playerBonusRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('PLAYER_BONUS') ?
                <Check style={rowData.player_bonus_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Category"} crumbsList={breadcrumbsList} />
                <div className="material-table">
                    <MaterialTable
                        title="List Of KPI"
                        icons={{
                            Add: props => (
                                <div ref={myTextIcon}>
                                    <Button
                                        {...props}
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        className={classes.button}
                                        onClick={() => handleOpenKpiDialog()}
                                    >
                                        + Add Kpi
                                    </Button>
                                </div>
                            )
                        }}
                        columns={columns}
                        data={data}
                        options={{
                            search: true,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            actionsColumnIndex: -1,
                            showTitle: false,
                            pageSize: 10,
                            addRowPosition: 'first',
                            emptyRowsWhenPaging: false,
                            actionsCellStyle: { minWidth: "130px" }
                        }}
                        localization={{ header: { actions: "Manage rules" } }}
                        components={{
                            Pagination: props => <TablePagination {...props} rowsPerPageOptions={[10, 25, 50]} />,
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }}
                        actions={[
                            {
                                icon: () => <SettingsOutlined />,
                                tooltip: 'Manage Rule',
                                onClick: (event, rowData) => handleEditKpi(rowData, 'ALL'),
                            },
                            {
                                icon: (rowData) => <DeleteIcon />,
                                tooltip: 'Delete',
                                onClick: (event, rowData) => handleDeleteKpi(rowData),
                            }
                        ]}
                        editable={{
                            onRowAdd: newData => { }
                        }}
                    />
                    <CustomDialog
                        open={openDeleteDialog}
                        content={<span>Deleting a KPI will also remove its scoring rules and related entries in the team/bonus matches.<br /><br /> Are you sure you want to proceed further ?</span>}
                        handleConfirmationDialog={handleConfirmationDeleteDialog}
                        type={"CONFIRMATION"}
                        title={"Confirmation: Delete KPI"}
                        maxWidth={'sm'}
                        secondaryButton={{ text: 'Cancel' }}
                        primaryButton={{ text: 'YES', otherProps: { style: { backgroundColor: 'red' } } }}
                    />
                    {
                        openKpiScoringRulesDialog &&
                        <KpiScoringRulesDialog
                            open={openKpiScoringRulesDialog}
                            kpiData={JSON.parse(JSON.stringify(selectedKpiData))}
                            handleClose={handleCloseKpiDialog}
                            setLoading={setLoading}
                            editType={editType}
                            functions={functions}
                            industries={industries}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(
    null,
    mapDispatchToProps
)(ViewALLMasterKpis));

