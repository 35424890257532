import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { BUSINESS_UNITS, UPDATE_BUSINESS } from '../../config';
import { Button, Switch, TablePagination } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';
import { Edit } from '@material-ui/icons';
import BusinessUnitDialog from './BusinessUnitDialog';
import { setLoading } from '../../ducks/loading';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    },
    event: {
        background: '#5D5FEF29',
        borderRadius: 4,
        padding: 2,
        height: 28,
        fontSize: 12,
        marginRight: 5,
        marginBottom: 5
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Business Unit' }
]


let total_count = 0;
let total_pages = 0;

const BusinessUnit = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [BusinessList, setBusinessList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedBusiness, setselectedBusiness] = useState();
    const [businessOpenDialog, setBusinessOpenDialog] = useState(false);

    useEffect(() => {
        getBusinessList();
    }, [currentPage, pageSize]);

    const getBusinessList = () => {
        setLoading(true)
        Axios.get(BUSINESS_UNITS, { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    setBusinessList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ----toggle changes-------
    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            Axios.put(UPDATE_BUSINESS(rowData?._id), { active: value })
                .then(() => {
                    getBusinessList();
                    setselectedBusiness();
                    setLoading(false);
                    if (value)
                        enqueueSnackbar('Business Unit activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    else
                        enqueueSnackbar('Business Unit de-activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Business', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });

        }
    }

    // --------update business------
    const updateBusiness = (data) => {
        setselectedBusiness(data);
        setBusinessOpenDialog(true);
    }
    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----actions ---
    const handleCloseTemplateDialog = (value) => {
        if (value) getBusinessList();
        setBusinessOpenDialog(false);
        setselectedBusiness();
    };
    const handleOpenDialog = () => {
        setBusinessOpenDialog(true);
    }

    const column = [
        {
            title: "Business Unit",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 150 },
        },
        {
            title: "Short Code",
            field: "short_code",
            emptyValue: '--',
            cellStyle: { minWidth: 150 },
        },
        {
            title: "Description",
            field: "description",
            emptyValue: '--',
            cellStyle: { minWidth: 250 },
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    const EnhancedTableToolbar = () => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Business Unit
                    </Button>
                </div>
            </div >
        )
    };

    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Business"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={BusinessList.length}
                        title="List Of Business"
                        columns={column}
                        data={BusinessList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        actions={[
                            {
                                icon: () => <Edit style={{ fontSize: "20px" }} />,
                                tooltip: "edit",
                                onClick: (event, rowData) => updateBusiness(rowData),
                            }
                        ]}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count}
                                        page={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>

            {businessOpenDialog && (
                <BusinessUnitDialog
                    open={businessOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedBusiness={selectedBusiness}
                    BusinessList={BusinessList}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessUnit));

