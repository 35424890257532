import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { GET_LEAGUE_FUNCTIONS, UPDATE_LEAGUE_FUNCTIONS, UPDATE_LEAGUE_FUNCTIONS_STATUS } from '../config';
import { Button, Switch, TablePagination, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import CustomDialog from '../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Function Master' }
]


let total_count = 0;
let total_pages = 0;

const Function = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [FunctionList, setFunctionList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [FunctionConfirmDialog, setFunctionConfirmDialog] = useState(false);
    const [selectedFunction, setselectedFunction] = useState({});

    useEffect(() => {
        getFunction();
    }, [currentPage, pageSize]);

    const getFunction = () => {
        setLoading(true)
        Axios.get(GET_LEAGUE_FUNCTIONS, { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    setFunctionList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    const createFunction = (requestObj) => {
        setLoading(true)
        if (requestObj?.name.trim() === "") {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            Axios.post(GET_LEAGUE_FUNCTIONS, requestObj)
                .then(() => {
                    getFunction();
                    setLoading(false);
                    enqueueSnackbar('Function added successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Function', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
    }

    const updateFunction = (requestObj, type, resolve = () => { }, reject = () => { }) => {
        setLoading(true)
        if (type === 'active') {
            let finalReqObj = { active: requestObj.active, id: requestObj._id }
            finalReqObj['active'] = requestObj?.activeStatus;
            Axios.put(UPDATE_LEAGUE_FUNCTIONS_STATUS(finalReqObj?.id), finalReqObj)
                .then(() => {
                    getFunction();
                    setFunctionConfirmDialog(false);
                    setLoading(false);
                    enqueueSnackbar('Function Update successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                    resolve();
                })
                .catch(error => {
                    setLoading(false);
                    reject();
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Function', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
        else {
            Axios.put(UPDATE_LEAGUE_FUNCTIONS(requestObj?.id), requestObj)
                .then(() => {
                    getFunction();
                    setLoading(false);
                    enqueueSnackbar('Function Updated successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                    resolve();
                })
                .catch(error => {
                    setLoading(false);
                    reject();
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Function', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }

    }


    const handleToggleChange = (e, value, rowData) => {
        setselectedFunction({ ...rowData, activeStatus: value });
        setFunctionConfirmDialog(true);
    }

    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };

    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    const column = [
        {
            title: "Function",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 400 },
            editComponent: props => (
                <TextField
                    value={props.value}
                    id="Function-required"
                    label="Function"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    size='small'
                    autoComplete="off"
                    style={{ maxWidth: 200 }}
                />
            )
        },
        {
            title: "Description",
            field: "description",
            emptyValue: '--',
            cellStyle: { minWidth: 400 },
            editComponent: props => (
                <TextField
                    value={props.value}
                    id="description-required"
                    label="Description"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    size='small'
                    autoComplete="off"
                    style={{ maxWidth: 200 }}
                />
            )
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            cellStyle: { minWidth: 120 },
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]


    const handleConfirmationDialog = (value) => {
        if (value) {
            updateFunction(selectedFunction, 'active');
        }
        else setFunctionConfirmDialog(false);
    };


    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Function"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={FunctionList.length}
                        title="List Of Function"
                        icons={{
                            Add: props => (
                                <div>
                                    <Button
                                        {...props}
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        className={classes.button}
                                    >
                                        + Add
                                    </Button>
                                </div>
                            )
                        }}
                        columns={column}
                        data={FunctionList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    createFunction({ name: newData.name, description: newData.description }, resolve, reject);
                                }),
                            // onRowDelete: newData =>
                            //     new Promise((resolve, reject) => {
                            //         //updateFunction({ title: newData.title, id: newData._id, active: newData.active }, resolve, reject);
                            //     }),
                            // isDeletable: () => false,
                            onRowUpdate: newData =>
                                new Promise((resolve, reject) => {
                                    updateFunction({ name: newData.name, description: newData.description, id: newData._id, active: newData.active }, resolve, reject);
                                }),
                        }}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[5, 10, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count} //total count
                                        page={currentPage} //page number 0,1,2,3
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            }
                        }}
                    />
                </div>
            </div>
            {FunctionConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={FunctionConfirmDialog}
                    content={<span>Are you sure want to Update This Function?</span>}
                    handleConfirmationDialog={handleConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Function));

