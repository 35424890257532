import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { Mention, MentionsInput } from 'react-mentions';
import mentionsInputStyle from "./mentionsInputStyle";
import { GET_CATEGORY_EVENTS, MASTER_TEMPLATES_LIST, MASTER_TEMPLATE_ATTRIBUTES, MASTER_TEMPLATE_UPDATE } from "../config";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    mentionInputRoot: {
        position: 'relative',
        '& .mention-input__suggestions': {
            zIndex: '10 !important'
        },
        '& .mention-input__highlighter': {
            display: 'none',
            minHeight: '112px !important',
        },
        '& .mention-input__input': {
            border: '1px solid #0000003b !important',
            borderRadius: '3px !important',
            minHeight: '112px !important',
            fontSize: '13px !important',
            width: '100% !important',
            position: 'initial !important'
        }
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "5px 7px !important",
        height: 42,
    },
    dialogueActions: {
        // display: "flex",
        // justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    customDateLabel: {
        width: "100%",
        "& .MuiFormControl-root": { width: "100% !important" },
    },
    sheduleDate: {
        "& h6": {
            margin: "5px 0px 5px 12px",
            fontSize: 14,
        },
    },
    timestyle: {
        "& .MuiOutlinedInput-input": { cursor: "pointer" },
    },
    listStyle: {
        border: "1px solid #0000003b",
        borderRadius: 6,
        "& .MuiList-root ": {
            display: "flex",
            padding: "0px 5px",
            "& .MuiListItem-root": {
                width: "37px",
                height: "37px",
                borderRadius: "50%",
                padding: "7px 10px",
                margin: "2px 6px",
                border: "none",
                textAlign: "center",
                "& span": {
                    fontSize: "small",
                    fontWeight: "500",
                    color: "#000000",
                },
            },
            "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                "& span": { color: 'white !important' }
            },
        },
        msgTempActive: {
            "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    mentionStyle: {
        backgroundColor: theme.palette.primary.light,
        // color: theme.palette.primary.main,
        fontSize: 14,
        width: '100%'
    },
    unactiveLists: {
        background: 'none'
    },
    activeLists: {
        background: theme.palette.primary.light
    },
    unactiveShedule: { paddingTop: 0 },
    activeShedule: { paddingTop: 126 },
    textCounter: {
        // margin: '8px 8px 0px',
        fontSize: '13px !important ',
        // textAlign: 'right',
        position: 'absolute',
        top: 124,
        right: 0
    },
    textPlace: {
        fontSize: '12px',
        position: 'absolute',
        top: 112,
        left: 3,
        color: 'dimgrey'
    },
    nudgeRef: {
        border: '1px solid #0000003b !important',
        borderRadius: 3,
        width: 'auto',
        minHeight: 50,
        padding: 6,
        '& ul': {
            padding: 0,
            margin: 0,
            '& li': {
                listStyleType: 'none',
                display: 'inline-flex',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: 4,
                padding: '2px 8px',
                fontSize: 12,
                marginRight: 3,
                marginBottom: 3,
                color: theme.palette.primary.main,
                cursor: 'pointer'
            }
        }
    },
    dialogContent: {
        // minHeight: 430,
        '& .MuiInputLabel-outlined': {
            // top: '-6px !important',
            fontSize: 14
        }
    },
    dialogPaper: {
        width: '100%'
    }
}));



const MasterTemplateDialog = ({
    open = false,
    handleClose,
    selectedTemplate,
    setLoading,
    categoryList,
    editType = null,
    autoFocus = null,
    readOnly = false,
    industries,
    functions
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        industry: null,
        function: null,
        event: null,
        category: null,
        templateTitle: "",
        templateBody: "",
    });
    const [tags, setTags] = useState([]);
    const commentRef = React.useRef();
    const [categoryEvents, setCategoryEvents] = useState()
    const [categoryAttributes, setCategoryAttributes] = useState()

    useEffect(() => {
        if (details?.category) {
            getCategoryEventsList();
            details?.industry && details.function && getCategoryAttributes()
        };
    }, [details?.category]);


    useEffect(() => {
        if (selectedTemplate) {
            const templateDetails = {
                ...details,
                industry: selectedTemplate?.industry_id ? industries?.find((d) => d._id == selectedTemplate?.industry_id) : '',
                function: selectedTemplate?.function_id ? functions?.find((d) => d._id == selectedTemplate?.function_id) : '',
                category: selectedTemplate?.category ? categoryList?.find((d) => d._id == selectedTemplate?.category?.id) : '',
                event: selectedTemplate?.event ? selectedTemplate?.event : '',   //categoryEvents?.find((d) => d._id == selectedTemplate?.event_id) : '',
                templateTitle: selectedTemplate?.title,
                templateBody: selectedTemplate?.message,
            };
            setTags(selectedTemplate?.attributes);
            setDetails({ ...templateDetails });
        }
    }, [selectedTemplate]);


    //-----get events lists -----
    const getCategoryEventsList = () => {
        setLoading(true);
        Axios
            .get(GET_CATEGORY_EVENTS(details?.category?._id), { params: { page: 0, limit: 500 } })
            .then(response => {
                let data = response.data.map((el) => {
                    return {
                        name: el?.title,
                        id: el._id,
                        ...el
                    }
                })
                setDetails({ ...details, event: data?.find((d) => d.id == selectedTemplate?.event?.id) })
                setCategoryEvents(data);
                setLoading(false);
            })
            .catch(error => {
            });
    }


    //-----get attribute lists -----
    const getCategoryAttributes = () => {
        setLoading(true);
        Axios
            .get(MASTER_TEMPLATE_ATTRIBUTES(details?.category?._id), { params: { industry_id: details?.industry?._id, function_id: details?.function?._id, page: 0, limit: 500 } })
            .then(response => {
                let data = response.data.map((el) => {
                    return {
                        name: el?.attribute_name,
                        display: el?.attribute_name,
                        key: el?.attribute_key,
                        id: el?.attribute_key,
                        // ...el
                    }
                })
                setCategoryAttributes(data);
                setLoading(false);
            })
            .catch(error => {
            });
    }



    const handleChangeInput = (e, field) => {
        setDetails({ ...details, [field]: e.target.value });
    };
    const handleSelect = (val, field) => {
        if (val) {
            if (field === 'industry') {
                setDetails({ ...details, [field]: val, function: null, event: null, category: null, templateBody: '' });
                setTags([]);
            }
            else if (field === 'function') {
                setDetails({ ...details, [field]: val, event: null, category: null, templateBody: '' });
                setTags([]);
            }
            else if (field === 'category') {
                setDetails({ ...details, [field]: val, event: null, templateBody: '' });
                setTags([]);
            }
            else if (field === 'event') {
                setDetails({ ...details, [field]: val, templateBody: '' });
                setTags([]);
            }
            else {
                setDetails({ ...details, [field]: val, });
                setTags([]);
            }
        }
        else {
            setDetails({ ...details, category: null, event: null, templateBody: '' });
            setTags([]);
        }
    }


    const handleClearData = (value = false) => {
        handleClose(value);
    };

    const onchangeMentions = (e) => {
        setDetails({ ...details, templateBody: e.target.value })
    }
    const handleAddUsers = (id, display) => {
        const tagsCopy = JSON.parse(JSON.stringify(tags));
        if (!tagsCopy.map(ele => ele.id).includes(id)) setTags([...tags, { key: id, name: display }]);
    }
    const handleAppendElement = (data) => {
        const tagsCopy = JSON.parse(JSON.stringify(tags));
        setDetails({ ...details, templateBody: details.templateBody + `@@_${data.name}@_ ` })
        if (!tagsCopy.map(ele => ele.id).includes(data.key)) setTags([...tags, { ...data }]);
    }


    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (
            details.templateTitle.trim() == "" ||
            details.templateBody.trim() == "" ||
            details.category == null ||
            details.event == null ||
            details.function == null ||
            details.industry == null
        ) {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                industry_id: details?.industry ? details.industry._id : null,
                function_id: details?.function ? details.function._id : null,
                category_id: details.category ? details.category._id : null,
                event_id: details.event ? details.event._id : null,
                title: details.templateTitle,
                message: details?.templateBody.replaceAll('@_', ''),
                attributes: tags?.length !== 0 ? tags.filter((elm) => details?.templateBody.replaceAll('@_', '').includes(elm?.name)) : [],
            };
            setLoading(true);
            if (editType) {
                Axios.put(MASTER_TEMPLATE_UPDATE(selectedTemplate?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Template Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(
                            error.response && error.response.data
                                ? error.response.data.error_description
                                : "Failed to Update Template.",
                            {
                                variant: "error",
                                preventDuplicate: true,
                            }
                        );
                    });
            } else {
                Axios.post(MASTER_TEMPLATES_LIST(), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Template Created Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(
                            error.response && error.response.data
                                ? error.response.data.error_description
                                : "Failed to add Template.",
                            {
                                variant: "error",
                                preventDuplicate: true,
                            }
                        );
                    });
            }
        }
    };

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {editType ? 'Update Template' : 'Create New Template'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="industry"
                                autoComplete
                                options={industries}
                                getOptionLabel={(option) => option.name}
                                value={details?.industry}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Industry"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'industry')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="function"
                                autoComplete
                                options={functions}
                                getOptionLabel={(option) => option.name}
                                value={details?.function}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Function"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'function')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="category"
                                autoComplete
                                options={categoryList}
                                getOptionLabel={(option) => option.name}
                                value={details?.category}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'category')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="event"
                                autoComplete
                                options={categoryEvents ? categoryEvents : []}
                                getOptionLabel={(option) => option.name}
                                value={details?.event}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Event"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'event')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="template-title"
                                value={details.templateTitle}
                                type="String"
                                label="Template Title"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "templateTitle")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label style={{ fontSize: 13, fontWeight: 'bold' }}>Template Body</label>
                            <div style={{ width: 'auto' }} className={classes.mentionInputRoot}>
                                <MentionsInput
                                    readOnly={readOnly}
                                    placeholder="Use @ to mention tags"
                                    inputRef={commentRef}
                                    value={details.templateBody}
                                    onChange={e => onchangeMentions(e)}
                                    allowSuggestionsAboveCursor
                                    style={mentionsInputStyle}
                                    rows={4}
                                    maxLength={1500}
                                    autoFocus={autoFocus}
                                    className="mention-input"
                                >
                                    <Mention
                                        trigger="@"
                                        data={categoryAttributes && [...categoryAttributes]}
                                        markup="@@___display__@_" //@@___display__@_
                                        appendSpaceOnAdd
                                        displayTransform={(display) => `@${display}`}
                                        onAdd={(id, display) => handleAddUsers(id, display)}
                                    />
                                </MentionsInput>
                                <div>
                                    {details.category && <> {details?.templateBody?.length !== 0 && <span className={classes.textPlace}>Use @ to mention tags</span>}</>}
                                </div>
                            </div>
                        </Grid>
                        {
                            details?.category && categoryAttributes?.length > 0 ?
                                <Grid item xs={12}>
                                    <div className={classes.sheduleDate}>
                                        <label style={{ fontSize: 13, fontWeight: 'bold' }}> Use the below tags in your template body</label>
                                    </div>
                                    <div className={classes.nudgeRef}>
                                        <ul>
                                            {categoryAttributes && categoryAttributes.map((kpi) => {
                                                return (
                                                    <li key={kpi.key}
                                                        className={details?.templateBody.replaceAll('@_', '').includes(kpi.name) ? classes.activeLists : classes.unactiveLists}
                                                        onClick={e => handleAppendElement(kpi)}
                                                    > {kpi.name} </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </Grid>
                                : ''
                        }
                    </Grid>
                </div>
            </DialogContent>
            <div className={classes.dialogueActions}>
                <DialogActions style={{ padding: "10px 25px" }}>
                    <Button
                        onClick={() => handleClearData(false)}
                        className={classes.footerButton}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleSubmit()}
                        className={classes.footerButton}
                        variant="contained"
                        color="primary"
                    >
                        {editType ? 'Save' : 'Submit'}
                    </Button>
                </DialogActions>
            </div>
        </Dialog >
    );
};

export default withRouter(MasterTemplateDialog);
