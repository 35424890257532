/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";

const CustomAutocompleteMultiple = ({
    items,
    selectedValues,
    label,
    placeholder,
    selectAllLabel = "Select All",
    noOptionsText,
    limitTags,
    onToggleOption,
    onClearOptions,
    onSelectAll,
    getOptionLabel,
    require,
    variant = "outlined",
    classes,
    valueKey = "value",
    selectAll = false,
    disabled = false
}) => {

    const allSelected = items.length === selectedValues.length;

    const handleToggleSelectAll = () => {
        onSelectAll && onSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find(option => option[valueKey] === "select-all")) {
                handleToggleSelectAll();
            } else {
                onToggleOption && onToggleOption(selectedOptions);
            }
        } else if (reason === "clear") {
            onClearOptions && onClearOptions();
        }
    };

    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option[valueKey] === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    const inputRenderer = params => (
        <TextField {...params} label={label} placeholder={placeholder} variant={variant} require={require} />
    );

    const getOptionSelected = (option, anotherOption) =>
        option[valueKey] === anotherOption[valueKey];

    const filter = createFilterOptions();

    return (
        <Autocomplete
            multiple
            limitTags={limitTags}
            disabled={disabled}
            options={items}
            value={selectedValues}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={noOptionsText}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return selectAll ? [{ name: selectAllLabel, [valueKey]: "select-all" }, ...filtered] : [...filtered];
            }}
            onChange={handleChange}
            classes={classes}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
        />
    );
};

export default CustomAutocompleteMultiple;
