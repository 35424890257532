import { all } from "redux-saga/effects";

import s3 from "./s3";
import themes from "./themes";

function* runLoop(token) {
  let restarts = 0;

  while (true) {
    try {
      yield all([
        s3(),
        themes(),
      ]);
    } catch (e) {
      console.error("Caught error in saga, restarting:");
      console.error(e);
      restarts++;
      if (restarts > 10) {
        throw new Error("Already restarted 10 times");
      }
    }
  }
}

function* apiServiceSaga(token) {
  yield all([runLoop(token)]);
}

export default apiServiceSaga;
