import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { GET_LEAGUE_INDUSTRIES, GET_OBJECTS, OBJECT_UPDATE } from '../config';
import { Button, Switch, TablePagination } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import CustomDialog from '../components/CustomDialog';
import CustomObjectDialog from './CustomObjectDialog';
import { Delete, Edit } from '@material-ui/icons';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Custom Objects' }
]


let total_count = 0;
let total_pages = 0;

const CustomObjects = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { path } = useRouteMatch();
    const classes = useStyles();
    const [ObjectsList, setObjectsList] = useState([]);
    const [IndustryList, setIndustryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [objectConfirmDialog, setObjectConfirmDialog] = useState(false);
    const [objectDeleteConfirmDialog, setObjectDeleteConfirmDialog] = useState(false);
    const [selectedObject, setselectedObject] = useState(null);
    const [objectOpenDialog, setObjectOpenDialog] = useState(false);

    useEffect(() => {
        getIndustries()
    }, []);

    useEffect(() => {
        getObjects()
    }, [currentPage, pageSize]);

    // ----------industries--------
    const getIndustries = () => {
        Axios.get(GET_LEAGUE_INDUSTRIES, { params: { page: 0, limit: 500 } })
            .then(res => {
                if (res.data) {
                    let data = res?.data?.map(el => { return { ...el, id: el?._id } })
                    setIndustryList(data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Objects', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -----------objects--------
    const getObjects = () => {
        setLoading(true)
        Axios.get(GET_OBJECTS, { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    // ----set total count and pages ---
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    let data = res?.data?.map(el => {
                        return {
                            ...el,
                            industry_name: el?.industries?.name
                        }
                    })
                    setObjectsList(data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Objects', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -----------update object status--------
    const updateObjectStatus = (requestObj, resolve = () => { }, reject = () => { }) => {
        setLoading(true)
        let finalReqObj = { active: requestObj.active, id: requestObj._id }
        finalReqObj['active'] = requestObj?.activeStatus;
        Axios.put(OBJECT_UPDATE(finalReqObj?.id), finalReqObj)
            .then(() => {
                getObjects();
                setObjectConfirmDialog(false);
                setselectedObject(null);
                setLoading(false);
                enqueueSnackbar('Object Update successfully.', {
                    variant: 'success',
                    preventDuplicate: true
                })
                resolve();
            })
            .catch(error => {
                setLoading(false);
                reject();
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Object', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });

    }
    // -----------delete object--------
    const deleteObject = (requestObj) => {
        Axios.delete(OBJECT_UPDATE(requestObj?._id))
            .then(() => {
                getObjects();
                setselectedObject(null);
                setObjectDeleteConfirmDialog(false);
                setLoading(false);
                enqueueSnackbar('Object Deleted successfully.', {
                    variant: 'success',
                    preventDuplicate: true
                })
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Delete Object', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----toggle changes-------
    const handleToggleChange = (e, value, rowData) => {
        setselectedObject({ ...rowData, activeStatus: value });
        setObjectConfirmDialog(true);
    }
    const handleConfirmationDialog = (value) => {
        if (value) {
            updateObjectStatus(selectedObject);
        }
        else setObjectConfirmDialog(false);
    };

    // ---delete changes----
    const handleDeleteObject = (rowData) => {
        setselectedObject({ ...rowData });
        setObjectDeleteConfirmDialog(true);
    }
    const handleDeleteConfirmationDialog = (value) => {
        if (value) {
            deleteObject(selectedObject);
        }
        else setObjectDeleteConfirmDialog(false);
    };

    // ----onrow click------
    const onObjectClick = (data) => {
        props.history.push(`${path}/${data?._id}`)
    }

    // ----object dialogs actions -----
    const handleCloseTemplateDialog = (value) => {
        if (value) getObjects();
        setObjectOpenDialog(false);
        setselectedObject(null);
    };
    const handleOpenDialog = () => {
        setObjectOpenDialog(true);
        setselectedObject(null);
    }
    const updateObject = (data, type) => {
        setselectedObject(data)
        setObjectOpenDialog(true);
    }


    const column = [
        {
            title: "Object",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Industry",
            field: "industry_name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        // {
        //     title: "Source",
        //     field: "source",
        //     emptyValue: '--',
        //     editable: false
        // },
        {
            title: "Description",
            field: "description",
            emptyValue: '--',
            cellStyle: { minWidth: 200 },
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            emptyValue: '--',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    const EnhancedTableToolbar = (toolBarProps) => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Object
                    </Button>
                </div>
            </div >
        )
    };


    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Objects"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={ObjectsList?.length}
                        title="List Of Objects"
                        columns={column}
                        data={ObjectsList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        onRowClick={(e, rowData) => onObjectClick(rowData)}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Edit',
                                onClick: (event, rowData) => updateObject(rowData),
                            },
                            // {
                            //     icon: (rowData) => <Delete />,
                            //     tooltip: 'Delete',
                            //     onClick: (event, rowData) => handleDeleteObject(rowData),
                            // }
                        ]}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count} //total count
                                        page={currentPage} //page number 0,1,2,3
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            {objectConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={objectConfirmDialog}
                    content={<span>Are you sure you want to {selectedObject?.activeStatus ? 'active' : 'inactive'} This Object?</span>}
                    handleConfirmationDialog={handleConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
            {objectDeleteConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={objectDeleteConfirmDialog}
                    content={<span>Are you sure want to Delete This Object?</span>}
                    handleConfirmationDialog={handleDeleteConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
            {objectOpenDialog && (
                <CustomObjectDialog
                    open={objectOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedObject={selectedObject}
                    IndustryList={IndustryList}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomObjects));

