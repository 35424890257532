import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Keycloak from 'keycloak-js';
import InitOptions from './keycloak.json';
import configureStore from "./store/configureStore";
import JwtDecode from 'jwt-decode';
import Axios from 'axios';
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// require('dotenv').config();

const defaultNotificationProps = {
  maxSnack: 3,
  autoHideDuration: 5000,
  variant: 'info',
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
}

console.log('Origin ', window.location.host.split('.').shift());

export const orgRealm = 'platform';
// export const orgRealm = window.location.host.split('.').shift();

const keycloakInitOptions = {
  ...InitOptions,
  // realm: 'orgs1.dev.chase.wavelabs.in/admin'.split('.').shift(),
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_AUTH,
  resource: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
}


let keycloak = Keycloak(keycloakInitOptions);

keycloak.init({ onLoad: keycloakInitOptions.onLoad }).success((auth) => {

  if (!auth) {
    window.location.reload();
  } else {
    const decoded_token = JwtDecode(keycloak.token);
    const username = decoded_token.preferred_username;

    const onResponseSuccess = response => {
      console.log('onResponseSuccess ');
      return response;
    };

    const onResponseError = err => {
      console.log('onResponseError interceptors ', err.response);
      const status = err.status || err.response.status;
      if (status === 403 || status === 401) {
        alert('Authentication Failed');
        keycloak.logout();
      }
      if (status >= 500 || status === 400) {
        console.error('[axios-global]invalid request');
      }
      return window.Promise.reject(err);
    }

    console.info("Keycloak Authenticated ", username);

    Axios.interceptors.response.use(onResponseSuccess, onResponseError);
    Axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
    const store = configureStore({}, keycloak.token, username);
    store.dispatch({ type: 'LOGIN', payload: { ...keycloak, decoded_token } });

    ReactDOM.render(
      // <React.StrictMode>
      <SnackbarProvider {...defaultNotificationProps} maxSnack={3}>
        <Provider store={store}>
          {/* <ThemeProvider theme={theme}> */}
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
          {/* </ThemeProvider> */}
        </Provider>
      </SnackbarProvider>,
      // </React.StrictMode>,
      document.getElementById('root')
    );
  }

  localStorage.setItem("react-token", keycloak.token);
  localStorage.setItem("react-refresh-token", keycloak.refreshToken);

  setTimeout(() => {
    keycloak.updateToken(70).success((refreshed) => {
      if (refreshed) {
        console.debug('Token refreshed' + refreshed);
      } else {
        console.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).error(() => {
      console.error('Failed to refresh token');
    });

  }, 60000)

}).error(() => {
  console.error("Keycloak Authenticated Failed");
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
