import { all, takeEvery, call } from "redux-saga/effects";
import Axios from "axios";
import { AUTHENTICATED } from "../../ducks/client";
import { s3FileUpload } from "../../config";
import { UPLOAD_TO_S3 } from "../../ducks/common";





const uploadFilesToS3 = (payload, cancelToken) => {
    return Axios.post(s3FileUpload, payload,
        {
            headers: {
                // Authorization: 'Bearer ' + token
                "Content-Type": "multipart/form-data"
            },
            cancelToken
        });
}

function* watchForS3Uploads(client) {
    yield takeEvery(UPLOAD_TO_S3, function* uploadFiles(action) {
        try {
            let { data } = yield call(uploadFilesToS3, action.payload.formData, action.payload.cancelToken);
            // console.log('watchForS3Uploads action ', action);
            if (data) {
                yield call(action.payload.onUploadSuccessCB, data, action.payload.file);
            }
            else {
                throw 'Invalid';
            }
            // console.log('watchForS3Uploads data ', data);

        } catch (e) {
            yield call(action.payload.onUploadErrorCB, e, action.payload.file);
            console.log('watchForS3Uploads catch err ', e);
        }
    });
}

export default function* (client) {
    yield all([watchForS3Uploads(client)]);
}
