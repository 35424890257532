import moment from 'moment';
import xml_to_js from 'xml-js';
import qs from 'query-string';
import DocFormat from './assets/images/doc-format.png'
import PdfFormat from './assets/images/pdf-format.png'
import ExcelFormat from './assets/images/excel.svg';
import TextIcon from "./assets/icons/survey.svg";
import _ from 'underscore';

// This is a workaround to extract the form ID from the message delivered by plugin,
// as it is not available elsewhere
// This should be removed in favour of the ID being delivered by the 
export const parseFormIdFromMessage = (message) => {

    // <a href='jchat://pubsub.localhost?select-form&node=medivac&id=b87ecb10-def2-43cf-81fd-42384b6dd298'>===== MEDEVAC 9-Line =====</a>

    let queryParamStart = "&id=";
    let queryParamEnd = "'>";

    let queryParamStartIndex = message.body.indexOf(queryParamStart) + queryParamStart.length;
    let queryParamEndIndex = message.body.indexOf(queryParamEnd);
    let idLength = queryParamEndIndex - queryParamStartIndex;

    let id = message.body.substr(queryParamStartIndex, idLength);

    return id;
}

export const parseFileIdFromReference = (reference) => {

    let queryParamStart = "&item=";

    let queryParamStartIndex = reference.uri.indexOf(queryParamStart) + queryParamStart.length;

    return reference.uri.substr(queryParamStartIndex);
}

export const highlightText = (text, targetClassName) => {
    let inputText = document.getElementsByClassName(targetClassName);
    if (inputText) {
        Array.from(inputText).forEach(function (element) {
            let innerHTML = element.innerHTML;
            let newInnerHtml = innerHTML.toLowerCase();
            let newText = text.toLowerCase();
            let index = newInnerHtml.indexOf(newText);
            // let regEx = new RegExp(text, "ig");
            // element.innerHTML = innerHTML.replace(regEx, `<span class='highlight'>${text}</span>`);
            if (index >= 0) {
                innerHTML = innerHTML.substring(0, index) + "<span class='highlight'>" + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
                element.innerHTML = innerHTML;
            }

        })
    }
}

export const convertUnicode = (input = "") => {
    return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
}

export const replaceSpecialChars = (input = "") => {
    input = input.replace(/\"/g, "\\\"");
    input = input.replace(/\n/gi, '\\n');
    return input
}

export const generateMsg = (msg) => {
    return `%%%${JSON.stringify(msg)}%%%`
}

export const statusList = [
    { show: 'available', color: 'green' },
    { show: 'away', color: 'orange' },
    { show: 'dnd', color: 'red' },
    { show: 'unavailable', color: '#bdbdbd' },
]

export const isStanzaMsgDisplayed = (stanza) => {
    return xml_to_js.xml2js(stanza).elements[0].elements.some(obj => obj.name === 'displayed');
}

export const isStanzaMsgReceived = (stanza) => {
    return xml_to_js.xml2js(stanza).elements[0].elements.some(obj => obj.name === 'received');
}

export const getToJidWithStanza = (stanza) => {
    return xml_to_js.xml2js(stanza).elements[0].attributes.to
}

export const getFromJidWithStanza = (stanza) => {
    return xml_to_js.xml2js(stanza).elements[0].attributes.from
}

export const getMsgIdWithStanza = (stanza) => {
    return xml_to_js.xml2js(stanza).elements[0].attributes.id;
}

export const getStanzaMsgDetails = (stanza, username) => {
    const messageDisplayed = isStanzaMsgDisplayed(stanza);
    const messageReceived = isStanzaMsgReceived(stanza);
    // before api changes
    const outgoing = (messageDisplayed || messageReceived) ? getToJidWithStanza(stanza).startsWith(username) : getFromJidWithStanza(stanza).startsWith(username) ? true : false;
    const id = getMsgIdWithStanza(stanza);
    return { messageDisplayed, messageReceived, outgoing, id }
}

export const getJsonMsgDetails = (msg, username) => {
    const messageDisplayed = msg.marker && (msg.marker.type === 'displayed');
    const messageReceived = msg.marker && (msg.marker.type === 'received');
    // before api changes
    const outgoing = (messageDisplayed || messageReceived) ? (msg.to).startsWith(username) : (msg.from).startsWith(username) ? true : false
    return { messageDisplayed, messageReceived, outgoing, id: msg.marker && (msg.marker.id || msg.id) }
}

export const timeZonesList = [
    {
        title: '(GMT-11:00) Pago Pago',
        value: 'Pacific/Pago_Pago'
    },
    {
        title: '(GMT-10:00) Hawaii Time',
        value: 'Pacific/Honolulu'
    },
    {
        title: '(GMT-08:00) Pacific Time',
        value: 'America/Los_Angeles'
    },
    {
        title: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        value: 'Asia/Colombo'
    }
]

export const timeOptionsList = [
    { title: '10:00', value: '10:00' },
    { title: '10:30', value: '10:30' },
    { title: '11:00', value: '11:00' },
    { title: '11:30', value: '11:30' },
    { title: '12:00', value: '12:00' },
    { title: '12:30', value: '12:30' },
    { title: '13:00', value: '13:00' },
    { title: '13:30', value: '13:30' },
    { title: '14:00', value: '14:00' },
    { title: '14:30', value: '14:30' },
    { title: '15:00', value: '15:00' },
    { title: '15:30', value: '15:30' },
    { title: '16:00', value: '16:00' },
    { title: '16:30', value: '16:30' },
    { title: '17:00', value: '17:00' },
    { title: '17:30', value: '17:30' },
    { title: '18:00', value: '18:00' },
    { title: '18:30', value: '18:30' },
    { title: '19:00', value: '19:00' },
    { title: '19:30', value: '19:30' },
    { title: '20:00', value: '20:00' },
    { title: '20:30', value: '20:30' },
    { title: '21:00', value: '21:00' },
    { title: '21:30', value: '21:30' },
    { title: '22:00', value: '22:00' },
    { title: '22:30', value: '22:30' },
    { title: '23:00', value: '23:00' },
    { title: '23:30', value: '23:30' },
    { title: '24:00', value: '24:00' },
    { title: '24:30', value: '24:30' },
    { title: '01:00', value: '01:00' },
    { title: '01:30', value: '01:30' },
    { title: '02:00', value: '02:00' },
    { title: '02:30', value: '02:30' },
    { title: '03:00', value: '03:00' },
    { title: '03:30', value: '03:30' },
    { title: '04:00', value: '04:00' },
    { title: '04:30', value: '04:30' },
    { title: '05:00', value: '05:00' },
    { title: '05:30', value: '05:30' },
    { title: '06:00', value: '06:00' },
    { title: '06:30', value: '06:30' },
    { title: '07:00', value: '07:00' },
    { title: '07:30', value: '07:30' },
    { title: '08:00', value: '08:00' },
    { title: '08:30', value: '08:30' },
    { title: '09:00', value: '09:00' },
    { title: '09:30', value: '09:30' }
]

export const repeatOptionsList = [
    { title: 'Never', value: 'NONE' },
    { title: 'Everyday (includes Sat & Sun)', value: 'DAILY' },
    // { title: 'Every weekday', value: 'EVERY_WEEKDAY' },
    { title: 'Weekly', value: 'WEEKLY' },
    { title: 'Monthly', value: 'MONTHLY' },
    { title: 'Yearly', value: 'YEARLY' },
]

export const formatDate = (date) => date ? moment(date).format('DD MMM yyyy') : '';
export const formatDateToMonth = (date) => date ? moment(date).format('DD MMM') : '';

export const formatDateUTC = (date) => date ? moment(date).utc().format('DD MMM yyyy') : '';

export const getFormattedDate = (date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss')
}

export const getUTCFormattedDate = (date) => {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ssZ')
}

export const getLocalFormattedDate = (date) => {
    return moment(date).local().format('YYYY-MM-DDTHH:mm:ssZ')
}

export const getTime = (date) => {
    return moment(date).format('HH:mm')
}

export const getLocalTime = (date) => {
    return moment(date).local().format('HH:mm')
}

export const getTime12Hrs = (date) => {
    // return new Date(date).getHours();
    return moment(date).format('hh:mm a')
}

export const getHours = (date) => {
    return new Date(date).getHours();
}

export const getMinutes = (date) => {
    return new Date(date).getMinutes();
}

export const getAvatarText = (text) => {
    return (text ? (text.split(' ')[0].charAt(0) + (text.split(' ')[1] ? text.split(' ')[1]?.charAt(0) : '')) : '') // (userDetails.name && userDetails.name.match(/\b(\w)/g).join('').substring(0, 2)) || (userDetails.username && userDetails.username.match(/\b(\w)/g).join('').substring(0, 2))
}

export const getIntialLetters = (text) => {
    return (text ? text.split(' ')?.map(word => word.charAt(0)).join('') : '')
}

export const getFormatIcon = (type = "") => {
    switch (type) {
        case 'doc':
        case 'docx':
            return DocFormat;
        case 'pdf':
            return PdfFormat;
        case 'xlsx':
        case 'xls':
        case 'csv':
            return ExcelFormat;
        case 'txt':
            return TextIcon;
        default:
            break;
    }
}

export const ValidateEmail = (email) => {
    console.log('ValidateEmail ', email);
    if (email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }
    else return false;
}

export const getEllipsisText = (text = '', limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
}
export const downloadFiles = (uri, name) => {
    var link = document.createElement("a");
    // link.onclick = `javascript:document.execCommand('SaveAs','true', ${name})`;
    link.download = name;
    link.href = uri;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export const highlightMentions = (text, name) => {
    var regExp1 = new RegExp(`([@]${name}?)+`, 'g');
    return text.replace(regExp1, (val) => {
        return '<strong>' + `${val}` + '</strong>';
    })
}

export const handleSeeMore = ele => {
    // let ele = document.getElementById(`show-more-button-${index}`);
    // If text is shown less, then show complete
    if (ele.getAttribute("data-more") == 0) {
        ele.setAttribute("data-more", 1);
        ele.style.display = "block";
        ele.innerHTML = "See Less";

        ele.previousElementSibling.style.display = "none";
        ele.previousElementSibling.previousElementSibling.style.display =
            "inline";
    }
    // If text is shown complete, then show less
    else if (ele.getAttribute("data-more") == 1) {
        ele.setAttribute("data-more", 0);
        ele.style.display = "inline";
        ele.innerHTML = "See More";

        ele.previousElementSibling.style.display = "inline";
        ele.previousElementSibling.previousElementSibling.style.display = "none";
    }
};

export const isInViewport = (element, index) => {
    if (element) {
        const rect = element.getBoundingClientRect();
        // if (index === 1) console.log('isInViewport ', index + 1, ' == ', rect.top, rect.bottom, window.innerHeight - 200);
        return (
            rect.top <= -400 || rect.top >= (window.innerHeight - 100)
        );
    }
    else return false;
}

// export const isInViewport = (element) => {
//     if (element) {
//         const rect = element.getBoundingClientRect();
//         return (
//             rect.top >= 0 &&
//             rect.left >= 0 &&
//             rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//             rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//         );
//     }
//     else return false;
// }


export const getDuration = (date1, date2) => (date1 && date2) ? moment.duration(moment(date2).diff(date1)).asDays() : 0;

export const getCurrencyFormat = (countryCode, currencyCode = 'USD', value, decimalView = true, units = true) => `${units ? currencyCode : ''} ${Intl.NumberFormat(countryCode ? `en-${countryCode}` : false, currencyCode !== 'NA' && { currency: currencyCode, maximumFractionDigits: decimalView ? 2 : 0 }).format(value)}`;
export const getCurrencyFormatSuffix = (countryCode, currencyCode = 'USD', value, units = true) => `${Intl.NumberFormat(countryCode ? `en-${countryCode}` : false, currencyCode !== 'NA' && { currency: currencyCode, maximumFractionDigits: 2 }).format(value)} ${units ? currencyCode : ''}`;

export const getNumberFormat = (cc, value, decimalView = true) => Intl.NumberFormat(cc ? `en-${cc}` : false, { maximumFractionDigits: decimalView ? 2 : 0 }).format(value);

export const getDurationWithUnit = (value, unit = '') => ` ${value === 1 ? unit.toLowerCase().substring(0, unit.length - 1) : unit.toLowerCase()}`

export const decodeEnum = (s) => {
    if (typeof s !== 'string') return ''
    return (s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).replaceAll('_', ' ');
}

export const sortTableDataByKey = (data = [], key = '', type = '') => {
    let sorttedData = JSON.parse(JSON.stringify(data));
    if (type === 'A') sorttedData = sorttedData.sort((a, b) => ((a[key] < b[key]) ? -1 : 0));
    else if (type === 'D') sorttedData = sorttedData.sort((a, b) => ((b[key] < a[key]) ? -1 : 0));
    else sorttedData = data;
    return sorttedData;
}

export const getPollDuration = (date) => {
    const expireDate = getLocalFormattedDate(date);
    const hours = moment(formatDate(expireDate)).diff(formatDate(moment()), 'hours');
    const days = moment(formatDate(expireDate)).diff(formatDate(moment()), 'days');
    // console.log('pollDuration ', expireDate, 'Date now ', moment().toLocaleString(), ' hours ', hours, ' days ', days);
    return (
        new Date(expireDate).getTime() > Date.now() ?
            ((hours <= 24 ? '1 d' :
                hours > 312 ? '2 w' :
                    `${days} d`) + ' left') : 'Poll closed'
    )
}

export const postCreatedAtFormat = (timestamp) => {
    return (moment().diff(timestamp, 'days') > 6 && moment().year() !== moment(timestamp).year()) ? "DD MMM YYYY" : moment().diff(timestamp, 'days') > 6 ? "DD MMM" : ''
}

export const buzzTimeStamp = (date) => {
    const craetedDate = new Date(date);
    const diff = moment(new Date()).diff(moment(craetedDate), 'hours')
    if (diff <= 24) return moment(craetedDate).calendar();
    else return moment(craetedDate).format('lll');
}

export const getValue = (value) => value ? value : '--';

export const getSearchValue = (history, key) => {
    const query = qs.parse(history.location.search);
    return query[key];
}

export const QueryTypes = {
    league: ['season', 'round', 'match', 'bonusMatch', 'viewBy', 'team', 'player', 'role'],
    season: ['round', 'match', 'bonusMatch', 'viewBy', 'team', 'player', 'role'],
    round: ['match', 'bonusMatch', 'viewBy'],
    match: [],
    bonusMatch: [],
    viewBy: []
}

export const setSearchQuery = (history, obj = {}) => {
    const query = qs.parse(history.location.search);
    const deletableList = QueryTypes[Object.keys(obj).length > 0 && Object.keys(obj)[0]] || []
    deletableList.forEach(ele => {
        delete query[ele];
    });
    history.push({ search: qs.stringify({ ...query, ...obj }) });
}

export const historyPushWithSearch = (path, history, search, additionaObj = {}) => {
    let query = {};
    if (typeof search === 'string') {
        const obj = qs.parse(search);
        query = { league: obj.league, season: obj.season, round: obj.round };
    }
    else if (typeof search === 'object')
        query = { league: search.league, season: search.season, round: search.round };
    history.push({ pathname: path, search: qs.stringify({ ...query, ...additionaObj }) });
}

export const seasonKpiPositionsSort = (kpisIndexBy, dataKpis, key = 'kpi_id') => {
    kpisIndexBy = _.indexBy(kpisIndexBy, key);
    return _.compact(dataKpis?.map(el => {
        if (kpisIndexBy[el._id]) {
            const planedAtrributes = ['PLANNED_TARGET', 'PLANNED_ACTUAL', 'PLANNED_PENDING_ACTUAL', 'PLANNED_ACHIEVEMENT']
            const summary_attributes = !el?.achievement_plan_enabled ? el?.summary_attributes.filter(el => !planedAtrributes.includes(el.attribute)).sort((a, b) => a.sequence_no - b.sequence_no).filter(e => e.view) : el?.summary_attributes.sort((a, b) => a.sequence_no - b.sequence_no).filter(e => e.view)
            const detailed_attributes = !el?.achievement_plan_enabled ? el?.detailed_attributes.filter(el => !planedAtrributes.includes(el.attribute)).sort((a, b) => a.sequence_no - b.sequence_no).filter(e => e.view) : el?.detailed_attributes.sort((a, b) => a.sequence_no - b.sequence_no).filter(e => e.view)
            return {
                ...kpisIndexBy[el._id],
                tags: el.tags,
                sequence_no: el.sequence_no,
                summary_attributes: summary_attributes,
                detailed_attributes: detailed_attributes,
                copy_summary_attributes: summary_attributes.map(ele => { return ele.attribute }),
                copy_detailed_attributes: detailed_attributes.map(ele => { return ele.attribute })
            }
        } else {
            return null
        }
    }))
};


export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
};

export const getBase64WithUrl = async (url) => {
    if (url) {
        const data = await fetch(`${url}?t=1`);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }
    else {
        return new Promise((resolve) => {
            resolve('');
        });
    }
};
