import React, { useState } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import CustomAutocompleteMultiple from '../../components/CustomAutocompleteMultiple';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        // width: 280
    },
}));

const SelectRolesDialog = ({ handleDialog, open, rolesList, ...other }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [roles, setRoles] = useState([]);

    const handleToggleOption = selectedOptions =>
        setRoles(selectedOptions);
    const handleClearOptions = () => setRoles([]);
    const handleSelectAll = isSelected => {
        if (isSelected) {
            setRoles(rolesList);
        } else {
            handleClearOptions();
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="role-creation"
            open={open}
            onClose={() => handleDialog(false)}
            {...other}
        >
            <DialogTitle disableTypography className={classes.title}>
                Copy Rule To:
            </DialogTitle>
            <DialogContent style={{ padding: 24, width: 350 }}>
                <CustomAutocompleteMultiple
                    items={rolesList}
                    getOptionLabel={(option) => option.name || ''}
                    limitTags={2}
                    selectedValues={roles}
                    valueKey="_id"
                    label="Roles"
                    selectAll
                    selectAllLabel="Select All"
                    onToggleOption={handleToggleOption}
                    onClearOptions={handleClearOptions}
                    onSelectAll={handleSelectAll}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                />
            </DialogContent>
            <DialogActions style={{ padding: 25 }}>
                <Button variant='outlined' onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                <Button disabled={roles.length === 0} variant='contained' style={{ marginLeft: 30 }} onClick={() => handleDialog(true, roles)} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectRolesDialog;
