import React, { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { CUSTOM_ELEMENTS, UPDATE_ELEMENTS } from "../../config";
import { dataTypes } from "../ObjectElements";
import { MeasurementTypes } from "../CustomKpiTable";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: '20px 15px 15px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014'
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "5px 7px !important",
        height: 42,
    },
}));



const CustomElementsDialog = ({ open = false, handleClose, objectId, selectedElement, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        display_name: '',
        key: '',
        data_type: null,
        unit_of_measurement: null,
        description: '',
        default_value: '',
        required: null,
        index: null
    });

    useEffect(() => {
        if (selectedElement) {
            const elementDetails = {
                ...details,
                name: selectedElement?.name,
                display_name: selectedElement?.display_name,
                key: selectedElement?.key,
                data_type: selectedElement?.data_type ? dataTypes?.find((d) => d.id === selectedElement?.data_type) : null,
                unit_of_measurement: selectedElement?.unit_of_measurement ? MeasurementTypes?.find((d) => d.id === selectedElement?.unit_of_measurement) : null,
                description: selectedElement?.description,
                default_value: selectedElement?.default_value,
                required: selectedElement?.required ? selectedElement?.required : null,
                index: selectedElement?.index ? selectedElement?.index : null,
            };
            setDetails({ ...elementDetails });
        }
    }, [selectedElement]);


    const handleChangeInput = (e, field) => {
        if (['required', 'index'].includes(field)) setDetails({ ...details, [field]: e.target.checked });
        else setDetails({ ...details, [field]: e.target.value });
    }

    const handleSelect = (val, field) => {
        if (val) {
            setDetails({ ...details, [field]: val });
        }
    }

    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (
            details?.name.trim() === "" ||
            details?.data_type == null
        ) {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                name: details?.name,
                display_name: details?.display_name,
                key: details?.key,
                data_type: details?.data_type ? details?.data_type?.id : null,
                unit_of_measurement: details?.unit_of_measurement ? details?.unit_of_measurement?.id : null,
                description: details?.description,
                default_value: details?.default_value,
                required: details?.required ? details?.required : null,
                index: details?.index ? details?.index : null,
            };
            setLoading(true);
            if (selectedElement) {
                Axios.put(UPDATE_ELEMENTS(selectedElement?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Element Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Element', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            } else {
                Axios.post(CUSTOM_ELEMENTS, requestObj)
                    .then(() => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar('Element added successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Element', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
        }
    };


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {selectedElement ? 'Update Element' : 'Add Element'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                disabled={selectedElement}
                                variant="outlined"
                                id="name"
                                value={details.name}
                                type="String"
                                label="Name"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "name")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="display_name"
                                value={details.display_name}
                                type="String"
                                label="Display Name"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "display_name")}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="key"
                                disabled={selectedElement}
                                value={details.key}
                                type="String"
                                label="Key"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "key")}
                            />
                        </Grid> */}
                        <Grid item xs={6}>
                            <Autocomplete
                                id="data_type"
                                disabled={selectedElement}
                                autoComplete
                                options={dataTypes}
                                getOptionLabel={(option) => option.name}
                                value={details?.data_type}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Datatype"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'data_type')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="unit_of_measurement"
                                autoComplete
                                disabled={selectedElement}
                                options={MeasurementTypes}
                                getOptionLabel={(option) => option.name}
                                value={details?.unit_of_measurement}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Measurement"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'unit_of_measurement')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                disabled={selectedElement}
                                id="default_value"
                                value={details.default_value}
                                type="String"
                                label="Default Value"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "default_value")}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={details.required}
                                        onChange={(e) => handleChangeInput(e, "required")}
                                        name="Required"
                                        color="primary"
                                    />
                                }
                                label="Required"
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={details.index}
                                        onChange={(e) => handleChangeInput(e, "index")}
                                        name="Index By"
                                        color="primary"
                                    />
                                }
                                label="Index By"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 150 }}
                                variant="outlined"
                                id="description"
                                value={details.description}
                                type="String"
                                label="Description"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "description")}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 0" }}>
                        <Button
                            onClick={() => handleClose(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            {selectedElement ? 'Save' : 'Submit'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(CustomElementsDialog);
