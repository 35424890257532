import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Dialog, DialogActions, Grid, Tab, Tabs, DialogContent, DialogTitle, TablePagination, Divider, Radio, IconButton, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { MTableToolbar } from 'material-table';
import classNames from 'classnames';
import { slabTypes, scoreAllocatesSr1, scoreAllocatesSr2Sr3, getRuleName, matchScoringConditions } from '../KpiScoringRulesDialog';
const useStyles = makeStyles(theme => ({
    root: {
        padding: "25px",
        width: "100%",
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        // width: 280
    },
    tabsRoot: {
        // padding: '14px 0px',
        minWidth: 280,
        // maxWidth: 400,
        // margin: 'auto'
        // borderBottom: '1px solid #0000003a',
        paddingBottom: 0,
        margin: "15px 0px 30px 0px"
    },
    tabsFixed: {
        maxWidth: 760
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 140,
        color: '#838383',
    },
    tabWrapper: {
        flexDirection: 'row',
        // justifyContent: 'flex-start',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 10px 0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px',
        border: 'none',
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        // width: 280
    },
    tableNoteStyles: {
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        }

    },

    customToolbarRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 80px)'
    }
}));

const TeamMatchScoringRule = ({ teamMatchData, viewAddtionalScores, handleChangeAutocomplete, handleChangeInput, handleViewAddtionalScore, handleTeamToggle, handelChangeTeamMatchData, details, seasonDetails, getUnit, scoringUnit }) => {
    const myTextIcon = React.useRef(null);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const handleCreate = (rowData, resolve, reject) => {
        let newData = teamMatchData.additionalScores ? teamMatchData.additionalScores : [];
        if (!(rowData.type && rowData.min && rowData.max && rowData.allocate_to && rowData.score)) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (Number(rowData.min) >= Number(rowData.max)) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => ((Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)) && (Number(rowData.max) >= Number(ele.min) && Number(rowData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) < Number(ele.min) && Number(rowData.max) > Number(ele.max)))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.max) > Number(ele.min) && Number(rowData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else {
            newData.unshift(rowData);
            handelChangeTeamMatchData('additionalScores', newData)
            resolve()
        }
    }

    const handleUpdate = (newData, oldData, resolve, reject) => {
        const compareData = [...teamMatchData.additionalScores];
        const index = oldData.tableData.id;
        compareData.splice(index, 1);
        if (!(newData.min && newData.max && newData.allocate_to && newData.score)) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (Number(newData.min) >= Number(newData.max)) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => ((Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)) && (Number(newData.max) >= Number(ele.min) && Number(newData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) < Number(ele.min) && Number(newData.max) > Number(ele.max)))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.max) > Number(ele.min) && Number(newData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else {
            const dataUpdate = [...teamMatchData.additionalScores];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            handelChangeTeamMatchData('additionalScores', dataUpdate)
            resolve();
        }
    }

    const handleDelete = (oldData, resolve, reject) => {
        const dataDelete = [...teamMatchData.additionalScores];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        handelChangeTeamMatchData('additionalScores', dataDelete)
        resolve();
    }

    const handleSlabsCreate = (rowData, resolve, reject, slabType) => {
        let newData = slabType === 1 ? (teamMatchData.penaltySlabs ? teamMatchData.penaltySlabs : []) : (teamMatchData.slabs ? teamMatchData.slabs : []);
        if (!(rowData.type && rowData.min && rowData.max && (teamMatchData.condition?.id === 'SR6' ? rowData.multiplication_factor : rowData.points))) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(rowData.min) < Number(teamMatchData.minimumScore)) : (Number(rowData.min) >= Number(teamMatchData.minimumScore)))) {
            enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(rowData.max) < Number(teamMatchData.minimumScore)) : (Number(rowData.max) > Number(teamMatchData.minimumScore)))) {
            enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (Number(rowData.min) >= Number(rowData.max)) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(rowData.multiplication_factor) < 0) : (Number(rowData.multiplication_factor) >= 0))) {
            enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => ((Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)) && (Number(rowData.max) >= Number(ele.min) && Number(rowData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) < Number(ele.min) && (ele.max !== null && Number(rowData.max) > Number(ele.max))))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.max) > Number(ele.min) && Number(rowData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 1 && teamMatchData?.minimumScore <= 0) {
            enqueueSnackbar(`Penalty slabs can be defined only below certain threshold minimum eligibility.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            newData.unshift(rowData);
            console.log('slabs ', newData);
            handelChangeTeamMatchData(slabType === 1 ? 'penaltySlabs' : 'slabs', newData)
            resolve()
        }
    }

    const handleSlabsUpdate = (newData, oldData, resolve, reject, slabType) => {
        const compareData = slabType === 1 ? [...teamMatchData.penaltySlabs] : [...teamMatchData.slabs];
        const index = oldData.tableData.id;
        compareData.splice(index, 1);
        if (!(newData.type && newData.min && (newData.type.id === 'BETWEEN' ? newData.max : true) && (teamMatchData.condition?.id === 'SR6' ? newData.multiplication_factor : newData.points))) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(newData.min) < Number(teamMatchData.minimumScore)) : (Number(newData.min) >= Number(teamMatchData.minimumScore)))) {
            enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(newData.max) < Number(teamMatchData.minimumScore)) : (Number(newData.max) > Number(teamMatchData.minimumScore)))) {
            enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.type.id === 'BETWEEN' ? (Number(newData.min) >= Number(newData.max)) : false) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamMatchData.condition?.id === 'SR6' && (slabType === 0 ? (Number(newData.multiplication_factor) < 0) : (Number(newData.multiplication_factor) >= 0))) {
            enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => ((Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)) && (Number(newData.max) >= Number(ele.min) && Number(newData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) < Number(ele.min) && Number(newData.max) > Number(ele.max)))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.max) > Number(ele.min) && Number(newData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 1 && teamMatchData?.minimumScore <= 0) {
            enqueueSnackbar(`Penalty slabs can be defined only below certain threshold minimum eligibility.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            const dataUpdate = slabType === 1 ? [...teamMatchData.penaltySlabs] : [...teamMatchData.slabs];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            console.log('slabs ', dataUpdate);
            handelChangeTeamMatchData(slabType === 1 ? 'penaltySlabs' : 'slabs', dataUpdate)
            resolve();
        }
    }

    const handleSlabsDelete = (oldData, resolve, reject, slabType) => {
        const dataDelete = slabType === 1 ? [...teamMatchData.penaltySlabs] : [...teamMatchData.slabs];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        handelChangeTeamMatchData(slabType === 1 ? 'penaltySlabs' : 'slabs', dataDelete)
        resolve();
    }

    const handleChangeMinimumEligibility = (e) => {
        const minEligibility = e.target.value;
        // console.log('slabs ', minEligibility);
        if (minEligibility) {
            const emptySlabsPositive = [0, 1, 2, 3].map(item => ({ min: null, max: item ? null : 99999, multiplication_factor: null, tableData: { id: item }, type: item ? { name: 'Between', id: 'BETWEEN' } : { name: 'Above', id: 'ABOVE' } }));// teamMatchData.slabs ? teamMatchData.slabs : [];
            if (teamMatchData.slabs?.length === 0)
                handelChangeTeamMatchData('slabs', emptySlabsPositive);
        }
        else handelChangeTeamMatchData('slabs', []);
        if (minEligibility > 0) {
            const emptySlabsPenalty = [0, 1, 2, 3].map(item => ({ min: null, max: null, multiplication_factor: null, tableData: { id: item }, type: { name: 'Between', id: 'BETWEEN' } }));// teamMatchData.slabs ? teamMatchData.slabs : [];
            if (teamMatchData.penaltySlabs?.length === 0)
                handelChangeTeamMatchData('penaltySlabs', emptySlabsPenalty);
        }
        else handelChangeTeamMatchData('penaltySlabs', []);

        handleChangeInput(e, 'minimumScore', 'TEAM_MATCH');
    }

    const slabsColumns = [
        {
            title: "Score",
            field: "points",
            editComponent: props => (
                <Autocomplete
                    id="reward-level"
                    options={[...Array(10)].map((item, i) => (i + 1))}
                    getOptionLabel={(option) => `${option} ${getRuleName(scoringUnit?.id, option)}` || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 120 }} label='Score' variant="outlined" required />}
                    onChange={(...args) => props.onChange(args[1])}
                />
            ),
            render: (rowData) => `${rowData.points} ${getRuleName(scoringUnit?.id, rowData.points)}`
        }];

    const slabsAndProrataColumns = [
        {
            title: "Multiplication factor",
            field: "multiplication_factor",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    label='Multiplication factor'
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    // InputProps={{ endAdornment: `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` }}
                    style={{ minWidth: 140, maxWidth: 200 }}
                />
            ),
            // render: (rowData) => `${rowData.multiplication_factor}`
        }
    ]

    const matchColumns = (isPenaltySlabs) => [
        {
            title: "Type",
            field: "type",
            // hidden: teamMatchData.condition.id !== 'SR1',
            editComponent: props => (
                <Autocomplete
                    id="type"
                    options={teamMatchData.condition?.id !== 'SR4' ? (teamMatchData.condition?.id === 'SR6' && isPenaltySlabs) ? slabTypes.filter(ele => ele.id != 'ABOVE') : ((teamMatchData?.additionalScores?.length && teamMatchData.additionalScores.some(ele => ele?.type?.id === 'ABOVE')) ? slabTypes.filter(ele => ele.id != 'ABOVE') : slabTypes)
                        : (teamMatchData?.slabs?.length && teamMatchData?.slabs.some(ele => ele?.type?.id === 'ABOVE')) ? slabTypes.filter(ele => ele.id != 'ABOVE') : slabTypes}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 125 }} label='Type' variant="outlined" required />}
                    onChange={(...args) => {
                        if (args[1] && Object.keys(args[1]).length > 0 && args[1]?.id === 'ABOVE') {
                            props.rowData.max = 99999;
                        }
                        else props.rowData.max = null;
                        props.onChange(args[1])
                    }}
                />
            ),
            render: (rowData) => rowData.type?.name,
        },
        {
            title: `Greater than or equal to`,
            field: "min",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    label='Greater than or equal to'
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    InputProps={{ endAdornment: `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` }}
                    style={{ minWidth: 140, maxWidth: 200 }}
                />
            ),
            render: (rowData) => (rowData.min !== null) ? rowData.min + `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` : '--'
        },
        {
            title: `Less than`,
            field: "max",
            editComponent: props => {
                if (props.rowData && props.rowData?.type?.id === 'BETWEEN') {
                    return (
                        <TextField
                            value={props.value}
                            type="number"
                            id="outlined-required"
                            label='Less than'
                            onChange={e => props.onChange(e.target.value)}
                            variant="outlined"
                            fullWidth
                            required
                            autoComplete="off"
                            InputProps={{ endAdornment: `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` }}
                            style={{ minWidth: 140, maxWidth: 200 }}
                        />
                    )
                }
                else return null
            },
            render: (rowData) => rowData?.type?.id === 'BETWEEN' ? ((rowData.max !== null) ? (rowData.max + `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}`) : '--') : '--'
        },
        {
            title: "Score",
            field: "score",
            editComponent: props => (
                <Autocomplete
                    id="reward-level"
                    options={[...Array(10)].map((item, i) => (i + 1))}
                    getOptionLabel={(option) => `${option} ${getRuleName(scoringUnit?.id, option)}` || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 120 }} label='Score' variant="outlined" required />}
                    onChange={(...args) => props.onChange(args[1])}
                />
            ),
            render: (rowData) => `${rowData.score} ${getRuleName(scoringUnit?.id, rowData.score)}`
        },
        {
            title: "Allocate to",
            field: "allocate_to",
            editComponent: props => (
                <Autocomplete
                    id="reward-level"
                    options={teamMatchData.condition === 'SR1' ? scoreAllocatesSr1 : scoreAllocatesSr2Sr3}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 310 }} label='Allocate to' variant="outlined" required />}
                    onChange={(...args) => props.onChange(args[1])}
                />
            ),
            render: (rowData) => rowData.allocate_to?.name,
        }];

    const TeamEnhancedTableToolbar = props => {
        const classes = useStyles();
        return (
            <div style={!teamMatchData.condition?.id ? { position: 'relative', width: '100%' } : {}} className={classes.customToolbarRoot}>
                {teamMatchData?.condition?.id !== 'SR6' ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12 }}>{teamMatchData?.condition?.id === 'SR4' ? "Measure performance in" : "Measure over achievement in"} : </p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, fontSize: 12 }} onClick={() => handleTeamToggle('ACHIEVEMENT')}>
                        <Radio color="primary" checked={teamMatchData.basedOn === 'ACHIEVEMENT'} size="small" /> Achievement %</p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, marginLeft: 15, fontSize: 12 }} onClick={() => handleTeamToggle('ACTUALS')}>
                        <Radio color="primary" checked={teamMatchData.basedOn === 'ACTUALS'} size="small" />Actuals</p>
                </div>
                    :
                    <p>{props.index === 0 ? 'Scoring slabs' : 'Penalty slabs'}</p>
                }
                {
                    (!teamMatchData.condition?.id) &&
                    <Button
                        color="primary"
                        variant="contained"
                        disabled
                        style={{ pointerEvents: 'auto' }}
                        title={"Please select scoring condition to add Additional Scores"}
                    >Add</Button>
                }

            </div>
        )
    };
    const AdditionalScoreField = () => {
        return (
            <Autocomplete
                id="additional-score-view"
                options={[{ name: 'Yes', value: 'YES' }, { name: 'No', value: 'NO' }]}
                getOptionLabel={(option) => option.name || ''}
                value={viewAddtionalScores === 'YES' ? { name: 'Yes', value: 'YES' } : { name: 'No', value: 'NO' }}
                disableClearable
                classes={{ inputRoot: classes.autocompleteRoot }}
                renderInput={(params) => <TextField
                    {...params} style={{ minWidth: 170, maxWidth: 300 }} label="Additional scores for over achievement" variant="outlined" />}
                onChange={(...args) => handleChangeAutocomplete(args[1], 'additionalScore', 'TEAM_MATCH')}
            />
        )
    }
    const EnhancedToolbarForPerformance = props => {
        const classes = useStyles();
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ padding: 0, margin: 0, fontSize: 12 }}>Measure performance in : </p>
                <p style={{ cursor: 'pointer', padding: 0, margin: 0, fontSize: 12 }} onClick={() => handleTeamToggle('ACHIEVEMENT')}>
                    <Radio color="primary" checked={teamMatchData.basedOn === 'ACHIEVEMENT'} size="small" /> Achievement %</p>
                <p style={{ cursor: 'pointer', padding: 0, margin: 0, marginLeft: 15, fontSize: 12 }} onClick={() => handleTeamToggle('ACTUALS')}>
                    <Radio color="primary" checked={teamMatchData.basedOn === 'ACTUALS'} size="small" />Actuals</p>
            </div>
        )
    };
    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="scoring-condition"
                            options={matchScoringConditions}
                            getOptionLabel={(option) => option.name || ''}
                            value={teamMatchData?.condition}
                            // style={{ width: 260 }}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => <TextField {...params} style={{ minWidth: 170 }} label="Scoring Condition" variant="outlined" required />}
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'condition', 'TEAM_MATCH')}
                        />
                    </Grid>
                    {
                        (teamMatchData.condition && teamMatchData.condition?.id === 'SR1') &&
                        <Grid item xs={2}>
                            <TextField
                                value={teamMatchData?.minimumScore}
                                type="number"
                                id="outlined-required"
                                label="Min Eligibility"
                                onChange={e => handleChangeInput(e, 'minimumScore', 'TEAM_MATCH')}
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                // required={(props.rowData.scoringCondition && props.rowData.scoringCondition.id === 'SR1')}
                                InputProps={{ endAdornment: "%" }}
                                style={{ minWidth: 80 }}
                            />
                        </Grid>
                    }
                    {
                        (teamMatchData.condition && ['SR4', 'SR5', 'SR6'].indexOf(teamMatchData.condition?.id) === -1) &&
                        <Grid item xs={2} >
                            <Autocomplete
                                id="score"
                                options={[...Array(10)].map((item, i) => (i + 1))}
                                getOptionLabel={(option) => `${option} ${getRuleName(scoringUnit?.id, option)}`}
                                value={teamMatchData?.score}
                                // style={{ width: 120 }}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} style={{ minWidth: 120, }} label="score" variant="outlined" required />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'score', 'TEAM_MATCH')}
                            />
                        </Grid>
                    }
                    {
                        (teamMatchData.condition && teamMatchData.condition?.id === 'SR5' || teamMatchData.condition?.id === 'SR6') &&
                        <>
                            <Grid item xs={12}>
                                <EnhancedToolbarForPerformance />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={teamMatchData?.minimumScore}
                                    type="number"
                                    id="outlined-required"
                                    label="Min Eligibility"
                                    onChange={e => teamMatchData.condition?.id === 'SR6' ? handleChangeMinimumEligibility(e) : handleChangeInput(e, 'minimumScore', 'TEAM_MATCH')}
                                    variant="outlined"
                                    fullWidth
                                    style={{ maxWidth: 250 }}
                                    autoComplete="off"
                                    required={(teamMatchData.condition && teamMatchData.condition?.id === 'TBSR2')}
                                    InputProps={{ endAdornment: teamMatchData.basedOn === 'ACHIEVEMENT' ? "%" : '' }}
                                />
                            </Grid>
                            {
                                teamMatchData?.condition?.id !== 'SR6' &&
                                <Grid item xs={3}>
                                    <TextField
                                        value={teamMatchData?.multiplicationFactor}
                                        type="number"
                                        id="outlined-required"
                                        label="Multiplication factor"
                                        onChange={e => handleChangeInput(e, 'multiplicationFactor', 'TEAM_MATCH')}
                                        variant="outlined"
                                        fullWidth
                                        style={{ maxWidth: 250 }}
                                        autoComplete="off"
                                        required={(teamMatchData.condition && teamMatchData.condition?.id === 'SR5')}
                                    />
                                </Grid>

                            }
                        </>
                    }
                    <Grid item xs={4}>
                        {
                            (teamMatchData.condition && ['SR4', 'SR5', 'SR6'].indexOf(teamMatchData.condition?.id) === -1) &&
                            <AdditionalScoreField />
                        }
                    </Grid>
                </Grid>

                {
                    viewAddtionalScores === 'YES' && <>
                        <p style={{ marginTop: 15, marginBottom: -5, fontSize: 12, fontWeight: 'bold' }}>Additional scores:</p>
                        <div className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                // title={<p className={"note"}>Additional Scores</p>}
                                title={''}
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={matchColumns()}
                                data={teamMatchData?.additionalScores}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    // showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                }}
                                components={{
                                    Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                    Toolbar: props => (
                                        <div>
                                            {
                                                (teamMatchData.condition && teamMatchData.condition?.id) && <MTableToolbar {...props} />
                                            }
                                            <TeamEnhancedTableToolbar />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleCreate(newData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleUpdate(newData, oldData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(oldData, resolve, reject)
                                            return;
                                        })
                                }}
                            />
                        </div>
                    </>
                }
                {
                    (teamMatchData.condition && teamMatchData.condition?.id === 'SR4') &&
                    <>
                        {/* <p style={{ fontSize: 12, fontWeight: 'bold' }}>Additional Scores :</p> */}
                        <div style={{ marginTop: 15 }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title=''
                                // title={'Additional Scores'}
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={[...matchColumns().filter((ele, i) => i <= 2), ...slabsColumns]}
                                data={teamMatchData?.slabs}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    // showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    // toolbar: !readOnly
                                }}
                                components={{
                                    Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <TeamEnhancedTableToolbar />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSlabsCreate(newData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSlabsUpdate(newData, oldData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleSlabsDelete(oldData, resolve, reject)
                                            return;
                                        })
                                }}
                            />
                        </div>
                    </>
                }
                {
                    (teamMatchData.condition && teamMatchData.condition?.id === 'SR6') &&
                    <>
                        {/* <p style={{ fontSize: 12, fontWeight: 'bold' }}>Additional Scores :</p> */}
                        {[0, 1].map(item => <div key={item} style={{ marginTop: 15 }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title=''
                                // title={'Additional Scores'}
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={[...matchColumns(item === 1).filter((ele, i) => i <= 2), ...slabsAndProrataColumns]}
                                data={item ? teamMatchData?.penaltySlabs : teamMatchData?.slabs}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    // showTitle: true,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    // toolbar: !readOnly
                                }}
                                components={{
                                    Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <TeamEnhancedTableToolbar index={item} />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSlabsCreate(newData, resolve, reject, item);
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSlabsUpdate(newData, oldData, resolve, reject, item)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleSlabsDelete(oldData, resolve, reject, item)
                                            return;
                                        })
                                }}
                            />
                        </div>
                        )}
                    </>
                }
            </Grid>

        </>
    )

}
export default TeamMatchScoringRule;