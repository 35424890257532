import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Dialog, DialogActions, Grid, Tab, Tabs, DialogContent, DialogTitle, TablePagination, Divider, Radio, IconButton, makeStyles, TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { MTableToolbar } from 'material-table';
import classNames from 'classnames';
import { connect } from "react-redux";
import { slabTypes, bonusScoringConditions } from '../KpiScoringRulesDialog';
import { setLoading } from '../../ducks/loading';
import Axios from 'axios';
import { CheckBox, CheckBoxOutlineBlank, FileCopyOutlined } from '@material-ui/icons';
// import { ReactComponent as ShareFolder } from "../../../../assets/icons/share.png";
import SelectRolesDialog from './SelectRolesDialog';
import { Clear, Colorize, ExpandMore } from '@material-ui/icons';
import { ReactComponent as InfoIcon } from "../../assets/icons/infoIcon.svg";
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Container } from 'react-trello';
import { ROLES } from '../../config';
import CustomAutocompleteMultiple from '../../components/CustomAutocompleteMultiple';
import ColorPickerModal from '../../components/ColorPickerModal';



const useStyles = makeStyles(theme => ({
    root: {
        padding: "25px",
        width: "100%",
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        // width: 280
    },
    tabsRoot: {
        // padding: '14px 0px',
        minWidth: 280,
        // maxWidth: 400,
        // margin: 'auto'
        // borderBottom: '1px solid #0000003a',
        paddingBottom: 0,
        // margin: "15px 0px 30px 0px",
        borderBottom: '1px solid #0000003a',
    },
    tabsFixed: {
        maxWidth: 760
    },
    tabRoot: {
        // borderBottom: '1px solid #0000003a',
        padding: '0 20px',
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        // minWidth: 140,
        minWidth: 'auto',
        color: '#838383',
    },
    tabWrapper: {
        flexDirection: 'row',
        // justifyContent: 'flex-start',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 10px 0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px',
        border: 'none',
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        // width: 280
    },
    tableNoteStyles: {
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        }

    },
    customToolbarRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 80px)'
    },
    performanceTitleRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'calc(100% - 80px)'
    },
    healthIndicator: {
        backgroundColor: '#D9D9D9',
        height: '20px',
        width: '20px',
        borderRadius: '17px',
        marginLeft: 5
    },
    customColor: {
        position: 'absolute',
        right: '126px',
        bottom: '-144px',
        zIndex: '15'
    }
}));

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
export const colorOptions = [{ name: "Green", id: "#218D21" }, { name: "Red", id: "#E11919" }, { name: "Amber", id: "#C98E09" }];

const PlayerBonusAndMatchRule = ({ data, tag, handleChangeAutocomplete, handleChangeInput, handelChangeData, details, seasonDetails, getUnit, scoringUnit, handlePlayerToggle, setLoading, editType }) => {
    const myTextIcon = React.useRef(null);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rolesList, setRolesList] = useState([]);
    const [roleTab, setRoleTab] = useState(0);
    const [openRolesDialog, setOpenRolesDialog] = useState(false);
    const [performanceColor, setPerformanceColor] = useState('');
    const [openColorPicker, setOpenColorPicker] = useState(false);


    useEffect(() => {
        // getRoles();
    }, []);

    const getRoles = () => {
        setLoading(true)
        Axios.get(ROLES(), { params: { limit: 5000, page: 0, active: true } })
            .then(res => {
                if (res.data) {
                    setRolesList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    const handleChangeRoles = (roles, filed, tag) => {
        const uniqueData = [...new Map(roles.map(item => [item['_id'], item])).values()];
        const resultData = uniqueData.reduce((result, obj, index) => {
            const ruleIndex = data?.rules?.findIndex(rule => rule.role === obj._id);
            if (ruleIndex >= 0) {
                return [...result, { ...data.rules[ruleIndex] }];
            }
            else {
                return [...result, {
                    role: obj._id,
                    role_name: obj.name,
                    min_eligibility: 0,
                    multiplication_factor: 0,
                    score: 0,
                    base_points: 0,
                    slabs: [],
                    penaltySlabs: [],
                    indicator_slabs: [],
                }];
            }
        }, []);
        // handleChangeAutocomplete([...uniqueData], 'rules', tag);
        handelChangeData(prevState => {
            return { ...prevState, rules: resultData }
        })
    }

    const handleToggleOption = selectedOptions =>
        handleChangeRoles(selectedOptions);
    const handleClearOptions = () => handleChangeRoles([]);
    const handleSelectAll = isSelected => {
        if (isSelected) {
            handleChangeRoles(rolesList);
        } else {
            handleClearOptions();
        }
    };

    const handleRoleTabChange = (event, newValue) => {
        setRoleTab(newValue);
    }

    const handleCreate = (rowData, resolve, reject, slabType = null) => {
        let newData = slabType === 'RAG_SLABS' ? data.rules[roleTab].indicator_slabs : slabType === 1 ? (data.rules[roleTab]?.penaltySlabs ? data.rules[roleTab]?.penaltySlabs : []) : data.rules[roleTab]?.slabs ? data.rules[roleTab].slabs : [];
        if (slabType !== 'RAG_SLABS' ? !(rowData.type && rowData.min && rowData.max && data.condition?.id === 'TBSR4' ? rowData.multiplication_factor : rowData.points) : false) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 'RAG_SLABS' ? !(rowData.type && rowData.min && rowData.max && rowData.status_code) : false) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.min) < Number(data.rules[roleTab]?.minEligibility)) : (Number(rowData.min) >= Number(data.rules[roleTab]?.minEligibility)))) {
            enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.max) < Number(data.rules[roleTab]?.minEligibility)) : (Number(rowData.max) > Number(data.rules[roleTab]?.minEligibility)))) {
            enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (Number(rowData.min) >= Number(rowData.max)) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.multiplication_factor) < 0) : (Number(rowData.multiplication_factor) >= 0))) {
            enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => ((Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)) && (Number(rowData.max) >= Number(ele.min) && Number(rowData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) < Number(ele.min) && (ele.max !== null && Number(rowData.max) > Number(ele.max))))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.max) > Number(ele.min) && Number(rowData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 1 && data.rules[roleTab]?.minEligibility === 0) {
            enqueueSnackbar(`Penalty slabs can be defined only below certain threshold minimum eligibility.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            newData.unshift(rowData);
            const playerData = data.rules[roleTab][slabType === 'RAG_SLABS' ? 'indicator_slabs' : slabType === 1 ? 'penaltySlabs' : 'slabs'] = newData;
            handelChangeData(prevState => {
                return { ...prevState, ...playerData }
            })
            setPerformanceColor('');
            resolve();
        }
    }

    const handleUpdate = (newData, oldData, resolve, reject, condition, slabType) => {
        if (slabType !== 'RAG_SLABS' && (condition === 'TBSR2' || condition === 'TBSR3')) {
            let playerData = JSON.parse(JSON.stringify(data));
            const ruleIndex = playerData?.rules?.findIndex(rule => rule.role === newData?.role);
            if (ruleIndex >= 0) {
                playerData.rules[ruleIndex] = { ...newData, min_eligibility: parseFloat(newData.min_eligibility), multiplication_factor: parseFloat(newData.multiplication_factor), base_points: parseFloat(newData.base_points) };
                handelChangeData({ ...playerData });
                resolve();
            }
        }
        else {
            const compareData = slabType === 'RAG_SLABS' ? [...data.rules[roleTab].indicator_slabs] : slabType === 1 ? [...data.rules[roleTab].penaltySlabs] : [...data.rules[roleTab]?.slabs];
            const index = oldData.tableData.id;
            compareData.splice(index, 1);
            if (slabType !== 'RAG_SLABS' ? !(newData.type && newData.min && (newData.type.id === 'BETWEEN' ? newData.max : true) && (data.condition?.id === 'TBSR4' ? newData.multiplication_factor : newData.points)) : false) {
                enqueueSnackbar('Please enter required fields.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (slabType === 'RAG_SLABS' ? !(newData.type && newData.min && newData.max && newData.status_code) : false) {
                enqueueSnackbar('Please enter required fields.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.min) < Number(data.rules[roleTab]?.minEligibility)) : (Number(newData.min) >= Number(data.rules[roleTab]?.minEligibility)))) {
                enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.max) < Number(data.rules[roleTab]?.minEligibility)) : (Number(newData.max) > Number(data.rules[roleTab]?.minEligibility)))) {
                enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (newData.type.id === 'BETWEEN' ? (Number(newData.min) >= Number(newData.max)) : false) {
                enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (data.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.multiplication_factor) < 0) : (Number(newData.multiplication_factor) >= 0))) {
                enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (compareData.length >= 1 && compareData.some(ele => ((Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)) && (Number(newData.max) >= Number(ele.min) && Number(newData.max) <= Number(ele.max))))) {
                enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) < Number(ele.min) && Number(newData.max) > Number(ele.max)))) {
                enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)))) {
                enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.max) > Number(ele.min) && Number(newData.max) <= Number(ele.max)))) {
                enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                    variant: 'error',
                    preventDuplicate: true
                });
                reject()
            }
            else {
                const index = oldData.tableData.id;
                const playerData = data.rules[roleTab][slabType === 'RAG_SLABS' ? 'indicator_slabs' : slabType === 1 ? 'penaltySlabs' : 'slabs'][index] = newData;
                handelChangeData(prevState => {
                    return { ...prevState, ...playerData }
                })
                setPerformanceColor('');
                resolve();

            }
        }
    }

    const handleDelete = (oldData, resolve, reject, condition, slabType) => {
        if (condition === 'TBSR2' || condition === 'TBSR3') {
            let playerData = JSON.parse(JSON.stringify(data));
            playerData.rules.splice(oldData.tableData.id, 1);
            handelChangeData({ ...playerData });
            resolve();
        }
        else {
            const index = oldData.tableData.id;
            const playerData = data.rules[roleTab][slabType === 'RAG_SLABS' ? 'indicator_slabs' : slabType === 1 ? 'penaltySlabs' : 'slabs'].splice(index, 1);
            handelChangeData(prevState => ({ ...prevState, ...playerData }))
            resolve();
        }
    }


    const handleCheckDefaultRole = (e) => {
        e.stopPropagation();
        handelChangeData(prevState => ({
            ...prevState,
            default_role: !prevState.default_role,
            rules: prevState.default_role ? [] : [{
                min_eligibility: 0,
                multiplication_factor: 0,
                score: 0,
                base_points: 0,
                slabs: [],
                penaltySlabs: [],
                indicator_slabs: [],
            }]
        }));
    }

    const handleChangeMinimumEligibility = (e, roleTab) => {
        const minEligibility = e.target.value;
        let playerData = { ...data };
        playerData.rules[roleTab].minEligibility = minEligibility;
        if (minEligibility) {
            const emptySlabsPositive = [0, 1, 2, 3].map(item => ({ min: null, max: item ? null : 99999, multiplication_factor: null, tableData: { id: item }, type: item ? { name: 'Between', id: 'BETWEEN' } : { name: 'Above', id: 'ABOVE' } }));// teamBonusData.slabs ? teamBonusData.slabs : [];
            if (playerData.rules[roleTab].slabs?.length === 0)
                playerData.rules[roleTab].slabs = emptySlabsPositive;
        }
        else playerData.rules[roleTab].slabs = [];
        if (minEligibility > 0) {
            const emptySlabsPenalty = [0, 1, 2, 3].map(item => ({ min: null, max: null, multiplication_factor: null, tableData: { id: item }, type: { name: 'Between', id: 'BETWEEN' } }));// teamBonusData.slabs ? teamBonusData.slabs : [];
            if (playerData.rules[roleTab].penaltySlabs?.length === 0)
                playerData.rules[roleTab].penaltySlabs = emptySlabsPenalty;
        }
        else playerData.rules[roleTab].penaltySlabs = [];
        handelChangeData(prevState => ({ ...prevState, ...playerData }));
    }

    const slabsColumns = [
        {
            title: "Points",
            field: "points",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    label='Points'
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    style={{ width: 120 }}
                />

            ),
            render: (rowData) => rowData.points
        }
    ]



    const CustomColorPicker = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {/* <SketchPicker
                    color={performanceColor}
                    onChangeComplete={handleColorChange}
                /> */}
                {/* <div onClick={() => setOpenColorPicker({ open: false })}>
                    <Button style={{ backgroundColor: 'black', color: 'white' }} >Ok</Button>

                </div> */}
            </div>
        )
    }









    const slabsAndProrataColumns = [
        {
            title: "Multiplication factor",
            field: "multiplication_factor",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    label='Multiplication factor'
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    // InputProps={{ endAdornment: `${teamMatchData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` }}
                    style={{ minWidth: 140, maxWidth: 200 }}
                />
            ),
            // render: (rowData) => `${rowData.multiplication_factor}`
        }
    ]

    const columns = (isPenaltySlabs) => [
        {
            title: "Type",
            field: "type",
            editComponent: props => (
                <Autocomplete
                    id="type"
                    options={(data.condition?.id === 'TBSR4' && isPenaltySlabs) ? slabTypes.filter(ele => ele.id != 'ABOVE') : (data.rules[roleTab]?.slabs?.length && data.rules[roleTab]?.slabs?.some(ele => ele?.type?.id === 'ABOVE')) ? slabTypes.filter(ele => ele.id != 'ABOVE') : slabTypes}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 125 }} label='Type' variant="outlined" required />}
                    onChange={(...args) => {
                        if (args[1] && Object.keys(args[1]).length > 0 && args[1]?.id === 'ABOVE') {
                            props.rowData.max = 99999;
                        }
                        else props.rowData.max = null;
                        props.onChange(args[1])
                    }}
                />
            ),
            render: (rowData) => rowData.type?.name,
        }, {
            title: `Greater than or equal to`,
            field: "min",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label='Greater than or equal to'
                    required
                    min={20}
                    classNames={{ input: classes.addtionalScoreInputs }}
                    InputProps={{ endAdornment: `${data.basedOn === 'ACTUALS' ? '' : ' %'}` }}
                    autoComplete="off"
                    style={{ minWidth: 220, maxWidth: 240 }}
                />
            ),
            render: (rowData) => (rowData.min !== null) ? (rowData.min + `${data.basedOn === 'ACTUALS' ? '' : ' %'}`) : '--'
        },
        {
            title: `Less than`,
            field: "max",
            editComponent: props => {
                if (props.rowData && props.rowData?.type?.id === 'BETWEEN') {
                    return (
                        <TextField
                            value={props.value}
                            type="number"
                            id="outlined-required"
                            label='Less than'
                            onChange={e => props.onChange(e.target.value)}
                            variant="outlined"
                            fullWidth
                            required
                            autoComplete="off"
                            InputProps={{ endAdornment: `${data.basedOn === 'ACTUALS' ? '' : ' %'}` }}
                            style={{ minWidth: 140, maxWidth: 200 }}
                        />
                    )
                }
                else return null
            },
            render: (rowData) => rowData?.type?.id === 'BETWEEN' ? (rowData.max !== null) ? (rowData?.max + `${data.basedOn === 'ACTUALS' ? '' : ' %'}`) : '--' : '--'
        }];


    const TBSR2Columns = [
        {
            title: "Role",
            field: "role",
            editable: 'never',
            emptyValue: 'All Employees',
            render: rowData => rowData?.role_name || rowData?.role
        },
        {
            title: "Min Eligibility",
            field: "min_eligibility",
            emptyValue: '--',
            editable: 'onUpdate',
            editComponent: props =>
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    label="Min Eligibility"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: 250 }}
                    autoComplete="off"
                    required={(data.condition && data.condition?.id === 'TBSR2')}
                    InputProps={{ endAdornment: data.basedOn === 'ACHIEVEMENT' ? "%" : '' }}
                />
        },
        {
            title: "Multiplication factor *",
            field: "multiplication_factor",
            emptyValue: '--',
            editable: 'onUpdate',
            editComponent: props =>
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    label="Multiplication factor"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: 250 }}
                    autoComplete="off"
                    required={(data.condition && data.condition?.id === 'TBSR2')}
                />
        }
    ]

    const TBSR3Columns = [
        {
            title: "Role",
            field: "role",
            editable: 'never',
            emptyValue: 'All Employees',
            render: rowData => rowData?.role_name || rowData?.role
        },
        {
            title: "Points",
            field: "base_points",
            emptyValue: '--',
            editable: 'onUpdate',
            editComponent: props =>
                <TextField
                    value={data?.points}
                    type="number"
                    id="outlined-required"
                    label="Points"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: 250 }}
                    autoComplete="off"
                    required={(data.condition && data.condition?.id === 'TBSR3')}
                />
        },
    ]

    const BonusEnhancedTableToolbar = props => {
        const classes = useStyles();
        return (
            <div > {/* className={(data.condition && data.condition?.id === 'TBSR2') ? classes.basedOnView : classes.customToolbarRoot} */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12 }}>Measure performance in : </p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, fontSize: 12 }} onClick={() => handlePlayerToggle('ACHIEVEMENT', tag)}>
                        <Radio color="primary" checked={data.basedOn === 'ACHIEVEMENT'} size="small" /> Achievement %</p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, marginLeft: 15, fontSize: 12 }} onClick={() => handlePlayerToggle('ACTUALS', tag)}>
                        <Radio color="primary" checked={data.basedOn === 'ACTUALS'} size="small" />Actuals</p>
                </div>
            </div>
        )
    };

    const EnhancedTableToolbarTBSR1 = (
        <div className={classes.customToolbarRoot} >
            {/* <Button title="Copy Rule to" onClick={() => setOpenRolesDialog(true)} variant="outlined" color="primary" startIcon={<FileCopyOutlined />}>Copy to</Button> */}
        </div>
    )

    const PerformanceTitle = (
        <div className={classes.performanceTitleRoot} >
            <p style={{ fontSize: '16px' }}>Performance Indicator</p>
            <InfoIcon style={{ margin: '5px' }} />
        </div>
    )

    const EnhancedTableToolbarTBSR4 = (props) => (
        <div className={classes.customToolbarRoot} style={{ justifyContent: 'space-between' }}>
            {props.index === 2 ?
                <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: '16px' }}>Performance Indicator</p>
                    <InfoIcon style={{ margin: '5px' }} />
                </div>
                :
                <p>{props.index === 0 ? 'Scoring slabs' : 'Penalty slabs'}</p>
            }
            {/* {(!data.default_role) && <Button title="Copy Rule to" onClick={() => setOpenRolesDialog(true)} variant="outlined" color="primary" startIcon={<FileCopyOutlined />}>Copy to</Button>} */}
        </div>
    )

    const handleDialog = (value, roles) => {
        if (value) {
            const resultData = data.rules?.reduce((result, obj, index) => {
                if (roles.some(role => obj.role === role._id)) {
                    const { _id, name } = roles.find(role => obj.role === role._id);
                    return [...result, {
                        ...data.rules[roleTab],
                        role: _id,
                        role_name: name,
                        slabs: JSON.parse(JSON.stringify(data.rules[roleTab]?.slabs))
                    }]
                }
                return [...result, { ...obj }];
            }, []);
            handelChangeData(prevState => ({ ...prevState, rules: resultData }));
        }
        setOpenRolesDialog(false);
    }

    const handleColorChange = (color) => {
        // setPerformanceColor(color.id);
    };





    return (
        <>
            <Grid item xs={12}>
                {/* <p style={{ padding: 0, fontWeight: 'bold', fontSize: 12 }}>Team bonus scoring rule:</p> */}
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="scoring-condition"
                            options={bonusScoringConditions}
                            getOptionLabel={(option) => option.name || ''}
                            value={data?.condition}
                            // style={{ maxWidth: 250 }}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => <TextField {...params} label="Scoring Condition" variant="outlined" required />}
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'condition', tag)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <p onClick={(e) => handleCheckDefaultRole(e)} style={{ margin: 0, fontSize: 14, cursor: 'pointer' }}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={data.default_role ? true : false}
                                onClick={(e) => handleCheckDefaultRole(e)}
                                color="primary"
                            />
                            All Employees
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            (data.condition && data.condition?.id === 'TBSR1' && data.rules?.length > 0) &&
                            <Container maxWidth={"xl"}>

                                <BonusEnhancedTableToolbar />
                                <Tabs
                                    id="role-tabs"
                                    value={roleTab}
                                    onChange={handleRoleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    aria-label="scrollable auto tabs example"
                                    className={classes.tabsRoot}
                                    classes={{ indicator: classes.tabIndicator, fixed: classes.tabsFixed }}
                                >
                                    {
                                        [...data.rules].map((obj, i) =>
                                            <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper }} key={i} label={(obj.role_name || obj.role) || 'All Employees'} />
                                        )
                                    }
                                </Tabs>
                                <div style={{ marginTop: 10 }} className={classNames("material-table", classes.tableNoteStyles)}>
                                    <MaterialTable
                                        title='TBSR1'
                                        icons={{
                                            Add: props => (
                                                <div ref={myTextIcon}>
                                                    <Button
                                                        {...props}
                                                        color="primary"
                                                        variant="contained"
                                                        disableElevation
                                                        disableRipple
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            )
                                        }}
                                        columns={[...columns().filter((ele, i) => i <= 2), ...slabsColumns]}
                                        data={data?.rules[roleTab]?.slabs}
                                        options={{
                                            search: false,
                                            actionsColumnIndex: -1,
                                            showTitle: false,
                                            addRowPosition: 'first',
                                            emptyRowsWhenPaging: false,
                                            // toolbar: !readOnly
                                        }}
                                        components={{
                                            Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                            Toolbar: props => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    {(!data.default_role) && EnhancedTableToolbarTBSR1}

                                                </div>)
                                        }}
                                        editable={{
                                            onRowAdd: newData =>
                                                new Promise((resolve, reject) => {
                                                    if (Object.keys(newData).length > 0) {
                                                        handleCreate(newData, resolve, reject)
                                                        return;
                                                    } else {
                                                        reject();
                                                        return;
                                                    }
                                                }),
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    if (Object.keys(newData).length > 0) {
                                                        handleUpdate(newData, oldData, resolve, reject, 'TBSR1')
                                                        return;
                                                    } else {
                                                        reject();
                                                        return;
                                                    }
                                                }),
                                            onRowDelete: oldData =>
                                                new Promise((resolve, reject) => {
                                                    handleDelete(oldData, resolve, reject, 'TBSR1')
                                                    return;
                                                })
                                        }}
                                    />
                                </div>
                            </Container>
                        }
                    </Grid>
                </Grid>

                {
                    (data.condition && data.condition?.id === 'TBSR2' && data.rules?.length > 0) &&
                    <>
                        <BonusEnhancedTableToolbar />
                        <div className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title="TBSR2"
                                columns={TBSR2Columns}
                                data={data?.rules}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    // showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 25, 50],
                                    pageSize: 5,
                                    toolbar: false,
                                }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            let resultData = JSON.parse(JSON.stringify(data));
                                            resultData.rules[rowData.tableData?.id][columnDef.field] = parseFloat(newValue);
                                            handelChangeData(prevState => ({ ...prevState, rules: resultData.rules }));
                                            resolve();
                                        });
                                    }
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleUpdate(newData, oldData, resolve, reject, 'TBSR2')
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(oldData, resolve, reject, 'TBSR2')
                                            return;
                                        })
                                }}
                            />
                        </div>
                    </>
                }
                {
                    (data.condition && data.condition?.id === 'TBSR3' && data.rules?.length > 0) &&
                    <div className={classNames("material-table", classes.tableNoteStyles)}>
                        <MaterialTable
                            title="TBSR3"
                            columns={TBSR3Columns}
                            data={data?.rules}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                // showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 25, 50],
                                pageSize: 5,
                                toolbar: false,
                            }}
                            cellEditable={{
                                // cellStyle: { minWidth: 150 },
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                    return new Promise((resolve, reject) => {
                                        let resultData = JSON.parse(JSON.stringify(data));
                                        resultData.rules[rowData.tableData?.id][columnDef.field] = parseFloat(newValue);
                                        handelChangeData(prevState => ({ ...prevState, rules: resultData.rules }));
                                        resolve();
                                    });
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        if (Object.keys(newData).length > 0) {
                                            handleUpdate(newData, oldData, resolve, reject, 'TBSR3')
                                        } else {
                                            reject();
                                            return;
                                        }
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        handleDelete(oldData, resolve, reject, 'TBSR3')
                                        return;
                                    })
                            }}
                        />
                    </div>
                }
                {
                    (data.condition && data.condition?.id === 'TBSR4' && data.rules?.length > 0) &&
                    <>
                        <BonusEnhancedTableToolbar />
                        <Tabs
                            id="role-tabs"
                            value={roleTab}
                            onChange={handleRoleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="on"
                            aria-label="scrollable auto tabs example"
                            className={classes.tabsRoot}
                            classes={{ indicator: classes.tabIndicator, fixed: classes.tabsFixed }}
                        >
                            {
                                [...data.rules].map((obj, i) =>
                                    <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper }} key={i} label={(obj.role_name || obj.role) || 'All Employees'} />
                                )
                            }
                        </Tabs>
                        <TextField
                            value={data?.rules[roleTab]?.minEligibility}
                            type="number"
                            id="outlined-required"
                            label="Min Eligibility"
                            onChange={e => handleChangeMinimumEligibility(e, roleTab)}
                            variant="outlined"
                            fullWidth
                            style={{ maxWidth: 250, margin: '20px 0 0' }}
                            autoComplete="off"
                            required={(data.condition && data.condition?.id === 'TBSR2')}
                            InputProps={{ endAdornment: data.basedOn === 'ACHIEVEMENT' ? "%" : '' }}
                        />
                        {[0, 1].map(item => <div key={item} style={{ marginTop: 10 }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title='TBSR1'
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={[...columns(item === 1).filter((ele, i) => i <= 2), ...slabsAndProrataColumns]}
                                data={data?.rules[roleTab][item === 0 ? 'slabs' : 'penaltySlabs']}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    // toolbar: !readOnly
                                }}
                                components={{
                                    Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <EnhancedTableToolbarTBSR4 index={item} />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleCreate(newData, resolve, reject, item)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleUpdate(newData, oldData, resolve, reject, 'TBSR1', item)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(oldData, resolve, reject, 'TBSR1', item)
                                            return;
                                        })
                                }}
                            />
                        </div>
                        )}

                    </>
                }

                {openColorPicker &&
                    <ColorPickerModal open={openColorPicker} handleColorChange={handleColorChange} handleDialog={() => setOpenColorPicker(false)} />
                }







            </Grid>
            {/* {
                (data.condition?.id && data?.basedOn) &&
                <Grid item xs={4} md={4}>

                    <ScoringRulesContents condition={data.condition?.id} basedOn={data?.basedOn} tag={tag} isPlayer={true} />
                </Grid>

            } */}

            {openRolesDialog && <SelectRolesDialog open={openRolesDialog} rolesList={rolesList.filter(obj => data.rules?.some(rule => rule.role === obj._id && rule.role !== data.rules[roleTab]?.role))} handleDialog={handleDialog} />}
        </>
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value))
    };
};
export default connect(null, mapDispatchToProps)(PlayerBonusAndMatchRule);