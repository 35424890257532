import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, makeStyles, Switch, } from '@material-ui/core';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading } from '../ducks/loading';
import Axios from 'axios';
import { GET_KPI_LIST, UPDATE_KPI, UPDATE_KPI_STATUS } from "../config";
import CurrencyCodes from './CurrencyCodes.json'
import { Check, SettingsOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDialog from '../components/CustomDialog';
import KpiScoringRulesDialog from './KpiScoringRulesDialog';
import { ReactComponent as ArrowForward } from "../assets/icons/arrow-right-1.svg";
import { setSearchQuery } from '../helpers';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        },
        '& .MuiAccordion-root:before': {
            height: '0px !important'
        },
        '.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px !important'
        }
    },
    footerButton: {
        margin: 18,
        // width: 135,
        // height: 47,
        '&:last-child': {
            marginRight: 0
        }
    },
    backButton: {
        margin: 18,
        width: 135,
        height: 47,
        marginLeft: 0
    },
    footerButtonGroup: {
        margin: '0px 0px 18px 18px',
        float: 'right',
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        width: 280,
        height: 40
    },
    multilineInput: {
        fontSize: 14,
        padding: '15px 14px 12px'
    },
    chipRoot: {
        borderRadius: '16px !important'
    }, customToolbarRoot: {
        position: 'absolute',
        top: 5,
        // right: 115,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 100px)'
    }, customToolbarRootWithMessage: {
        position: 'absolute',
        top: 1,
        right: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'calc(100% - 342px)'
    },
    divisionsLink: {
        fontSize: 12,
        cursor: 'pointer',
        // marginRight: 15,
        whiteSpace: 'nowrap',
        textDecoration: 'underline'
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        '& input': {
            padding: '5.5px 4px !important'
        }
        // width: 280
    },
    button: {
        textTransform: 'capitalize'
    },
    accordianMain: {
        background: '#F0F1F3',
        marginBottom: 8,
        border: '1px solid #d6d6d6',
        borderRadius: '4px !important',
        boxShadow: 'none',
        '& .MuiAccordion-root:before': {
            height: '0px !important'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 48
        }
    },
    accordianDetails: {
        padding: 0
    },
    viewAllButton: {
        color: '#727272',
        fontSize: 13,
        float: 'right',
        cursor: 'pointer',
        border: '1px solid #D3D3D3',
        borderRadius: 4,
        padding: '6px 16px',
        textTransform: 'Capitalize',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    }
}));

export const MeasurementTypes = [{ name: "Currency", id: "CURRENCY" }, { name: "Number", id: "NUMBER" }, { name: "Percentage", id: "PERCENTAGE" }, { name: 'Duration', id: 'DURATION' }];
export const kpiTagTypes = [{ name: 'Team Core', id: 'TEAM_MATCH' }, { name: 'Team Bonus', id: 'TEAM_BONUS' }, { name: 'Player Core', id: 'PLAYER_MATCH' }, { name: 'Player Bonus', id: 'PLAYER_BONUS' }];
export const durationUnits = [{ name: 'Minutes', id: 'MINUTES' }, { name: 'Hours', id: 'HOURS' }, { name: 'Days', id: 'DAYS' }];
export const ScoringUnitTypes = [{ name: 'Run', id: 'RUN' }, { name: 'Hit', id: 'HIT' }, { name: 'Goal', id: 'GOAL' },]


const CustomKpiTable = ({ kpiDetails, setLoading, history, selectedFunction, functions, industries, id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const myTextIcon = useRef(null);
    const [data, setData] = useState([]);
    const [kpisLoading, setKpisLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedKpiData, setSelectedKpiData] = useState(null);
    const [openKpiScoringRulesDialog, setOpenKpiScoringRulesDialog] = useState(false);
    const [editType, setEditType] = useState(null);

    useEffect(() => {
        myTextIcon.current && myTextIcon.current.parentNode.parentNode.classList.remove('MuiIconButton-root');
        if (selectedFunction && (selectedFunction?._id === id)) {
            getKpisList();
        }
    }, [selectedFunction?._id]);


    // ---kpi's---
    const getKpisList = () => {
        setLoading(true)
        setKpisLoading(true)
        Axios.get(GET_KPI_LIST(), { params: { industry_id: kpiDetails?.industry?._id, function_id: kpiDetails?.function?._id, limit: 1000, page: 0 } })
            .then(res => {
                if (res.data) {
                    res.data.forEach(ele => {
                        ele.tags = ele.tags.length > 0 ? kpiTagTypes.filter(t => ele.tags.includes(t.id)) : ele.tags;
                        ele.unit_of_measurement = MeasurementTypes.find(u => u.id === ele.unit_of_measurement);
                        ele.unit = ele.unit_of_measurement?.id === "CURRENCY" ? CurrencyCodes.find(c => c.id === ele.unit) : ele.unit_of_measurement?.id === "DURATION" ? durationUnits.find(c => c.id === ele.unit) : ''
                    })
                    setData(res.data);
                }
                setLoading(false)
                setKpisLoading(false)
            })
            .catch(err => {

            });
    }

    const handleDeleteKpi = (data) => {
        setOpenDeleteDialog(true);
        setSelectedKpiData({ ...data })
    }
    const handleConfirmationDeleteDialog = (value) => {
        if (value) {
            setLoading(true);
            Axios.delete(UPDATE_KPI(selectedKpiData._id))
                .then(response => {
                    setLoading(false);
                    setSelectedKpiData(null)
                    setOpenDeleteDialog(false);
                    getKpisList()
                })
                .catch(error => {
                });
        }
        else {
            setOpenDeleteDialog(false)
            setSelectedKpiData({})
        }
    }



    const handleCloseKpiDialog = (value) => {
        setSelectedKpiData(null)
        if (value) getKpisList()
        setOpenKpiScoringRulesDialog(false);
        if (editType) setEditType(null)
    }

    const handleEditKpi = (data, type) => {
        setSelectedKpiData(data)
        setOpenKpiScoringRulesDialog(true);
        setEditType(type)
    }

    const handleOpenKpiDialog = () => {
        setOpenKpiScoringRulesDialog(true);
        setSelectedKpiData(null)
        setTimeout(() => {
            if (!editType) myTextIcon.current.click()
        })
    }


    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            let reqObj = {
                active: value,
            }
            setLoading(true);
            Axios.put(UPDATE_KPI_STATUS(rowData._id), reqObj)
                .then(() => {
                    getKpisList();
                    enqueueSnackbar(value ? 'KPI activated successfully.' : 'KPI deactivated successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    });
                    setLoading(false);
                }).catch(error => {
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : (value ? 'Failed to activate role.' : 'Failed to deactivated role.'), {
                        variant: 'error',
                        preventDuplicate: true
                    });
                    setLoading(false);
                });
        }
    }

    const handleDetailedScore = () => {
        setSearchQuery(history, { industry_id: kpiDetails.industry._id, function_id: kpiDetails.function._id });
        history.push(`/platform-admin/viewallmasterkpis${history.location?.search}`);
    }

    const columns = [
        {
            title: "KPI Name",
            field: "name",
            editable: false,
            cellStyle: { minWidth: 150 },
            render: rowData => rowData?.name
        },
        {
            title: "Industry",
            field: "industries",
            editable: false,
            render: rowData => rowData?.industries
        },
        {
            title: "Function",
            field: "function",
            editable: false,
            render: rowData => rowData?.function
        },
        // {
        //     title: "KPI Type",
        //     field: "tags",
        //     editable: false,
        //     render: rowData => rowData?.tags?.length > 0 ? rowData.tags?.map(ele => { return `${ele.name.split(' ')}` }).join(', ') : "--"
        // },
        {
            title: "Measurement",
            field: "unit_of_measurement",
            editable: false,
            render: (rowData) => rowData.unit_of_measurement?.name || ''
        },
        // {
        //     title: "Unit",
        //     field: "unit",
        //     emptyValue: 'NA',
        //     editable: false,
        //     render: (rowData) => rowData.unit_of_measurement?.id === 'DURATION' ? rowData.unit?.name : rowData.unit ? rowData.unit?.id : 'NA'
        // },
        {
            title: `Team Core rule`,
            field: "matchScoringRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('TEAM_MATCH') ?
                <Check style={rowData.team_match_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Team Bonus rule`,
            field: "bonusScoringRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('TEAM_BONUS') ?
                <Check style={rowData.team_bonus_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Player Core rule`,
            field: "playerMatchRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('PLAYER_MATCH') ?
                <Check style={rowData.player_match_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: `Player Bonus rule`,
            field: "playerBonusRule",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.tags.map(el => el.id).includes('PLAYER_BONUS') ?
                <Check style={rowData.player_bonus_rule ? { color: 'green' } : { color: 'grey' }} /> : '--'
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            align: 'right',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]



    return (
        <div className={classes.root}>
            <div className="material-table" style={{ marginTop: 0 }}>
                <MaterialTable
                    title="List Of KPI"
                    icons={{
                        Add: props => (
                            <div ref={myTextIcon}>
                                <Button
                                    {...props}
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    disableRipple
                                    className={classes.button}
                                    onClick={() => handleOpenKpiDialog()}
                                >
                                    + Add Kpi
                                </Button>
                            </div>
                        )
                    }}
                    isLoading={kpisLoading}
                    columns={columns}
                    data={data?.slice(0, 5)}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        showTitle: false,
                        paging: false,
                        addRowPosition: 'first',
                        emptyRowsWhenPaging: false,
                        actionsCellStyle: { minWidth: "130px" }
                    }}
                    localization={{ header: { actions: "Manage rules" } }}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                            </div>
                        )
                    }}
                    actions={[
                        {
                            icon: () => <SettingsOutlined />,
                            tooltip: 'Manage Rule',
                            onClick: (event, rowData) => handleEditKpi(rowData, 'ALL'),
                        },
                        {
                            icon: (rowData) => <DeleteIcon />,
                            tooltip: 'Delete',
                            onClick: (event, rowData) => handleDeleteKpi(rowData),
                        }
                    ]}
                    editable={{
                        onRowAdd: newData => { }
                    }}
                />
                <p className="table-footer">
                    {data?.length > 5 && <span className={classes.viewAllButton} onClick={() => handleDetailedScore()}>View all KPI's <ArrowForward /></span>}
                </p>
            </div>
            <CustomDialog
                open={openDeleteDialog}
                content={<span>Deleting a KPI will also remove its scoring rules and related entries in the team/bonus matches.<br /><br /> Are you sure you want to proceed further ?</span>}
                handleConfirmationDialog={handleConfirmationDeleteDialog}
                type={"CONFIRMATION"}
                title={"Confirmation: Delete KPI"}
                maxWidth={'sm'}
                secondaryButton={{ text: 'Cancel' }}
                primaryButton={{ text: 'YES', otherProps: { style: { backgroundColor: 'red' } } }}
            />
            {
                openKpiScoringRulesDialog &&
                <KpiScoringRulesDialog
                    open={openKpiScoringRulesDialog}
                    kpiData={selectedKpiData && JSON.parse(JSON.stringify(selectedKpiData))}
                    handleClose={handleCloseKpiDialog}
                    setLoading={setLoading}
                    editType={editType}
                    functions={functions}
                    industries={industries}
                />
            }
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(CustomKpiTable));
