import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItem, ListItemAvatar } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as ProfileOpen } from '../assets/icons/profile-collapsible.svg'
import { ReactComponent as ProfileClose } from '../assets/icons/profile-expandable.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel as Accordion, ExpansionPanelSummary as AccordionSummary, ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';

const templateStyles = makeStyles((theme) => ({
    root: {
        // margin: '50px 0 54px',
        // borderRight: '1px solid #e2dedeeb',
        // padding: '10px 0px',
        backgroundColor: '#FFF',
        // borderRadius: 8,
        // minHeight: 430,
        // maxHeight: 430,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .title': {
            margin: 0,
            padding: '14px 18px',
            color: '#838383',
            fontSize: 13,
            fontWeight: 600,
            borderBottom: '1px solid #f0f2f5'
        }
    },
    listItemRoot: {
        // flexDirection: 'column',
        // padding: '15px 8px 4px',
        // textAlign: 'center',
        width: 200,
        // width: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper, MuiPaper-root
        // borderBottom: '1px solid #f0f2f5'
    },
    submenu_opts: {
        '& .MuiExpansionPanel-root': {
            width: 200,
            borderBottom: '1px solid #f0f2f5',
            boxShadow: 'none',
            borderRadius: 'none'
        },
        '& .MuiExpansionPanelDetails-root': {
            diaplay: 'inline-block !important',
            width: 200,
            // borderBottom: '1px solid #f0f2f5',
            padding: '0px'
        },
        '& .MuiExpansionPanelSummary-root': {
            // padding: '0px 5px 0px 16px'
        },
        '& .MuiExpansionPanelSummary-content.Mui-expanded': {
            margin: 0
        },
        '& .MuiExpansionPanelSummary-root.Mui-expanded': {
            minHeight: 0
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        },
        '& .MuiExpansionPanelSummary-expandIcon': {
            padding: 0,
            position: 'absolute',
            right: 15
        }
    },
    listItemRoot_1: {
        diaplay: 'inline',
        width: 200,
        borderBottom: '1px solid #f0f2f5',
        paddingTop: 5,
        paddingBottom: 5
    },
    selectedStyles_1: {
        backgroundColor: 'transparent !important',
        borderRight: `5px solid ${theme.palette.primary.main}`,
        fontWeight: 600,
        '& svg': {
            opacity: '1 !important'
        }
    },
    listItemContent_1: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        '& div': {
            width: 'auto'
        }
    },
    avatarRoot: {
        minWidth: 0,
        width: 29,
        '& svg': {
            verticalAlign: 'sub',
            height: 25,
            opacity: .5
            // '& path': {
            //     fill: '#4d4d4f80'
            // }
        }
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        // margin: '8px 0px',
        margin: '8px 0px 5px 5px',
        whiteSpace: 'nowrap',
        '& div': {
            width: '100%'
        }
    },
    primaryText: {
        // fontWeight: 600,
        margin: 0,
        // paddingBottom: 4,
        fontSize: 13,
        color: '#535353',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383'
    },
    svgIconStyles: {
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
    selectedStyles: {
        backgroundColor: 'transparent !important',
        borderRight: `5px solid ${theme.palette.primary.main}`,
        fontWeight: 600,
        '& svg': {
            opacity: 1
        }
    },
    toggleButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '7px 0',
        zIndex: 10,
        transition: '0.2s ease-in-out',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        // [theme.breakpoints.down('xs')]: {
        //     top: -12,
        // }
    },
    svgIcon: {
        overflow: 'inherit',
        fontSize: '1.5rem',
        '& rect': {
            fill: theme.palette.primary.main,
        }
    },
    drawerPaperClose: {
        maxWidth: 48,
        overflow: 'hidden',
        '& .title': {
            whiteSpace: 'nowrap',
            visibility: 'hidden'
        },
        '& button': {
            right: 5
        }
    },
    copyRight: {
        textAlign: 'center',
        fontSize: 8,
        color: '#26262680',
        padding: '12px 20px'
    },
    listStyles: {
        '& .MuiListItem-root:first-child': {
            borderTop: '1px solid #f0f2f5',
        },
        '& .MuiListItem-root:last-child': {
            borderBottom: 'none',
        },

    }
}));

const SideNavbarButtonTemplate = ({ icon, label, submenu, submenubar, pathname, basepath = '', value, history, location, classes }) => {
    const [expanded, setExpanded] = useState(submenubar);
    const [selectedTab, setSelectedTab] = useState();
    const handleChange = (val) => (event, isExpanded) => {
        setSelectedTab(val);
        setExpanded(isExpanded ? val : false);
    };
    const handleClick = () => {
        history.push(`${basepath}/${pathname}`);
        setExpanded(false);
        setSelectedTab(null)
    }
    const handleSubClick = (value) => {
        history.push(`${basepath}/${pathname}/${value}`);
    }


    return (
        <div className={classes.submenu_opts}>
            <Accordion expanded={expanded ? expanded : false} onChange={handleChange(pathname)}>
                <AccordionSummary expandIcon={(submenu?.length > 0) ? <ExpandMoreIcon /> : null} aria-controls="panel1a-content" id="panel1a-header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItem className={classes.listItemRoot} classes={{ selected: classes.selectedStyles }} button selected={pathname ? (location?.pathname.includes('buzz_settings') === true ? false : location.pathname.includes(pathname)) : true} onClick={() => handleClick(value)}>
                            <ListItemAvatar title={label} className={classes.avatarRoot}>
                                {icon}
                            </ListItemAvatar>
                            <div className={classes.listItemContent}>
                                <div>
                                    <p className={classes.primaryText}>{label}</p>
                                </div>
                            </div>
                        </ListItem>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.listStyles} style={{ display: 'inline-block' }}>
                        {submenu?.map(option =>
                            <ListItem className={classes.listItemRoot_1} classes={{ selected: classes.selectedStyles_1 }} button selected={pathname ? location?.pathname?.split("/").pop() === option?.pathname : true} onClick={() => handleSubClick(option?.pathname)}>
                                <div className={classes.listItemContent_1}>
                                    <div style={{ paddingLeft: 0, display: 'flex', alignItems: 'center' }}>
                                        <ListItemAvatar title={label} className={classes.avatarRoot}> {option?.icon} </ListItemAvatar>
                                        <p style={{ fontSize: '12.3px', paddingLeft: 12 }} className={classes.primaryText}>{option?.label}</p>
                                    </div>
                                </div>
                            </ListItem>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const SubMenuBar = ({ menuOptions, basepath = '', ...others }) => {
    const classes = templateStyles();
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    return (
        <div className={classNames(classes.root, { [classes.drawerPaperClose]: !open })}>
            <div>
                <p className="title">Sub Menu</p>
                <IconButton title={open ? 'Collapse' : 'Expand'} disableRipple disableFocusRipple className={classNames(classes.toggleButton)} onClick={() => handleDrawerOpen()} >
                    {open ? <ProfileClose className={classes.svgIcon} /> : <ProfileOpen className={classes.svgIcon} />}
                </IconButton>
                <div>
                    {menuOptions.map(option =>
                        <SideNavbarButtonTemplate {...option} basepath={basepath} submenubar={option.submenubar}  {...others} classes={classes} />
                    )}
                </div>
            </div>
            {open && <div className={classes.copyRight}>
                <p>Powered by Invente</p>
                <p>© Leagues.ai {new Date().getFullYear()}</p>
            </div>}
        </div>
    )
}

export default SubMenuBar;