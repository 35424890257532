import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { GET_OBJECT_DETAILS, GET_OBJECT_ELEMENTS, UNMAPPED_CUSTOM_ELEMENTS, UPDATE_OBJECT_ELEMENTS } from '../config';
import { Button, Switch, TablePagination } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import CustomDialog from '../components/CustomDialog';
import { useParams } from 'react-router-dom';
import { Check, Delete, Edit } from '@material-ui/icons';
import CustomElementDialog from './CustomElementDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    }
}))

export const dataTypes = [
    { name: 'String', id: 'STRING' },
    { name: 'Number', id: 'NUMBER' },
    { name: 'Boolean', id: 'BOOLEAN' },
    { name: 'Date', id: 'DATE' },
    { name: 'Time', id: 'TIME' },
    { name: 'TimeStamp', id: 'TIMESTAMP' },
    { name: 'Float', id: 'FLOAT' },
    { name: 'Object Id', id: 'OBJECTID' }
];

let total_count = 0;
let total_pages = 0;

const ObjectElements = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const params = useParams()
    const [object, setObject] = useState();
    const [keysList, setKeysList] = useState();
    const [selectedElement, setselectedElement] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [elementConfirmDialog, setElementConfirmDialog] = useState(false);
    const [elementOpenDialog, setElementOpenDialog] = useState(false);
    const [elementDeleteConfirmDialog, setElementDeleteConfirmDialog] = useState(false);
    const [customElements, setCustomElements] = useState([]);

    useEffect(() => {
        if (params?.objId) getObjectDetails(params?.objId);
        getElementsList(params?.objId);
    }, []);

    useEffect(() => {
        if (params?.objId) getObjectKeys(params?.objId)
    }, [currentPage, pageSize]);

    const getElementsList = (objId) => {
        setLoading(true)
        Axios.get(UNMAPPED_CUSTOM_ELEMENTS(objId), { params: { page: 0, limit: 500 } })
            .then(res => {
                if (res.data) {
                    setCustomElements(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ---------object Details-----
    const getObjectDetails = (objId) => {
        Axios.get(GET_OBJECT_DETAILS(objId))
            .then(res => {
                if (res.data) {
                    setObject(res.data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Elements', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ---------object keys-----
    const getObjectKeys = (objId) => {
        Axios.get(GET_OBJECT_ELEMENTS(objId), { params: { page: currentPage, limit: pageSize } })
            .then(res => {
                if (res.data) {
                    total_count = res.headers.total_count;
                    total_pages = res.headers.total_pages;
                    setKeysList(res.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Elements', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }
    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // -----------update Element status--------
    const updateElementStatus = (requestObj) => {
        setLoading(true)
        let finalReqObj = { active: requestObj.active }
        finalReqObj['active'] = requestObj?.activeStatus;
        Axios.put(UPDATE_OBJECT_ELEMENTS(params?.objId, requestObj.element_id), finalReqObj)
            .then(() => {
                getObjectKeys(params?.objId);
                setElementConfirmDialog(false);
                setselectedElement(null);
                setLoading(false);
                enqueueSnackbar('Element Update successfully.', {
                    variant: 'success',
                    preventDuplicate: true
                })
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Element', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });

    }
    // -----------delete Element--------
    const deleteElement = (requestObj) => {
        Axios.delete(UPDATE_OBJECT_ELEMENTS(params?.objId, requestObj?.element_id))
            .then(() => {
                getObjectKeys(params?.objId);
                setselectedElement(null);
                setElementDeleteConfirmDialog(false);
                getElementsList(params?.objId);
                setLoading(false);
                enqueueSnackbar('Element Deleted successfully.', {
                    variant: 'success',
                    preventDuplicate: true
                })
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Delete Element', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // ----toggle changes-------
    const handleToggleChange = (e, value, rowData) => {
        setselectedElement({ ...rowData, activeStatus: value });
        setElementConfirmDialog(true);
    }
    const handleConfirmationDialog = (value) => {
        if (value) {
            updateElementStatus(selectedElement);
        }
        else setElementConfirmDialog(false);
    };

    // ---delete changes----
    const handleDeleteElement = (rowData) => {
        setselectedElement({ ...rowData });
        setElementDeleteConfirmDialog(true);
    }
    const handleDeleteConfirmationDialog = (value) => {
        if (value) {
            deleteElement(selectedElement);
        }
        else setElementDeleteConfirmDialog(false);
    };

    // ----Element dialogs actions -----
    const handleCloseTemplateDialog = (value) => {
        if (value) getObjectKeys(params?.objId);
        setElementOpenDialog(false);
        setselectedElement(null);
        getElementsList(params?.objId);
    };
    const handleOpenDialog = () => {
        setElementOpenDialog(true);
        setselectedElement(null);
    }
    const updateElement = (data, type) => {
        setselectedElement(data)
        setElementOpenDialog(true);
    }

    const column = [
        {
            title: "Name",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Display Name",
            field: "display_name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Key",
            field: "key",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Data-Type",
            field: "data_type",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "UOM",
            field: "unit_of_measurement",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Default Value",
            field: "default_value",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: 'Required',
            field: "required",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.required ? <Check style={{ color: 'green' }} /> : '--'
        },
        {
            title: 'Index By',
            field: "index",
            editable: false,
            align: 'center',
            render: (rowData) => rowData?.index ? <Check style={{ color: 'green' }} /> : '--'
        },
        {
            title: "Active",
            field: "active",
            editable: false,
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    const breadcrumbsList = [
        { label: 'Platform Admin', to: '/platform-admin' },
        { label: 'Custom Objects', to: '/platform-admin/customobjects' },
        { label: object?.name }
    ]

    const EnhancedTableToolbar = (toolBarProps) => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Element
                    </Button>
                </div>
            </div >
        )
    };
    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"elements"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={keysList?.length}
                        title="List Of elements"
                        columns={column}
                        data={keysList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                            selection: false,
                            showTextRowsSelected: false,
                        }}
                        actions={[
                            // {
                            //     icon: () => <Edit />,
                            //     tooltip: 'Edit',
                            //     onClick: (event, rowData) => updateElement(rowData),
                            // },
                            {
                                icon: (rowData) => <Delete />,
                                tooltip: 'Delete',
                                onClick: (event, rowData) => handleDeleteElement(rowData),
                            }
                        ]}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count} //total count
                                        page={currentPage} //page number 0,1,2,3
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            {elementConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={elementConfirmDialog}
                    content={<span>Are you sure want to {selectedElement?.activeStatus ? 'Active' : 'In Active'} This Element?</span>}
                    handleConfirmationDialog={handleConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
            {elementDeleteConfirmDialog && (
                <CustomDialog
                    primaryButton={{ text: "YES" }}
                    secondaryButton={{ text: "NO" }}
                    type="CONFIRMATION"
                    open={elementDeleteConfirmDialog}
                    content={<span>Are you sure want to Delete This Element?</span>}
                    handleConfirmationDialog={handleDeleteConfirmationDialog}
                    maxWidth={"sm"}
                />
            )}
            {elementOpenDialog && (
                <CustomElementDialog
                    open={elementOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedElement={selectedElement}
                    objectId={object}
                    keysList={keysList}
                    customElements={customElements}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectElements));

