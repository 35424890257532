import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import classNames from "classnames";
import { ADD_MASTER_EVENTS, UPDATE_MASTER_EVENT, UPDATE_MASTER_EVENTS, UPDATE_MASTER_EVENTS_STATUS } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";



const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: ' 12px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014'
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    }
}));



const EventTemplateDialog = ({ open = false, handleClose, selectedTemplate, setLoading, setSelectedTemplate, editType = null, getEvents }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedEvents, setSelectedEvents] = useState(selectedTemplate?.events);


    const createEvent = (data) => {
        let reqObj = {
            title: data?.title,
            category_id: selectedTemplate?._id
        }
        // setLoading(true)
        if (reqObj?.title.trim() === "") {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            Axios.post(ADD_MASTER_EVENTS(), reqObj)
                .then(() => {
                    getEvents();
                    handleClose(false);
                    setLoading(false);
                    enqueueSnackbar('Event added successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Event', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }
    }

    const updateEvent = (requestObj, resolve = () => { }, reject = () => { }) => {
        setLoading(true)
        if (requestObj?.title.trim() === "") {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            Axios.put(UPDATE_MASTER_EVENT(requestObj?.id), requestObj)
                .then(() => {
                    setLoading(false);
                    getEvents();
                    handleClose(false);
                    enqueueSnackbar('Event Updated successfully.', {
                        variant: 'success',
                        preventDuplicate: true
                    })
                    resolve();
                })
                .catch(error => {
                    setLoading(false);
                    reject();
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Event', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });
        }

    }


    const handleToggleChange = (e, value, rowData, index) => {
        let copyEventAttributes = JSON.parse(JSON.stringify(selectedEvents));
        copyEventAttributes[index].active = value
        setSelectedEvents(copyEventAttributes);
        updateEventStatus(value, rowData);
    }

    const updateEventStatus = (value, rowData) => {
        if (rowData) {
            let reqObj = {
                active: value,
            }
            Axios.put(UPDATE_MASTER_EVENTS_STATUS(rowData?._id), reqObj)
                .then(() => {
                    // enqueueSnackbar(value ? 'Event activated successfully.' : 'Event deactivated successfully.', {
                    //     variant: 'success',
                    //     preventDuplicate: true
                    // });
                    // setLoading(false);
                }).catch(error => {
                    // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : (value ? 'Failed to activate Event.' : 'Failed to deactivated Event.'), {
                    //     variant: 'error',
                    //     preventDuplicate: true
                    // });
                    // setLoading(false);
                });
        }
    }


    const column = [
        {
            title: "Events",
            field: "title",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
            editComponent: props => (
                <TextField
                    value={props.value}
                    id="event-required"
                    label="Event"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    size='small'
                    autoComplete="off"
                />
            )

        },
        {
            title: "Active",
            field: "active",
            editable: false,
            cellStyle: { minWidth: 120 },
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData, rowData?.tableData?.id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                Choose Events For {selectedTemplate?.category_name}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <div className={classNames("material-table", classes.tableRoot)}>
                        <MaterialTable
                            key={selectedEvents.length}
                            title="List Of Category"
                            icons={{
                                Add: props => (
                                    <div>
                                        <Button
                                            {...props}
                                            color="primary"
                                            variant="contained"
                                            disableElevation
                                            disableRipple
                                            className={classes.button}
                                        >
                                            +Add Event
                                        </Button>
                                    </div>
                                )
                            }}
                            columns={column}
                            data={selectedEvents}
                            options={{
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 250 },
                                searchFieldAlignment: "left",
                                search: true,
                                paging: false
                            }}
                            actions={[]}
                            editable={{
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        createEvent(newData, resolve, reject);
                                    }),
                                onRowUpdate: newData =>
                                    new Promise((resolve, reject) => {
                                        updateEvent({ title: newData.title, id: newData._id, active: newData.active }, resolve, reject);
                                    }),
                            }} />
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(EventTemplateDialog);
