import React from 'react';

import './CommonLayout.css'

const CommonLayout = ({ children, props }) => {
    console.log('CommonLayout ')
    return (
        <div className="root-layout">
            <div className="list-layout">
                {true && children}
            </div>
        </div>
    )
}

export default CommonLayout;