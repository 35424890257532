import { all, call, put, take } from "redux-saga/effects";

import setupClient from "./setupClient";

import auth from "./auth";
import clientSaga from './client';
import presence from './presence';
import settings from './settings';
import Axios from "axios";
import JwtDecode from "jwt-decode";


function* runLoop(client, credentials, getAccessKey) {
  let restarts = 0;

  while (true) {
    try {
      yield all([
        clientSaga(client, credentials, getAccessKey),
        presence(client),
        settings()
      ]);
    } catch (e) {
      console.error("Caught error in saga, restarting:");
      console.error(e);
      restarts++;
      if (restarts > 10) {
        throw new Error("Already restarted 10 times");
      }
    }
  }
}


function* getAccessKey(token) {
  const { payload } = yield take('LOGIN');

  // try {
  //   let { data } = yield call(fetchAccessKeyApi, token)
  //   console.log('getAccessKey success');
  //   // yield put(setAccessKey(data.token));
  //   return data.token;
  // } catch (e) {
  //   return '';
  //   console.log('getAccessKey catch err ', e);
  // }
}

function* xmppSaga(token, userName) {
  const decoded_token = JwtDecode(token);
  const accesskey = yield call(getAccessKey, token);
  const credentials = {
    jid: `${decoded_token.preferred_username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
    password: accesskey,
    nickname: decoded_token.name
  }
  const client = yield call(setupClient, credentials);

  // yield all([runLoop(client), auth(client, token)]);
  yield all([runLoop(client, credentials, getAccessKey), auth(client, credentials)]);
}

export default xmppSaga;
