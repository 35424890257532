import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Dialog, DialogActions, Grid, Tab, Tabs, DialogContent, DialogTitle, TablePagination, Divider, Radio, IconButton, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { MTableToolbar } from 'material-table';
import classNames from 'classnames';
import { slabTypes, bonusScoringConditions } from '../KpiScoringRulesDialog';
import ColorPickerModal from '../../components/ColorPickerModal';
import { ReactComponent as InfoIcon } from "../../assets/icons/infoIcon.svg";


const useStyles = makeStyles(theme => ({
    root: {
        padding: "25px",
        width: "100%",
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
        // width: 280
    },
    tabsRoot: {
        // padding: '14px 0px',
        minWidth: 280,
        // maxWidth: 400,
        // margin: 'auto'
        // borderBottom: '1px solid #0000003a',
        paddingBottom: 0,
        margin: "15px 0px 30px 0px"
    },
    tabsFixed: {
        maxWidth: 760
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 140,
        color: '#838383',
    },
    tabWrapper: {
        flexDirection: 'row',
        // justifyContent: 'flex-start',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 10px 0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px',
        border: 'none',
    },
    scoringAutocompleteRoot: {
        borderRadius: 4,
        padding: '2px 7px !important',
        // width: 280
    },
    tableNoteStyles: {
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        }

    },

    customToolbarRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 80px)'
    },
    healthIndicator: {
        backgroundColor: '#D9D9D9',
        height: '20px',
        width: '20px',
        borderRadius: '17px'
    },
    performanceTitleRoot: {
        position: 'absolute',
        top: 0,
        // top: 32,
        // right: 170,
        height: 40,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'calc(100% - 80px)'
    },
}));

const TeamBonusScoringRule = ({ teamBonusData, handleChangeAutocomplete, handleChangeInput, handelChangeTeamBonusData, details, seasonDetails, getUnit, scoringUnit, handleBonusToggle }) => {
    const myTextIcon = React.useRef(null);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [performanceColor, setPerformanceColor] = useState('');
    const [openColorPicker, setOpenColorPicker] = useState(false);

    console.log('teamBonusData', teamBonusData)

    const handleCreate = (rowData, resolve, reject, slabType) => {
        let newData = slabType === 'indicator_slabs' ? teamBonusData.indicator_slabs : slabType === 1 ? (teamBonusData.penaltySlabs ? teamBonusData.penaltySlabs : []) : teamBonusData.slabs ? teamBonusData.slabs : [];
        if (!(rowData.type && rowData.min && rowData.max && (slabType === 'indicator_slabs' ? rowData.performance_title : teamBonusData.condition?.id === 'TBSR4' ? rowData.multiplication_factor : rowData.points))) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.min) < Number(teamBonusData.minimumScore)) : (Number(rowData.min) >= Number(teamBonusData.minimumScore)))) {
            enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.max) < Number(teamBonusData.minimumScore)) : (Number(rowData.max) > Number(teamBonusData.minimumScore)))) {
            enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (Number(rowData.min) >= Number(rowData.max)) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(rowData.multiplication_factor) < 0) : (Number(rowData.multiplication_factor) >= 0))) {
            enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => ((Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)) && (Number(rowData.max) >= Number(ele.min) && Number(rowData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) < Number(ele.min) && (ele.max !== null && Number(rowData.max) > Number(ele.max))))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.min) >= Number(ele.min) && Number(rowData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.length >= 1 && newData.some(ele => (Number(rowData.max) > Number(ele.min) && Number(rowData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 1 && teamBonusData?.minimumScore <= 0) {
            enqueueSnackbar(`Penalty slabs can be defined only below certain threshold minimum eligibility.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            if (slabType === 'indicator_slabs') {
                rowData['performance_color'] = performanceColor
            }
            newData.unshift(rowData);
            handelChangeTeamBonusData(slabType === 'indicator_slabs' ? 'indicator_slabs' : slabType === 1 ? 'penaltySlabs' : 'slabs', newData)
            setPerformanceColor('');
            resolve()
        }
    }

    const handleUpdate = (newData, oldData, resolve, reject, slabType) => {
        console.log('newdattttttttt', newData, slabType)
        const compareData = slabType === 'indicator_slabs' ? [...teamBonusData.indicator_slabs] : slabType === 1 ? [...teamBonusData.penaltySlabs] : [...teamBonusData.slabs];
        const index = oldData.tableData.id;
        compareData.splice(index, 1);
        if (!(newData.type && newData.min && (newData.type.id === 'BETWEEN' ? newData.max : true) && (slabType === 'indicator_slabs' ? newData.performance_title : teamBonusData.condition?.id === 'TBSR4' ? newData.multiplication_factor : newData.points))) {
            enqueueSnackbar('Please enter required fields.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.min) < Number(teamBonusData.minimumScore)) : (Number(newData.min) >= Number(teamBonusData.minimumScore)))) {
            enqueueSnackbar(`'Greater than or equal' value should be ${slabType === 0 ? 'greater' : 'less'} than 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.max) < Number(teamBonusData.minimumScore)) : (Number(newData.max) > Number(teamBonusData.minimumScore)))) {
            enqueueSnackbar(`'Less than' value should be ${slabType === 0 ? 'greater' : 'less'} than or equal to 'Minimum Eligibility'`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (newData.type.id === 'BETWEEN' ? (Number(newData.min) >= Number(newData.max)) : false) {
            enqueueSnackbar(`'Greater than or equal' value should be less than 'Less than' value.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (teamBonusData.condition?.id === 'TBSR4' && (slabType === 0 ? (Number(newData.multiplication_factor) < 0) : (Number(newData.multiplication_factor) >= 0))) {
            enqueueSnackbar(slabType === 0 ? `'Multiplication factor' must be positive for Scoring slabs` : `'Multiplication factor' must be negative for Penalty slabs`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => ((Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)) && (Number(newData.max) >= Number(ele.min) && Number(newData.max) <= Number(ele.max))))) {
            enqueueSnackbar('Entered slab overlaps with another slab already defined here.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) < Number(ele.min) && Number(newData.max) > Number(ele.max)))) {
            enqueueSnackbar('Slab you are trying to define encompasses other slab defined already, please define discrete slabs.', {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.min) >= Number(ele.min) && Number(newData.min) < Number(ele.max)))) {
            enqueueSnackbar(`'Greater than or equal' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (compareData.length >= 1 && compareData.some(ele => (Number(newData.max) > Number(ele.min) && Number(newData.max) <= Number(ele.max)))) {
            enqueueSnackbar(`'Less than' value entered is already a part of another slab defined here.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject()
        }
        else if (slabType === 1 && teamBonusData?.minimumScore <= 0) {
            enqueueSnackbar(`Penalty slabs can be defined only below certain threshold minimum eligibility.`, {
                variant: 'error',
                preventDuplicate: true
            });
            reject();
        }
        else {
            if (slabType === 'indicator_slabs' && performanceColor) {
                newData['performance_color'] = performanceColor
            }
            const dataUpdate = slabType === 1 ? [...teamBonusData.penaltySlabs] : [...teamBonusData.slabs];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            handelChangeTeamBonusData(slabType === 'indicator_slabs' ? 'indicator_slabs' : slabType === 1 ? 'penaltySlabs' : 'slabs', dataUpdate)

            setPerformanceColor('');
            resolve();
        }
    }

    const handleDelete = (oldData, resolve, reject, slabType) => {
        const dataDelete = slabType === 1 ? [...teamBonusData.penaltySlabs] : [...teamBonusData.slabs];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        handelChangeTeamBonusData(slabType === 1 ? 'penaltySlabs' : 'slabs', dataDelete)
        resolve();
    }

    const handleChangeMinimumEligibility = (e) => {
        const minEligibility = e.target.value;
        // console.log('slabs ', minEligibility);
        if (minEligibility) {
            const emptySlabsPositive = [0, 1, 2, 3].map(item => ({ min: null, max: item ? null : 99999, multiplication_factor: null, tableData: { id: item }, type: item ? { name: 'Between', id: 'BETWEEN' } : { name: 'Above', id: 'ABOVE' } }));// teamBonusData.slabs ? teamBonusData.slabs : [];
            if (teamBonusData.slabs?.length === 0)
                handelChangeTeamBonusData('slabs', emptySlabsPositive);
        }
        else handelChangeTeamBonusData('slabs', []);
        if (minEligibility > 0) {
            const emptySlabsPenalty = [0, 1, 2, 3].map(item => ({ min: null, max: null, multiplication_factor: null, tableData: { id: item }, type: { name: 'Between', id: 'BETWEEN' } }));// teamBonusData.slabs ? teamBonusData.slabs : [];
            if (teamBonusData.penaltySlabs?.length === 0)
                handelChangeTeamBonusData('penaltySlabs', emptySlabsPenalty);
        }
        else handelChangeTeamBonusData('penaltySlabs', []);

        handleChangeInput(e, 'minimumScore', 'TEAM_BONUS');
    }

    const slabsColumns = [
        {
            title: "Points",
            field: "points",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    label='Points'
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    style={{ width: 120 }}
                />

            ),
            render: (rowData) => rowData.points
        }
    ]

    const slabsAndProrataColumns = [
        {
            title: "Multiplication factor",
            field: "multiplication_factor",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    label='Multiplication factor'
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="off"
                    // InputProps={{ endAdornment: `${teamBonusData.basedOn === 'ACTUALS' ? `${(details.unit && details.unit?.id) ? getUnit() : ''}` : ' %'}` }}
                    style={{ minWidth: 140, maxWidth: 200 }}
                />
            ),
            // render: (rowData) => `${rowData.multiplication_factor}`
        }
    ]

    const bonusColumns = (isPenaltySlabs) => [
        {
            title: "Type",
            field: "type",
            editComponent: props => (
                <Autocomplete
                    id="type"
                    options={(teamBonusData.condition?.id === 'TBSR4' && isPenaltySlabs) ? slabTypes.filter(ele => ele.id != 'ABOVE') : (teamBonusData.slabs.length && teamBonusData.slabs.some(ele => ele?.type?.id === 'ABOVE')) ? slabTypes.filter(ele => ele.id != 'ABOVE') : slabTypes}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    renderInput={(params) => <TextField {...params} style={{ width: 125 }} label='Type' variant="outlined" required />}
                    onChange={(...args) => {
                        if (args[1] && Object.keys(args[1]).length > 0 && args[1]?.id === 'ABOVE') {
                            props.rowData.max = 99999;
                        }
                        else props.rowData.max = null;
                        props.onChange(args[1])
                    }}
                />
            ),
            render: (rowData) => rowData.type?.name,
        }, {
            title: `Greater than or equal to`,
            field: "min",
            editComponent: props => (
                <TextField
                    value={props.value}
                    type="number"
                    id="outlined-required"
                    onChange={e => props.onChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label='Greater than or equal to'
                    required
                    classNames={{ input: classes.addtionalScoreInputs }}
                    InputProps={{ endAdornment: `${teamBonusData.basedOn === 'ACTUALS' ? '' : ' %'}` }}
                    autoComplete="off"
                    style={{ minWidth: 220, maxWidth: 240 }}
                />
            ),
            render: (rowData) => (rowData.min !== null) ? (rowData.min + `${teamBonusData.basedOn === 'ACTUALS' ? '' : ' %'}`) : '--'
        },
        {
            title: `Less than`,
            field: "max",
            editComponent: props => {
                if (props.rowData && props.rowData?.type?.id === 'BETWEEN') {
                    return (
                        <TextField
                            value={props.value}
                            type="number"
                            id="outlined-required"
                            label='Less than'
                            onChange={e => props.onChange(e.target.value)}
                            variant="outlined"
                            fullWidth
                            required
                            autoComplete="off"
                            InputProps={{ endAdornment: `${teamBonusData.basedOn === 'ACTUALS' ? '' : ' %'}` }}
                            style={{ minWidth: 140, maxWidth: 200 }}
                        />
                    )
                }
                else return null
            },
            render: (rowData) => rowData?.type?.id === 'BETWEEN' ? (rowData.max !== null) ? (rowData?.max + `${teamBonusData.basedOn === 'ACTUALS' ? '' : ' %'}`) : '--' : '--'
        }];

    const BonusEnhancedTableToolbar = props => {
        const classes = useStyles();
        return (
            <div className={(teamBonusData.condition && teamBonusData.condition?.id === 'TBSR2') ? classes.basedOnView : classes.customToolbarRoot}>
                {teamBonusData?.condition?.id !== 'TBSR4' ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12 }}>Measure performance in : </p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, fontSize: 12 }} onClick={() => handleBonusToggle('ACHIEVEMENT')}>
                        <Radio color="primary" checked={teamBonusData.basedOn === 'ACHIEVEMENT'} size="small" /> Achievement %</p>
                    <p style={{ cursor: 'pointer', padding: 0, margin: 0, marginLeft: 15, fontSize: 12 }} onClick={() => handleBonusToggle('ACTUALS')}>
                        <Radio color="primary" checked={teamBonusData.basedOn === 'ACTUALS'} size="small" />Actuals</p>
                </div>
                    :
                    <p>{props.index === 0 ? 'Scoring slabs' : 'Penalty slabs'}</p>
                }
            </div>
        )
    };

    const handleColorChange = (color) => {
        setPerformanceColor(color);

    };

    const PerformanceTitle = (
        <div className={classes.performanceTitleRoot} >
            <p style={{ fontSize: '16px' }}>Performance Indicator</p>
            <InfoIcon style={{ margin: '5px' }} />

        </div>
    )


    return (
        <>
            <Grid item xs={12}>
                {/* <p style={{ padding: 0, fontWeight: 'bold', fontSize: 12 }}>Team bonus scoring rule:</p> */}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="scoring-condition"
                            options={bonusScoringConditions}
                            getOptionLabel={(option) => option.name || ''}
                            value={teamBonusData?.condition}
                            style={{ maxWidth: 250 }}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => <TextField {...params} label="Scoring Condition" variant="outlined" required />}
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'condition', 'TEAM_BONUS')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            (teamBonusData.condition && teamBonusData.condition?.id === 'TBSR1') &&
                            <>
                                {/* <p style={{ fontSize: 12, fontWeight: 'bold' }}>Additional Scores :</p> */}
                                <div style={{ marginTop: 15 }} className={classNames("material-table", classes.tableNoteStyles)}>
                                    <MaterialTable
                                        title=''
                                        // title={'Additional Scores'}
                                        icons={{
                                            Add: props => (
                                                <div ref={myTextIcon}>
                                                    <Button
                                                        {...props}
                                                        color="primary"
                                                        variant="contained"
                                                        disableElevation
                                                        disableRipple
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            )
                                        }}
                                        columns={[...bonusColumns().filter((ele, i) => i <= 2), ...slabsColumns]}
                                        data={teamBonusData?.slabs}
                                        options={{
                                            search: false,
                                            actionsColumnIndex: -1,
                                            // showTitle: false,
                                            addRowPosition: 'first',
                                            emptyRowsWhenPaging: false,
                                            // toolbar: !readOnly
                                        }}
                                        components={{
                                            Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                            Toolbar: props => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    <BonusEnhancedTableToolbar />
                                                </div>)
                                        }}
                                        editable={{
                                            onRowAdd: newData =>
                                                new Promise((resolve, reject) => {
                                                    if (Object.keys(newData).length > 0) {
                                                        handleCreate(newData, resolve, reject)
                                                        return;
                                                    } else {
                                                        reject();
                                                        return;
                                                    }
                                                }),
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    if (Object.keys(newData).length > 0) {
                                                        handleUpdate(newData, oldData, resolve, reject)
                                                        return;
                                                    } else {
                                                        reject();
                                                        return;
                                                    }
                                                }),
                                            onRowDelete: oldData =>
                                                new Promise((resolve, reject) => {
                                                    handleDelete(oldData, resolve, reject)
                                                    return;
                                                })
                                        }}
                                    />
                                </div>

                            </>
                        }
                    </Grid>
                </Grid>

                {
                    (teamBonusData.condition && teamBonusData.condition?.id === 'TBSR2' || teamBonusData.condition?.id === 'TBSR4') &&
                    <>
                        {teamBonusData?.condition?.id !== 'TBSR4' && <BonusEnhancedTableToolbar />}
                        <Grid container spacing={3} style={{ marginTop: 5 }}>
                            <Grid item xs={3}>
                                <TextField
                                    value={teamBonusData?.minimumScore}
                                    type="number"
                                    id="outlined-required"
                                    label="Min Eligibility"
                                    onChange={e => teamBonusData.condition?.id === 'TBSR4' ? handleChangeMinimumEligibility(e) : handleChangeInput(e, 'minimumScore', 'TEAM_BONUS')}
                                    variant="outlined"
                                    fullWidth
                                    style={{ maxWidth: 250 }}
                                    autoComplete="off"
                                    required={(teamBonusData.condition && teamBonusData.condition?.id === 'TBSR2')}
                                    InputProps={{ endAdornment: teamBonusData.basedOn === 'ACHIEVEMENT' ? "%" : '' }}
                                />
                            </Grid>
                            {teamBonusData.condition?.id !== 'TBSR4' && <Grid item xs={3}>
                                <TextField
                                    value={teamBonusData?.multiplicationFactor}
                                    type="number"
                                    id="outlined-required"
                                    label="Multiplication factor"
                                    onChange={e => handleChangeInput(e, 'multiplicationFactor', 'TEAM_BONUS')}
                                    variant="outlined"
                                    fullWidth
                                    style={{ maxWidth: 250 }}
                                    autoComplete="off"
                                    required={(teamBonusData.condition && teamBonusData.condition?.id === 'TBSR2')}
                                />
                            </Grid>}
                        </Grid>
                    </>
                }
                {
                    (teamBonusData.condition && teamBonusData.condition?.id === 'TBSR3') &&
                    <Grid container spacing={3} style={{ marginTop: 5 }}>
                        <Grid item xs={5} >
                            <TextField
                                value={teamBonusData?.points}
                                type="number"
                                id="outlined-required"
                                label="Points"
                                onChange={e => handleChangeInput(e, 'points', 'TEAM_BONUS')}
                                variant="outlined"
                                fullWidth
                                style={{ maxWidth: 250 }}
                                autoComplete="off"
                                required={(teamBonusData.condition && teamBonusData.condition?.id === 'TBSR3')}
                            />
                        </Grid>
                    </Grid>
                }
                {
                    (teamBonusData.condition && teamBonusData.condition?.id === 'TBSR4') &&
                    <>
                        {/* <p style={{ fontSize: 12, fontWeight: 'bold' }}>Additional Scores :</p> */}
                        {[0, 1].map(item => <div key={item} style={{ marginTop: 15 }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title=''
                                // title={'Additional Scores'}
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={[...bonusColumns(item === 1).filter((ele, i) => i <= 2), ...slabsAndProrataColumns]}
                                data={item ? teamBonusData?.penaltySlabs : teamBonusData?.slabs}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    // showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    // toolbar: !readOnly
                                }}
                                components={{
                                    Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />,
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <BonusEnhancedTableToolbar index={item} />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleCreate(newData, resolve, reject, item)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleUpdate(newData, oldData, resolve, reject, item)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(oldData, resolve, reject, item)
                                            return;
                                        })
                                }}
                            />
                        </div>
                        )}

                    </>
                }

                {openColorPicker &&
                    <ColorPickerModal open={openColorPicker} colorValue={performanceColor} handleColorChange={handleColorChange} handleDialog={() => setOpenColorPicker(false)} />
                }

            </Grid>
            {/* {
                (teamBonusData.condition?.id && teamBonusData?.basedOn) &&
                <Grid item xs={4} md={4}>

                    <ScoringRulesContents condition={teamBonusData.condition?.id} basedOn={teamBonusData?.basedOn} />
                </Grid>

            } */}
        </>
    )

}
export default TeamBonusScoringRule;