import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { setLoading } from '../ducks/loading';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { GET_COUNTRIES, GET_LANGUAGE, UPDATE_LANGUAGE_STATUS } from '../config';
import { Button, Switch, TablePagination } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import { Edit } from '@material-ui/icons';
import MasterLanguageDialog from './LanguageMasterDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPickerDTToolbar-separator': {
            margin: '0px 4px -9px 2px !important',
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'none !important'
            }
        },
        '& .MuiAutocomplete-popper': {
            '& ul': {
                '& li': {
                    fontSize: '10px !importnt'
                }
            }
        }

    },
    button: {
        textTransform: 'capitalize'
    }
}))

const breadcrumbsList = [
    { label: 'Platform Admin', to: '/platform-admin' },
    { label: 'Language Master' }
]


let total_count = 0;
let total_pages = 0;

const LanguageMaster = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [languageList, setLanguageList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [languageOpenDialog, setLanguageOpenDialog] = useState(false);
    const [countries, setCountries] = useState([])




    useEffect(() => {
        getCountries();
    }, [])
    useEffect(() => {
        getLanguage();
    }, [currentPage, pageSize]);

    //-----------  Get Language --------
    const getLanguage = () => {
        setLoading(true)
        Axios.get(GET_LANGUAGE, { params: { page: currentPage, limit: pageSize } }).then(languages => {
            total_count = languages.headers.total_count;
            total_pages = languages.headers.total_pages;
            setLanguageList(languages.data)
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Language', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    //------------ Get Counties -----------------
    const getCountries = () => {
        Axios.get(GET_COUNTRIES)
            .then(res => {
                if (res.data) {
                    setCountries(res.data);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Language', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----toggle changes-------
    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            Axios.put(UPDATE_LANGUAGE_STATUS(rowData?._id), { active: value })
                .then(() => {
                    getLanguage();
                    setSelectedLanguage(null);
                    setLoading(false);
                    if (value)
                        enqueueSnackbar('Language activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    else
                        enqueueSnackbar('Language de-activated successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Language', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                });

        }
    }

    // ----language dialogs actions -----
    const handleCloseTemplateDialog = (value) => {
        if (value) getLanguage();
        setLanguageOpenDialog(false);
        setSelectedLanguage(null);
    };
    const handleOpenDialog = () => {
        setLanguageOpenDialog(true);
        setSelectedLanguage(null);
    }
    const updateLanguage = (data, type) => {
        setSelectedLanguage(data);
        setLanguageOpenDialog(true);
    }


    const column = [
        {
            title: "Language Name",
            field: "name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Display Name",
            field: "display_name",
            emptyValue: '--',
            cellStyle: { minWidth: 120 },
        },
        {
            title: "Short Code",
            field: "short_code",
            emptyValue: '--',
            editable: false
        },
        {
            title: "country",
            field: "country.country_name",
            emptyValue: '--',
            cellStyle: { minWidth: 200 },
        },
        {
            title: "Status",
            field: "active",
            editable: false,
            emptyValue: '--',
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData?.active}
                    color="primary"
                    name="active"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: classes.swtichRoot }}
                />
            )
        }
    ]

    const EnhancedTableToolbar = (toolBarProps) => {
        const classes = useStyles();
        return (
            <div className=''>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8, marginTop: '-14px' }}>
                    <Button
                        {...props}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.button}
                        onClick={() => handleOpenDialog()}
                    >
                        + Add Language
                    </Button>
                </div>
            </div >
        )
    };


    return (
        <div className={classes.root}>
            <div className={'common-wrapper'}>
                <CustomBreadcrumbs title={"Language"} crumbsList={breadcrumbsList} />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={languageList?.length}
                        title="List Of Language"
                        columns={column}
                        data={languageList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: 'first',
                            pageSizeOptions: [10, 25, 50],
                            emptyRowsWhenPaging: false,
                            pageSize: pageSize,
                            searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                            searchFieldAlignment: "left",
                            search: true,
                        }}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Edit',
                                onClick: (event, rowData) => updateLanguage(rowData),
                            },
                        ]}
                        components={{
                            Pagination: props => {
                                return (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        rowsPerPage={pageSize}
                                        count={total_count}
                                        page={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                )
                            },
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            {languageOpenDialog && (
                <MasterLanguageDialog
                    open={languageOpenDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedLanguage={selectedLanguage}
                    countries={countries}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageMaster));

