import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Warning } from '../../src/assets/icons/warning .svg'
import React from 'react';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353',
        display: "flex"
    },
    warnPopupStyle: {
        borderRadius: '20px',
        minWidth: "700px",
        minHeight: "350px"
    },
    dialogPaper: {
        borderRadius: '15px'
    },
    warnButton: {
        background: "#DA3832",
        color: "white",
        '&:hover': {
            backgroundColor: '#DA3832'
        },
    }
}));

const Types = ['INFO', 'ERROR', 'WARNING', 'CONFIRMATION'];
const CustomDialog = ({ content = '', title = "", primaryButton = { text: 'OK', otherProps: {} }, secondaryButton = { text: '', otherProps: {} }, handleConfirmationDialog, open, deleteItem, type = "", dialogFrom = "", style = "", ...other }) => {
    const classes = useStyles();
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleConfirmationDialog(false)}
            {...other}
            classes={style === "WARN" ? { paper: classes.warnPopupStyle } : { paper: classes.dialogPaper }}

        >
            {title &&
                <DialogTitle disableTypography className={classes.title}>
                    {dialogFrom === "denied" && <Warning />}
                    {title}
                    {/* <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton> */}
                </DialogTitle>}
            <DialogContent>
                <p>{content || 'Are you sure, You want to close'}</p>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                {dialogFrom === "denied" ?
                    <>
                        <Button variant='outlined' className={classes.warnButton} onClick={() => handleConfirmationDialog(false)} >
                            OK
                        </Button>
                    </> :
                    <>
                        {secondaryButton?.text && <Button variant='outlined' onClick={() => handleConfirmationDialog(false)}  {...secondaryButton.otherProps}>
                            {secondaryButton.text}
                        </Button>}
                        {primaryButton?.text && <Button variant='contained' style={{ marginLeft: 30 }} onClick={() => handleConfirmationDialog(true, "yes", deleteItem)} color="primary" {...primaryButton.otherProps}>
                            {primaryButton.text}
                        </Button>}
                    </>}

            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
