import React from 'react';
import { useSnackbar } from 'notistack';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    pageTitle: {
        color: "#58595B",
        fontSize: 22,
        margin: 0
    },
    breadcrumbsRoot: {
        marginTop: 2
    },
    breadcrumbsActive: {
        color: "#58595B",
        fontSize: 14,
        margin: "3px 0 0",
        fontWeight: 600
    },
    breadcrumbsLink: {
        color: theme.palette.primary.main,
        fontSize: 12,
        textDecoration: "none"
    },
}));

const CustomBreadcrumbs = ({ title = '', crumbsList = [] }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    return (
        <div>
            {/* <h2 className={classes.pageTitle}>{title}</h2> */}
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
                <Link color="inherit" to="/" className={classes.breadcrumbsLink}>
                    Home
                </Link>
                {
                    crumbsList.map(obj => (
                        [
                            obj.to ?
                                <Link color="inherit" to={obj.to} className={classes.breadcrumbsLink}>
                                    {obj.label}
                                </Link> :
                                <Typography component="span" color="textPrimary" className={classes.breadcrumbsActive}>
                                    {obj.label}
                                </Typography>

                        ]
                    ))
                }
                {/* <Link color="inherit" to={`/arena/leagues/${match.params.id}/seasons`} className={classes.breadcrumbsLink}>
                    {(details.shortName || match.params.id) + ' - Seasons'}
                </Link> */}
            </Breadcrumbs>
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        // setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(
    null,
    mapDispatchToProps
)(CustomBreadcrumbs));