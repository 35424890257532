import { combineReducers } from "redux";

import bookmarks from '../ducks/bookmarks';
import client from '../ducks/client';
import files from '../ducks/files';
import forms from '../ducks/forms';
import local from '../ducks/local';
import presence from '../ducks/presence';
import settings from '../ducks/settings';
import toast from '../ducks/toast';
import keycloak from '../ducks/keycloak';
import common from '../ducks/common';
import imageViewer from '../ducks/imageViewer';
import sidebar from '../ducks/sidebar';
import loading from '../ducks/loading';
import snackbar from '../ducks/snackbar';

export default combineReducers({
  bookmarks,
  client,
  files,
  forms,
  local,
  presence,
  settings,
  toast,
  keycloak,
  common,
  imageViewer,
  sidebar,
  loading,
  snackbar,
});
