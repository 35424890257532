import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { CREATE_LANGUAGE, UPDATE_LANGUAGE } from "../config";
import { useSnackbar } from 'notistack';
import Axios from "axios";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogContent: {
        padding: '20px 15px 15px 15px',
        paddingBottom: 10,
        minHeight: 200,
        background: '#00000014'
    },
    button: {
        textTransform: 'initial'
    },
    dialogPaper: {
        minWidth: 600,
        padding: 0
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
        textTransform: 'capitalize'
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    tableRoot: {
        marginTop: 0
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "5px 7px !important",
        height: 42,
    },
}));



const CustomLanguageDialog = ({ open = false, handleClose, countries, selectedLanguage, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        name: '',
        short_code: '',
        display_name: '',
        country: null,
    });
    useEffect(() => {
        if (selectedLanguage) {
            const langauageDetails = {
                ...details,
                country: selectedLanguage?.country ? countries?.find(el => el._id === selectedLanguage?.country?.country_id) : null,
                name: selectedLanguage?.name,
                short_code: selectedLanguage?.short_code,
                display_name: selectedLanguage?.display_name,
            };
            setDetails({ ...langauageDetails });
        }
    }, [selectedLanguage]);





    const handleChangeInput = (e, field) => {
        setDetails({ ...details, [field]: e.target.value });
    }

    const handleSelect = (val) => {
        if (val) {
            setDetails({ ...details, country: val });
        }
    }

    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        if (
            details?.name.trim() === "" ||
            details?.short_code == "" ||
            details?.country == null
        ) {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        } else {
            let requestObj = {
                country_id: details?.country ? details?.country._id : null,
                name: details?.name,
                short_code: details?.short_code,
                display_name: details?.display_name
            };
            setLoading(true);
            if (selectedLanguage) {
                Axios.put(UPDATE_LANGUAGE(selectedLanguage?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Language Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Language', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            } else {
                Axios.post(CREATE_LANGUAGE, requestObj)
                    .then(() => {
                        console.log("obj=>", requestObj)
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar('Language added successfully.', {
                            variant: 'success',
                            preventDuplicate: true
                        })
                    })
                    .catch(error => {
                        setLoading(false);
                        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Add Language', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    });
            }
        }
    };


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {selectedLanguage ? 'Update Language' : 'Add Language'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                disabled={selectedLanguage}
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="language-name"
                                value={details.name}
                                type="String"
                                label="Language Name"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "name")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="display_name"
                                value={details.display_name}
                                type="String"
                                label="Display Name"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "display_name")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 50 }}
                                variant="outlined"
                                id="short_code"
                                value={details.short_code}
                                type="String"
                                label="Short Code"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "short_code")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="country"
                                disabled={selectedLanguage}
                                autoComplete
                                options={countries}
                                getOptionLabel={(option) => option?.name}
                                value={details?.country}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Country"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val)}
                            />
                        </Grid>

                    </Grid>
                </div>
                <div className={classes.dialogueActions}>
                    <DialogActions style={{ padding: "10px 0" }}>
                        <Button
                            onClick={() => handleClose(false)}
                            className={classes.footerButton}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSubmit()}
                            className={classes.footerButton}
                            variant="contained"
                            color="primary"
                        >
                            {selectedLanguage ? 'Save' : 'Submit'}
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default withRouter(CustomLanguageDialog);
