import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 12,
        backgroundColor: '#000000d1'
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    }
}));

const ColorPickerModal = ({ handleDialog, open, colorValue, handleColorChange, ...other }) => {
    const classes = useStyles();

    const [color, setColor] = useColor("hex", colorValue || "#121212");

    useEffect(() => {
        if (color.hex) {
            handleColorChange(color.hex);
        }
    }, [color.hex]);

    return (
        <Dialog
            maxWidth="md"
            aria-labelledby="color-picker"
            open={open}
            onClose={() => handleDialog(false)}
            {...other}
            classes={{ paper: classes.root }}
        >
            {/* <DialogTitle disableTypography className={classes.title}>
                Color Picker
            </DialogTitle> */}
            <DialogContent style={{ padding: 0 }}>
                <ColorPicker width={456} height={228} color={color} onChange={setColor} hideHSV dark />
            </DialogContent>
            <DialogActions style={{ padding: 0 }}>
                <Button variant='contained' style={{ margin: '0px auto 10px' }} onClick={() => handleDialog(false)} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColorPickerModal;
